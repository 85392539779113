import { CancelTokenSource } from 'axios';

import { TransProgressKind } from './TransProgressExternal';

export enum TransProgressStatus {
  STARTING = 'Iniciando',
  TRANSFERING = 'Transferindo',
  FINISHED = 'Concluído',
  CANCELED = 'Cancelado',
  FAILED = 'Falhou'
}

export type TransProgressFile = {
  id: string;
  name: string;
  active?: boolean;
  kind: TransProgressKind;
  status: TransProgressStatus;
  size?: number;
  transfered?: number;
  statusInfo?: string;
  cancelToken?: CancelTokenSource;
  finishing?: boolean;
};

type TransProgressAddFileEventDetail = {
  id: string;
  name: string;
  kind: TransProgressKind;
  cancelToken: CancelTokenSource;
};

export enum TransProgressUpdateKind {
  PROGRESS,
  FINISHED,
  FAILED,
  NAME
}

type TransProgressUpdateFileEventDetail = {
  id: string;
  updateKind: TransProgressUpdateKind;
  loaded?: number;
  total?: number;
  reason?: any;
  name?: string;
};

export const TransProgressAddFileEventType = 'transProgressAddFileEvent';
export const TransProgressUpdateFileEventType = 'transProgressUpdateFileEvent';

export class TransProgressAddFileEvent extends CustomEvent<TransProgressAddFileEventDetail> {
  constructor(detail: TransProgressAddFileEventDetail) {
    super(TransProgressAddFileEventType, { detail });
  }
}

export class TransProgressUpdateFileEvent extends CustomEvent<TransProgressUpdateFileEventDetail> {
  constructor(detail: TransProgressUpdateFileEventDetail) {
    super(TransProgressUpdateFileEventType, { detail });
  }
}

export interface TransProgressEventMap {
  transProgressAddFileEvent: TransProgressAddFileEvent;
  transProgressUpdateFileEvent: TransProgressUpdateFileEvent;
}

declare global {
  interface DocumentEventMap {
    [TransProgressAddFileEventType]: TransProgressAddFileEvent;
    [TransProgressUpdateFileEventType]: TransProgressUpdateFileEvent;
  }
}
