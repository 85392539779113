import React, { Component } from 'react';

import { FloatSidebarButton } from './FloatSidebarButton';

const setFloatSidebarActive = (identifier: string, active: boolean) => {
  const event = new CustomEvent('setFloatSidebarActive', {
    detail: { identifier, active }
  });
  document.dispatchEvent(event);
};

type Props = {
  title: string;
  hint?: boolean;
  bubble?: string;
  bubbleClass?: string;
  icon?: string;
  buttonSlide?: string;
  className?: string;
  identifier?: string;
  defaultActive?: boolean;
};

type State = {
  active: boolean;
};

class FloatSidebar extends Component<Props, State> {
  private floatSidebarRef: React.RefObject<HTMLDivElement>;

  state: State = {
    active: this.props.defaultActive || false
  };

  constructor(props) {
    super(props);
    this.floatSidebarRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    const { identifier } = this.props;
    if (identifier) {
      document.addEventListener(
        'setFloatSidebarActive',
        this.handleSetActive(identifier),
        true
      );
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    const { identifier } = this.props;
    if (identifier) {
      document.removeEventListener(
        'setFloatSidebarActive',
        this.handleSetActive(identifier),
        true
      );
    }
  }

  setActiveFromEvent(event, active) {
    const domNode = this.floatSidebarRef.current;

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ active });
    }
  }

  handleSetActive = (identifier: string) => (event: any) => {
    if (event.detail.identifier === identifier) {
      this.setActiveFromEvent(event, event.detail.active);
    }
  };

  handleClickOutside = event => {
    if (event.isTrusted) {
      this.setActiveFromEvent(event, false);
    }
  };

  openCloseSidebar = () => {
    this.setState(prevState => ({ active: !prevState.active }));
  };

  render() {
    const { active } = this.state;
    const { hint, title, icon, bubble, bubbleClass, buttonSlide, className } =
      this.props;

    return (
      <div
        className={`float-sidebar ${active ? 'active' : ''} ${className}`}
        data-testid="float-sidebar-container"
        style={{ height: 'auto' }}
        ref={this.floatSidebarRef}
      >
        <FloatSidebarButton
          icon={icon || 'fa fa-cubes'}
          hint={hint}
          bubble={bubble}
          bubbleClass={bubbleClass}
          hintMessage={title}
          buttonSlide={buttonSlide}
          onButtonClick={this.openCloseSidebar}
        />

        <div className="title">{title}</div>

        {this.props.children}
      </div>
    );
  }
}

export { FloatSidebar, setFloatSidebarActive };
