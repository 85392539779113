import { Col, ExtraInfo, Loading, Row, Table } from '@elotech/components';
import { AxiosPromise } from 'axios';
import {
  IFrame,
  IFrameContainer,
  InlineButton,
  TableContainer
} from 'common/components';
import { Arquivo } from 'common/type';
import { getContentType, getExtension } from 'common/utils';
import { useEffect, useState } from 'react';
import React from 'react';
import { Image } from 'react-bootstrap';

type Props = {
  documents: Arquivo[];
  onDownload: (arquivo: Arquivo) => AxiosPromise;
  parentIsLoading: boolean;
};

export const DocumentViewer: React.FC<Props> = ({
  documents,
  onDownload,
  parentIsLoading
}) => {
  const [showingFile, setShowingFile] = useState<Arquivo>();
  const [showingLink, setShowingLink] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const VIEW_TYPES = [
    'PDF',
    'PNG',
    'JPG',
    'JPEG',
    'GIF',
    'BMP',
    'SVG',
    'MP4',
    'WEBM',
    'OGV',
    'OGG'
  ];

  const IMAGE_TYPES = ['PNG', 'JPG', 'JPEG', 'GIF', 'BMP', 'SVG'];

  useEffect(() => {
    if (!showingFile) {
      return;
    }
    let fileURL: string;
    let canceled = false;

    setShowingLink(undefined);
    setLoading(true);
    onDownload(showingFile!)
      .then(({ data, headers }) => {
        if (canceled) {
          return;
        }

        const contentDisposition = headers?.['Content-Disposition'];
        const responseFileName = contentDisposition?.substring(
          contentDisposition.indexOf('"') + 1,
          contentDisposition.lastIndexOf('"')
        );

        const type = getContentType(responseFileName || showingFile!.nome);
        const blob = new Blob([data], { type });

        fileURL = URL.createObjectURL(blob);

        setShowingLink(fileURL);
      })
      .finally(() => {
        if (!canceled) {
          setLoading(false);
        }
      });

    return () => {
      canceled = true;
      URL.revokeObjectURL(fileURL);
    };
  }, [showingFile]);

  const onNext = (forward: boolean) => {
    const canViewList = documents.filter(doc =>
      VIEW_TYPES.includes(getExtension(doc.nome))
    );

    if (!canViewList.length) {
      return;
    }

    if (canViewList.length === 1 && showingFile === canViewList[0]) {
      return;
    }

    if (!showingFile) {
      return setShowingFile(canViewList[forward ? 0 : canViewList.length - 1]);
    }

    let nextIndex =
      canViewList.findIndex(doc => doc === showingFile) + (forward ? 1 : -1);

    if (nextIndex >= canViewList.length) {
      nextIndex = 0;
    }

    if (nextIndex < 0) {
      nextIndex = canViewList.length - 1;
    }

    return setShowingFile(canViewList[nextIndex]);
  };

  const isImage = () => {
    return showingLink && IMAGE_TYPES.includes(getExtension(showingFile?.nome));
  };

  return (
    <>
      <Row>
        <Col sm={3} md={3}>
          <TableContainer>
            <Table<Arquivo>
              values={documents}
              highlight={doc => doc === showingFile}
              onClickRow={setShowingFile}
              keyExtractor={(doc, index) => `${index} - ${doc.nome}`}
              loading={parentIsLoading}
            >
              <Table.Column<Arquivo>
                style={{ cursor: 'pointer' }}
                header="Documentos"
                value={doc => (
                  <>
                    {!VIEW_TYPES.includes(getExtension(doc?.nome)) && (
                      <ExtraInfo
                        letter={'D'}
                        color={'negative'}
                        value={'Apenas Download'}
                      />
                    )}
                    {doc.nome}
                  </>
                )}
              />
            </Table>
          </TableContainer>
        </Col>
        <Col sm={9} md={9}>
          <Row>
            <div className="container" style={{ marginTop: '20px' }}>
              <Col sm={6} md={6}>
                <InlineButton
                  style={{ width: '100%' }}
                  label="Anterior"
                  icon="fa fa-arrow-left"
                  onClick={() => {
                    onNext(false);
                  }}
                />
              </Col>
              <Col sm={6} md={6}>
                <InlineButton
                  style={{ width: '100%' }}
                  label="Próximo"
                  icon="fa fa-arrow-right"
                  className="icon-right"
                  onClick={() => {
                    onNext(true);
                  }}
                />
              </Col>
            </div>
          </Row>
          <Row style={{ position: 'relative' }}>
            <Loading loading={loading} contain={true} />
            {isImage() ? (
              <Col
                md={12}
                className="mt-xs ml-xs"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Image src={showingLink} responsive />
              </Col>
            ) : (
              <IFrameContainer>
                <IFrame path={showingLink || 'about:blank'} />
              </IFrameContainer>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
