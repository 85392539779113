import { useShowNotification } from '@elotech/components';
import { ChipText } from 'common/components';

const getStatus = (situacao, motivo) => {
  if (situacao === 'ASSINANDO') return 'Assinando';
  if (situacao === 'REJEITADO') return motivo ? 'Rejeitada' : 'Descartada';
  if (situacao === 'ASSINADO') return 'Assinado';
  return 'Pendente';
};
const getStatusClass = (situacao, erro) => {
  if (situacao === 'ASSINADO') return 'positive';
  if (situacao === 'REJEITADO') return 'negative';
  if (situacao === 'ASSINANDO') return 'warning';
  if (erro) return 'negative';
  return 'warning';
};

const getIcon = (situacao, erro) => {
  if (situacao === 'ASSINANDO') return 'spinner';
  if (erro) return 'times';
  return undefined;
};

interface Props {
  situacao: string;
  motivoRecusa?: string;
  erro?: string;
}
export const StatusAssinatura = (props: Props) => {
  const showNotification = useShowNotification();
  const { situacao, motivoRecusa, erro } = props;
  return (
    <ChipText
      interactive={!!erro}
      type={getStatusClass(situacao, erro)}
      leftIcon={getIcon(situacao, erro)}
      onClick={() => {
        if (erro) showNotification({ level: 'error', message: erro });
      }}
    >
      {getStatus(situacao, motivoRecusa)}
    </ChipText>
  );
};
