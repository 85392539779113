import { Col } from '@elotech/components';
import { Label } from 'common/components';
import React, { ReactNode } from 'react';
import { FormGroup } from 'react-bootstrap';

export type QuickViewItemProps = {
  size: number;
  header: string;
  value: ReactNode;
};

const QuickViewItem = ({ size, header, value }: QuickViewItemProps) => {
  return (
    <Col md={size} sm={size}>
      <FormGroup>
        <Label>{header}</Label>
        {value}
      </FormGroup>
    </Col>
  );
};

export default QuickViewItem;
