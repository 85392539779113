import {
  Checkbox,
  Col,
  FormattedDateTime,
  Loading,
  Modal,
  Panel,
  Row,
  Table,
  useShowNotification
} from '@elotech/components';
import { InlineButton } from 'common/components';
import { useLoading } from 'common/hooks';
import { NAO_CARREGA_REJEITADOS_AGRUPAMENTO_ARQUIVO } from 'pages/parametros/Parametros';
import { useEffect, useState } from 'react';
import { ParametroService, ProcessoAgrupamentoService } from 'service';
import { AnaliseArquivoEnum, ProcessoArquivoDTO, ProcessoDTO } from 'types';

type ProcessoArquivoCheckboxType = ProcessoArquivoDTO & {
  selected: boolean;
};

const AgrupamentoArquivosProcesso = ({
  processo,
  onClose,
  mostraCapa = true
}) => {
  const ID_ARQUIVO_CAPA = 999999999999999;
  const ID_ARQUIVO_TRAMITES = 999999999999998;
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [arquivosCheckbox, setArquivosCheckbox] = useState<
    ProcessoArquivoCheckboxType[]
  >([]);
  const [arquivos, setArquivos] = useState<ProcessoArquivoDTO[]>([]);
  const showNotification = useShowNotification();
  const [paramNaoCarregaRejeitados, setParamNaoCarregaRejeitados] =
    useState(false);
  const [loading, setLoading] = useLoading();

  useEffect(() => {
    setLoading(
      ParametroService.findParametro(
        NAO_CARREGA_REJEITADOS_AGRUPAMENTO_ARQUIVO
      ).then(({ data }) => {
        setParamNaoCarregaRejeitados(data?.valor === 'S');
      })
    );
  }, []);

  const selectAllArquivos = (event): void => {
    const checked = event.currentTarget.checked;
    const newArquivos = arquivosCheckbox.map(
      (item: ProcessoArquivoCheckboxType) => {
        return {
          ...item,
          selected: checked
        };
      }
    );

    setArquivosCheckbox(newArquivos);
    setIsAllSelected(checked);
  };

  const selectArquivo = (
    checked,
    arquivo: ProcessoArquivoCheckboxType,
    index: number
  ): void => {
    const newArquivo = { ...arquivo, selected: checked };

    const newArquivos: ProcessoArquivoCheckboxType[] = [
      ...arquivosCheckbox.slice(0, index),
      newArquivo,
      ...arquivosCheckbox.slice(index + 1)
    ];

    setArquivosCheckbox(newArquivos);
    setIsAllSelected(hasAllSelected(newArquivos));
  };

  const hasAllSelected = (items): boolean => items.every(item => item.selected);

  const getArquivosFiltrados = (processo?: ProcessoDTO) => {
    return (
      processo?.arquivos
        ?.filter(
          pa =>
            !pa.agrupamento &&
            (pa.descricao?.toLowerCase().endsWith('.pdf') ||
              pa.nome?.toLowerCase().endsWith('.pdf'))
        )
        .filter(pa => {
          if (paramNaoCarregaRejeitados) {
            return pa.analise !== AnaliseArquivoEnum.RECUSADO;
          }

          return true;
        })
        .sort((a1, a2) => (a1.ordem || 0) - (a2.ordem || 0)) || []
    );
  };

  const getCapa = selected => {
    if (!mostraCapa) {
      return [];
    }
    return [{ idArquivo: ID_ARQUIVO_CAPA, nome: 'Capa processo', selected }];
  };

  useEffect(() => {
    setArquivos([]);
    const possuiArquivoAgrupado =
      processo &&
      processo.arquivos.filter(arquivo => arquivo.agrupamento).length > 0;
    if (possuiArquivoAgrupado && processo.fechado) {
      showNotification({
        level: 'error',
        message: `O documento agrupado já foi gerado e processo: ${processo.numero}/${processo.ano}. encontra-se finalizado para movimentações`
      });
      return;
    }
    if (processo) {
      const arquivosProcesso = getArquivosFiltrados(processo);
      setArquivos([
        ...getCapa(false),
        ...arquivosProcesso,
        { idArquivo: ID_ARQUIVO_TRAMITES, nome: 'Relatório de trâmites' }
      ]);
      const arquivosProcessosSelecionados = arquivosProcesso?.map(arquivo => ({
        ...arquivo,
        selected: true
      }));
      setArquivosCheckbox([...getCapa(true), ...arquivosProcessosSelecionados]);
    } else {
      setArquivosCheckbox([]);
      setIsAllSelected(false);
    }
  }, [processo, paramNaoCarregaRejeitados]);

  const agruparArquivos = () => {
    if (processo?.id) {
      setLoading(
        ProcessoAgrupamentoService.agruparArquivosEmUnicoPdf(
          {
            agruparCapa:
              mostraCapa &&
              arquivosCheckbox.findIndex(
                arquivo =>
                  arquivo.selected && arquivo.idArquivo === ID_ARQUIVO_CAPA
              ) > -1,
            arquivosAgrupamento: arquivosCheckbox.filter(
              a =>
                a.selected &&
                a.idArquivo !== ID_ARQUIVO_CAPA &&
                a.idArquivo !== ID_ARQUIVO_TRAMITES
            ),
            agruparTramites:
              arquivosCheckbox.findIndex(
                arquivo =>
                  arquivo.selected && arquivo.idArquivo === ID_ARQUIVO_TRAMITES
              ) > -1
          },
          processo.id
        )
          .finally(() => onClose())
          .then(() =>
            showNotification({
              level: 'success',
              message: `O agrupamento destes arquivos é feito de forma assíncrona
         e você será notificado assim que o processamento terminar. Após o
         agrupamento, o arquivo estará disponível no Processo
         ${processo.numero}/${processo.ano}.`
            })
          )
      );
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <Modal onClose={onClose} maxWidth="60%">
        <Panel isTable title="Agrupamento de Arquivos">
          <Table<ProcessoArquivoDTO>
            fixed
            values={arquivos}
            keyExtractor={arquivo =>
              `${arquivo.idArquivo}_${arquivo.identificador}_${arquivo.nome}`
            }
          >
            <Table.Column
              headerClassName="column-checkbox no-print"
              className="column-checkbox no-print"
              header={
                <div className="hidden-xs">
                  <Checkbox
                    id="check-all-arquivos"
                    checked={isAllSelected}
                    onChange={selectAllArquivos}
                  />
                </div>
              }
              value={(arquivo: any, index: number) => (
                <Checkbox
                  key={arquivo.idArquivo}
                  id={`checkbox-arquivo-${arquivo.idArquivo}`}
                  onChange={event =>
                    selectArquivo(event.currentTarget.checked, arquivo, index)
                  }
                  checked={
                    !!arquivosCheckbox?.find(
                      a => a.idArquivo === arquivo.idArquivo
                    )?.selected
                  }
                />
              )}
            />
            <Table.Column
              header="Nome"
              value={(pa: ProcessoArquivoDTO) => pa.nome || pa.descricao}
            />
            <Table.Column
              header="Usuário"
              value={(pa: ProcessoArquivoDTO) => pa.usuario}
            />
            <Table.Column
              header="Data Criação"
              value={(pa: ProcessoArquivoDTO) => (
                <FormattedDateTime value={pa.dataCriacao} />
              )}
            />
          </Table>
          <Row className="mt-xs">
            <Col md={12} className="right mt-xs">
              <InlineButton
                label="Agrupar arquivos"
                onClick={agruparArquivos}
              />
            </Col>
          </Row>
        </Panel>
      </Modal>
    </>
  );
};

export default AgrupamentoArquivosProcesso;
