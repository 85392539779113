import Chart from 'chart.js';
import { Component } from 'react';
import { safeStringify } from 'utils';

const defaultOptions = {
  scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
};

class BarChart extends Component {
  static defaultProps = {
    options: defaultOptions
  };

  componentDidMount() {
    this.criarGrafico();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.data, safeStringify) !==
      JSON.stringify(this.props.data, safeStringify)
    ) {
      if (this.pieChart) {
        this.pieChart.destroy();
      }

      this.criarGrafico();
    }
  }

  componentWillUnmount() {
    if (this.pieChart) {
      this.pieChart.destroy();
    }
  }

  criarGrafico() {
    const { data, options } = this.props;
    const ctx = this.refs.chart.getContext('2d');

    if (ctx) {
      this.pieChart = new Chart(ctx, {
        type: 'bar',
        data,
        options
      });

      this.pieChart.update();
    }
  }

  render() {
    return <canvas ref="chart" height="100vh" />;
  }
}

export default BarChart;
