import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/situacoes';

const defaultSort = {
  sort: 'id,asc'
};

export const findAllAutocomplete = (
  searchParam = '',
  tipoProcesso = null,
  pagination,
  sort = defaultSort
) => {
  let search = isNumber(searchParam)
    ? `id==${searchParam}`
    : FilterUtils.buildFilter(['descricao'])(searchParam);

  search = `${search} and ativa==true`;

  return axios.get(`${resource}`, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const findAllAutocompleteByTipoProcesso = (
  searchParam = '',
  tipoProcesso = null,
  pagination,
  sort = defaultSort
) => {
  let search = isNumber(searchParam)
    ? `id==${searchParam}`
    : FilterUtils.buildFilter(['descricao'])(searchParam);

  search = `${search} and ativa==true`;

  return axios.get(`${resource}/find-all/${tipoProcesso}`, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const findAllAutocompleteArquivamento = (
  searchParam = '',
  pagination,
  sort = defaultSort
) => {
  let search = isNumber(searchParam)
    ? `id==${searchParam}`
    : FilterUtils.buildFilter(['descricao'])(searchParam);

  search = `${search} and ativa==true and arquivamento==true`;

  return axios.get(resource, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const save = values => {
  if (values.id) {
    return axios.put(`${resource}/${values.id}`, values);
  }

  return axios.post(resource, values);
};

export const republish = () => {
  return axios.post(`${resource}/republish`);
};
