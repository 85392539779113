export enum FileType {
  ABS = 'audio/x-mpeg',
  AI = 'application/postscript',
  AIF = 'audio/x-aiff',
  AIFC = 'audio/x-aiff',
  AIFF = 'audio/x-aiff',
  AIM = 'application/x-aim',
  ART = 'image/x-jg',
  ASF = 'video/x-ms-asf',
  ASX = 'video/x-ms-asf',
  AU = 'audio/basic',
  AVI = 'video/x-msvideo',
  AVX = 'video/x-rad-screenplay',
  BCPIO = 'application/x-bcpio',
  BIN = 'application/octet-stream',
  BMP = 'image/bmp',
  BODY = 'text/html',
  CDF = 'application/x-cdf',
  CER = 'application/pkix-cert',
  CLASS = 'application/java',
  CPIO = 'application/x-cpio',
  CSH = 'application/x-csh',
  CSS = 'text/css',
  DIB = 'image/bmp',
  DOC = 'application/msword',
  DTD = 'application/xml-dtd',
  DV = 'video/x-dv',
  DVI = 'application/x-dvi',
  EOT = 'application/vnd.ms-fontobject',
  EPS = 'application/postscript',
  ETX = 'text/x-setext',
  EXE = 'application/octet-stream',
  GIF = 'image/gif',
  GTAR = 'application/x-gtar',
  GZ = 'application/x-gzip',
  HDF = 'application/x-hdf',
  HQX = 'application/mac-binhex40',
  HTC = 'text/x-component',
  HTM = 'text/html',
  HTML = 'text/html',
  IEF = 'image/ief',
  JAD = 'text/vnd.sun.j2me.app-descriptor',
  JAR = 'application/java-archive',
  JAVA = 'text/x-java-source',
  JNLP = 'application/x-java-jnlp-file',
  JPE = 'image/jpeg',
  JPEG = 'image/jpeg',
  JPG = 'image/jpeg',
  JS = 'application/javascript',
  JSF = 'text/plain',
  JSON = 'application/json',
  JSPF = 'text/plain',
  KAR = 'audio/midi',
  LATEX = 'application/x-latex',
  M3U = 'audio/x-mpegurl',
  MAC = 'image/x-macpaint',
  MAN = 'text/troff',
  MATHML = 'application/mathml+xml',
  ME = 'text/troff',
  MID = 'audio/midi',
  MIDI = 'audio/midi',
  MIF = 'application/x-mif',
  MOV = 'video/quicktime',
  MOVIE = 'video/x-sgi-movie',
  MP1 = 'audio/mpeg',
  MP2 = 'audio/mpeg',
  MP3 = 'audio/mpeg',
  MP4 = 'video/mp4',
  MPA = 'audio/mpeg',
  MPE = 'video/mpeg',
  MPEG = 'video/mpeg',
  MPEGA = 'audio/x-mpeg',
  MPG = 'video/mpeg',
  MPV2 = 'video/mpeg2',
  MS = 'application/x-wais-source',
  NC = 'application/x-netcdf',
  ODA = 'application/oda',
  ODB = 'application/vnd.oasis.opendocument.database',
  ODC = 'application/vnd.oasis.opendocument.chart',
  ODF = 'application/vnd.oasis.opendocument.formula',
  ODG = 'application/vnd.oasis.opendocument.graphics',
  ODI = 'application/vnd.oasis.opendocument.image',
  ODM = 'application/vnd.oasis.opendocument.text-master',
  ODP = 'application/vnd.oasis.opendocument.presentation',
  ODS = 'application/vnd.oasis.opendocument.spreadsheet',
  ODT = 'application/vnd.oasis.opendocument.text',
  OTG = 'application/vnd.oasis.opendocument.graphics-template',
  OTH = 'application/vnd.oasis.opendocument.text-web',
  OTP = 'application/vnd.oasis.opendocument.presentation-template',
  OTS = 'application/vnd.oasis.opendocument.spreadsheet-template',
  OTT = 'application/vnd.oasis.opendocument.text-template',
  OGX = 'application/ogg',
  OGV = 'video/ogg',
  OGA = 'audio/ogg',
  OGG = 'audio/ogg',
  OTF = 'application/x-font-opentype',
  SPX = 'audio/ogg',
  FLAC = 'audio/flac',
  ANX = 'application/annodex',
  AXA = 'audio/annodex',
  AXV = 'video/annodex',
  XSPF = 'application/xspf+xml',
  PBM = 'image/x-portable-bitmap',
  PCT = 'image/pict',
  PDF = 'application/pdf',
  PGM = 'image/x-portable-graymap',
  PIC = 'image/pict',
  PICT = 'image/pict',
  PLS = 'audio/x-scpls',
  PNG = 'image/png',
  PNM = 'image/x-portable-anymap',
  PNT = 'image/x-macpaint',
  PPM = 'image/x-portable-pixmap',
  PPT = 'application/vnd.ms-powerpoint',
  PPS = 'application/vnd.ms-powerpoint',
  PS = 'application/postscript',
  PSD = 'image/vnd.adobe.photoshop',
  QT = 'video/quicktime',
  QTI = 'image/x-quicktime',
  QTIF = 'image/x-quicktime',
  RAS = 'image/x-cmu-raster',
  RDF = 'application/rdf+xml',
  RGB = 'image/x-rgb',
  RM = 'application/vnd.rn-realmedia',
  ROFF = 'text/troff',
  RTF = 'application/rtf',
  RTX = 'text/richtext',
  SFNT = 'application/font-sfnt',
  SH = 'application/x-sh',
  SHAR = 'application/x-shar',
  SIT = 'application/x-stuffit',
  SND = 'audio/basic',
  SRC = 'application/x-wais-source',
  SV4CPIO = 'application/x-sv4cpio',
  SV4CRC = 'application/x-sv4crc',
  SVG = 'image/svg+xml',
  SVGZ = 'image/svg+xml',
  SWF = 'application/x-shockwave-flash',
  T = 'text/troff',
  TAR = 'application/x-tar',
  TCL = 'application/x-tcl',
  TEX = 'application/x-tex',
  TEXI = 'application/x-texinfo',
  TEXINFO = 'application/x-texinfo',
  TIF = 'image/tiff',
  TIFF = 'image/tiff',
  TR = 'text/troff',
  TSV = 'text/tab-separated-values',
  TTF = 'application/x-font-ttf',
  TXT = 'text/plain',
  ULW = 'audio/basic',
  USTAR = 'application/x-ustar',
  VXML = 'application/voicexml+xml',
  XBM = 'image/x-xbitmap',
  XHT = 'application/xhtml+xml',
  XHTML = 'application/xhtml+xml',
  XLS = 'application/vnd.ms-excel',
  XML = 'application/xml',
  XPM = 'image/x-xpixmap',
  XSL = 'application/xml',
  XSLT = 'application/xslt+xml',
  XUL = 'application/vnd.mozilla.xul+xml',
  XWD = 'image/x-xwindowdump',
  VSD = 'application/vnd.visio',
  WAV = 'audio/x-wav',
  WBMP = 'image/vnd.wap.wbmp',
  WML = 'text/vnd.wap.wml',
  WMLC = 'application/vnd.wap.wmlc',
  WMLS = 'text/vnd.wap.wmlsc',
  WMLSCRIPTC = 'application/vnd.wap.wmlscriptc',
  WMV = 'video/x-ms-wmv',
  WOFF = 'application/font-woff',
  WOFF2 = 'application/font-woff2',
  WRL = 'model/vrml',
  WSPOLICY = 'application/wspolicy+xml',
  Z = 'application/x-compress',
  ZIP = 'application/zip',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
}
