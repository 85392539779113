import {
  ActionButton,
  ActionsGroup,
  FormattedDateTime,
  Row,
  Table
} from '@elotech/components';
import { QuickView, StatusAssinatura, TableContainer } from 'common/components';
import { useLoading } from 'common/hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArquivoAssinaturaService, ParametroService } from 'service';
import { getUserInfoSelector } from 'state';
import { ProcessoArquivoSituacaoEnum } from 'types';

import { PERMITE_EXCLUSAO_SOLICITACOES_ASSINATURA_SOLICITANTE } from '../../parametros/Parametros';
import AssinaturaActionButton from './AssinaturaActionButton';

export const AssinaturaQuickView = ({
  arquivo,
  onClose,
  onChange,
  isUsuarioAdministrador
}) => {
  const [loading, setLoading] = useLoading();
  const usuarioLogado = useSelector(getUserInfoSelector);
  const [canRemove, setCanRemove] = useState(false);
  const [assinaturas, setAssinaturas] = useState(arquivo?.assinaturas || []);

  useEffect(() => {
    setLoading(
      ParametroService.findParametro(
        PERMITE_EXCLUSAO_SOLICITACOES_ASSINATURA_SOLICITANTE
      ).then(({ data }) => setCanRemove(data.valor === 'S'))
    );
  }, []);

  const docRecusado = arquivo.assinaturas?.some(
    a => a.dataRecusa || a.situacao === ProcessoArquivoSituacaoEnum.REJEITADO
  );

  const deleteById = (assinatura, index) => {
    return ArquivoAssinaturaService.deleteById(assinatura.id).then(() => {
      onChange();
      setAssinaturas(prev => [
        ...prev.slice(0, index),
        ...prev.slice(index + 1)
      ]);
    });
  };

  const habilitaRemocao = assinatura => {
    return (
      isUsuarioAdministrador ||
      (canRemove &&
        assinatura.situacao === ProcessoArquivoSituacaoEnum.PENDENTE &&
        usuarioLogado.username === assinatura.usuarioSolicitante?.id)
    );
  };

  const habilitaAssinar = assinatura => {
    return (
      !assinatura.dataAssinatura &&
      !assinatura.dataRecusa &&
      assinatura.usuario?.id === usuarioLogado.username
    );
  };

  return (
    <>
      <QuickView onClose={onClose} fixed hasScroll>
        <QuickView.Header>
          <Row>
            <QuickView.Item
              size={12}
              header="Nome documento"
              value={arquivo.nome}
            />
          </Row>
        </QuickView.Header>
        <Row style={{ marginLeft: 1, marginRight: 1 }}>
          <TableContainer>
            <Table
              loading={loading}
              values={[
                ...assinaturas.filter(a => !a.dataAssinatura),
                ...assinaturas.filter(a => a.dataAssinatura)
              ]}
            >
              <Table.Column
                header="Nome Assinante"
                value={assinatura => assinatura.usuario.nome}
              />
              <Table.Column
                header="Dt/Hr Atualização"
                value={assinatura => (
                  <FormattedDateTime
                    value={assinatura.dataAssinatura || assinatura.dataRecusa}
                  />
                )}
              />
              <Table.Column
                header="Status"
                value={assinatura => (
                  <StatusAssinatura
                    situacao={assinatura.situacao}
                    motivoRecusa={assinatura.motivoRecusa}
                  />
                )}
              />
              <Table.Column
                header="Motivo Recusa"
                defaultHidden={!docRecusado}
                value={assinatura => assinatura.motivoRecusa}
              />
              <Table.Column
                header=""
                value={(assinatura, index) => {
                  return (
                    <ActionsGroup>
                      {habilitaAssinar(assinatura) && (
                        <AssinaturaActionButton
                          assinatura={{
                            ...assinatura,
                            identificador: arquivo.identificador
                          }}
                          onUpdateFile={onChange}
                        />
                      )}
                      {habilitaRemocao(assinatura) && (
                        <ActionButton
                          icon="trash"
                          label="Remover"
                          onClick={() => deleteById(assinatura, index)}
                        />
                      )}
                    </ActionsGroup>
                  );
                }}
              />
            </Table>
          </TableContainer>
        </Row>
      </QuickView>
    </>
  );
};
