import {
  ProcessoAgrupadoService,
  ProcessoService,
  ProcessoValidacaoService
} from 'service';

import {
  SITUACAO_PADRAO_ARQUIVAMENTO,
  SITUACAO_PADRAO_ENCAMINHAMENTO,
  SITUACAO_PADRAO_REABERTURA,
  SITUACAO_PADRAO_RECEBIMENTO
} from '../../parametros/Parametros';
import { TramiteArquivarForm } from './TramiteArquivarForm';
import { TramiteEncaminharForm } from './TramiteEncaminharForm';
import { TramiteReabrirForm } from './TramiteReabrirForm';
import { TramiteReceberForm } from './TramiteReceberForm';

export const getLocalByRoteiro = (processo, tipo) => {
  const roteiroLocal = (processo.assunto.roteiro?.roteiroLocal || [])
    .sort((rl1, rl2) => rl1.sequencia - rl2.sequencia)
    .at?.(getIndexByTipo(processo, tipo));

  return roteiroLocal?.local;
};

const getIndexByTipo = (processo, tipo) => {
  const tramites = processo.tramites.filter(
    t => t.funcao !== 'Arquivado' && t.funcao !== 'Aberto'
  );

  if (tipo === 'encaminhar') {
    return tramites.length / 2;
  }

  if (tipo === 'reabrir') {
    return Number.isInteger(tramites.length / 2)
      ? tramites.length / 2
      : tramites.length / 2 + 0.5;
  }

  return (
    (Number.isInteger(tramites.length / 2)
      ? tramites.length / 2
      : tramites.length / 2 + 0.5) - 1
  );
};

export const getLoteUltimoTramite = (processos = []) => {
  if (processos.length > 0) {
    return processos[0].ultimoTramite.lote;
  }

  return 0;
};

export const componentByTipo = {
  RECEBER: props => <TramiteReceberForm {...props} />,
  RECEBER_ENCAMINHAR: props => (
    <TramiteEncaminharForm {...props} acao="RECEBER_ENCAMINHAR" />
  ),
  ENCAMINHAR: props => <TramiteEncaminharForm {...props} />,
  ARQUIVAR: props => <TramiteArquivarForm {...props} />,
  REABRIR: props => <TramiteReabrirForm {...props} />,
  REABRIR_ENCAMINHAR: props => (
    <TramiteEncaminharForm {...props} acao="REABRIR_ENCAMINHAR" />
  )
};

const handleRecebimento = (tramite, hasParecerAcao = true) => {
  const parecerTramite = hasParecerAcao ? tramite.parecerAcao : tramite.parecer;

  return {
    ...tramite,
    parecer: parecerTramite,
    processos: tramite.processos.map(processo => ({
      ...processo,
      parecer: processo.parecer || parecerTramite
    }))
  };
};

const handlePareceres = tramite => {
  return {
    ...tramite,
    processos: tramite.processos.map(processo => ({
      ...processo,
      parecer: processo.parecer || tramite.parecer
    }))
  };
};

export const tramitarByTipo = values => {
  const tramite = handlePareceres(values);

  const RECEBER = (wrapper = tramite) =>
    ProcessoService.receberProcessos(wrapper);
  const ENCAMINHAR = () => ProcessoService.encaminharProcessos(tramite);
  const ARQUIVAR = () => ProcessoService.arquivarProcessos(tramite);
  const REABRIR = () => ProcessoService.reabrirProcessos(tramite);
  const RECEBER_ENCAMINHAR = (wrapper = handleRecebimento(values)) =>
    RECEBER(wrapper).then(() => ENCAMINHAR());
  const REABRIR_ENCAMINHAR = () =>
    REABRIR().then(() => RECEBER_ENCAMINHAR(handleRecebimento(values, false)));

  return {
    RECEBER,
    ENCAMINHAR,
    ARQUIVAR,
    REABRIR,
    RECEBER_ENCAMINHAR,
    REABRIR_ENCAMINHAR
  };
};

export const tramitarEmLoteByTipo = values => {
  const tramite = handlePareceres(values);

  const RECEBER = (wrapper = tramite) =>
    ProcessoAgrupadoService.receberProcessosEmLote(wrapper);
  const ENCAMINHAR = () =>
    ProcessoAgrupadoService.encaminharProcessosEmLote(tramite);
  const ARQUIVAR = () =>
    ProcessoAgrupadoService.arquivarProcessosEmLote(tramite);
  const REABRIR = () => ProcessoAgrupadoService.reabrirProcessosEmLote(tramite);
  const RECEBER_ENCAMINHAR = (wrapper = handleRecebimento(values)) =>
    RECEBER(wrapper).then(() => ENCAMINHAR());
  const REABRIR_ENCAMINHAR = () =>
    REABRIR().then(() => RECEBER_ENCAMINHAR(handleRecebimento(values, false)));

  return {
    RECEBER,
    ENCAMINHAR,
    ARQUIVAR,
    REABRIR,
    RECEBER_ENCAMINHAR,
    REABRIR_ENCAMINHAR
  };
};

export const paramSituacaoByTipo = {
  RECEBER: SITUACAO_PADRAO_RECEBIMENTO,
  RECEBER_ENCAMINHAR: SITUACAO_PADRAO_ENCAMINHAMENTO,
  ENCAMINHAR: SITUACAO_PADRAO_ENCAMINHAMENTO,
  ARQUIVAR: SITUACAO_PADRAO_ARQUIVAMENTO,
  REABRIR: SITUACAO_PADRAO_REABERTURA,
  REABRIR_ENCAMINHAR: SITUACAO_PADRAO_ENCAMINHAMENTO
};

export const validarByTipo = {
  RECEBER: ProcessoValidacaoService.validarRecebimento,
  ENCAMINHAR: ProcessoValidacaoService.validarEncaminhamento,
  ARQUIVAR: ProcessoValidacaoService.validarArquivamento,
  REABRIR: ProcessoValidacaoService.validarReabertura,
  RECEBER_ENCAMINHAR: ProcessoValidacaoService.validarRecebimento,
  REABRIR_ENCAMINHAR: ProcessoValidacaoService.validarReabertura
};

export const titleByTipo = {
  RECEBER: 'Receber',
  RECEBER_ENCAMINHAR: 'Receber e Encaminhar',
  ENCAMINHAR: 'Encaminhar',
  ARQUIVAR: 'Arquivar',
  REABRIR: 'Reabrir',
  REABRIR_ENCAMINHAR: 'Reabrir e Encaminhar'
};
