export enum TipoGrauSigiloEnum {
  ULTRASSECRETA,
  SECRETA,
  RESERVADA
}

export const tipoGrauSigiloEnum = {
  ULTRASSECRETA: 'Ultrassecreta',
  SECRETA: 'Secreta',
  RESERVADA: 'Reservada'
};

export const prazoTipoGrauSigiloEnum = {
  ULTRASSECRETA: 25,
  SECRETA: 15,
  RESERVADA: 5
};
