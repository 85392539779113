import {
  ActionButton,
  ActionsGroup,
  Col,
  FormikAutocomplete,
  Row,
  Table,
  TableChild
} from '@elotech/components';
import { InlineButton, TableContainer } from 'common/components';
import { Formik } from 'formik';
import { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { ArquivoAssinaturaService, UsuarioService } from 'service';

export const SolicitarAssinatura = ({
  expandedAnexoIds,
  identificador,
  setLoading,
  onUpdateFile,
  onAnexoClick
}) => {
  const [usuarios, setUsuarios] = useState([]);

  const onSubmit = () => {
    const usuariosIds = usuarios.map(usuario => usuario.id);
    setLoading(
      ArquivoAssinaturaService.solicitarAssinatura({
        identificador,
        usuarios: usuariosIds
      }).then(() => {
        onUpdateFile();
        setUsuarios([]);
      })
    );
  };

  if (expandedAnexoIds.length > 0 && expandedAnexoIds.includes(identificador)) {
    return (
      <TableChild>
        <Formik enableReinitialize onSubmit={onSubmit} initialValues={{}}>
          {formProps => {
            return (
              <>
                <Row>
                  <FormikAutocomplete
                    size={5}
                    name="usuario"
                    label="Usuário"
                    onSearch={UsuarioService.findAllAutocomplete}
                    onItemSelected={(_, value) => {
                      const index = usuarios.findIndex(u => u.id === value.id);
                      if (value && index < 0) {
                        setUsuarios([...usuarios, value]);
                      }
                      formProps.setFieldValue('usuario', null);
                    }}
                    getOptionLabel={s =>
                      s.cpfMask ? `${s.cpfMask} - ${s.nome}` : s.nome
                    }
                  />
                </Row>
                {usuarios.length > 0 && (
                  <TableContainer>
                    <Table
                      values={usuarios}
                      keyExtractor={usuario => usuario.id}
                      defaultSort={{ direction: 'desc', name: 'id' }}
                    >
                      <Table.Column
                        header="CPF Solicitado"
                        value={usuario => usuario.cpfMask}
                      />
                      <Table.Column
                        header="Nome Solicitado"
                        value={usuario => usuario.nome}
                      />
                      <Table.Column
                        header=""
                        value={(_, index) => (
                          <ActionsGroup>
                            <ActionButton
                              icon="trash"
                              label="Remover"
                              onClick={() =>
                                setUsuarios(prev => [
                                  ...prev.slice(0, index),
                                  ...prev.slice(index + 1)
                                ])
                              }
                            />
                          </ActionsGroup>
                        )}
                      />
                    </Table>
                  </TableContainer>
                )}
                <Row>
                  <Col md={12} sm={12}>
                    <FormGroup>
                      <InlineButton
                        type="submit"
                        label="Solicitar"
                        disabled={usuarios.length <= 0}
                        onClick={formProps.submitForm}
                      />
                      <InlineButton
                        color="neutral"
                        label="Cancelar"
                        onClick={() => onAnexoClick(identificador)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            );
          }}
        </Formik>
      </TableChild>
    );
  }

  return null;
};
