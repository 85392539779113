import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  children: ReactNode;
};

export const MediaDefault = ({ children }: Props) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
