import { axios } from 'libs';

const resource = '/dashboard-ouvidoria';

export function loadByTipo(idEntidade) {
  return axios.get(
    idEntidade
      ? `${resource}/processo-por-tipo/${idEntidade}`
      : `${resource}/processo-por-tipo`
  );
}

export function loadByStatus(idEntidade) {
  return axios.get(
    idEntidade
      ? `${resource}/processo-por-status/${idEntidade}`
      : `${resource}/processo-por-status`
  );
}
