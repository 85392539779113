import { axios } from 'libs';

const resource = '/processos/validacao';

export const validarEncaminhamento = id => {
  return axios.get(`${resource}/encaminhamento${id}`);
};

export const validarRecebimento = id => {
  return axios.get(`${resource}/recebimento${id}`);
};

export const validarArquivamento = id => {
  return axios.get(`${resource}/arquivamento${id}`);
};

export const validarReabertura = id => {
  return axios.get(`${resource}/reabertura${id}`);
};
