export const isNumber = (value: any): boolean => {
  return new RegExp('^[0-9]+$').test(value);
};

export const formatCurrency = (
  value: number = 0,
  intl,
  fractionDigits: number = 4
): string => {
  return intl.formatNumber(value, {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  });
};

export const formatToDecimal = (
  value: number = 0,
  intl,
  fractionDigits: number = 4
): string => {
  return intl.formatNumber(value, { minimumFractionDigits: fractionDigits });
};

export const getSumFromObjectArrayProp = (
  objectArray: any[],
  propName: string
): number => {
  let sum: number = 0;

  if (objectArray.length) {
    const isTypeObject = (value): boolean => typeof value === 'object';
    const containsProperty = (value): boolean => value.hasOwnProperty(propName);
    const isTypeNumber = (value): boolean => typeof value === 'number';

    objectArray.forEach(object => {
      if (
        isTypeObject(object) &&
        containsProperty(object) &&
        isTypeNumber(object[propName])
      ) {
        sum += object[propName];
      }
    });
  }

  return sum;
};

export const convertNumberToStringForValidation = (value: number): string =>
  value || value === 0 ? value.toString() : '0';
