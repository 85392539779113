import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/locais';
const defaultSort = {
  sort: 'id,asc'
};

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const save = local => {
  return local.id
    ? axios.put(`${resource}/${local.id}`, local)
    : axios.post(resource, local);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const findAllLocaisOrigemAutocomplete = (
  searchParam = '',
  pagination,
  sort = defaultSort
) => {
  const search = isNumber(searchParam)
    ? `local=${searchParam}`
    : `descricao=${searchParam}`;

  return axios.get(`${resource}/usuario?${search}`, {
    params: {
      ...sort,
      ...pagination
    }
  });
};

export const findAllLocaisAutocomplete = (
  searchParam = '',
  pagination,
  sort = defaultSort,
  excludeId
) => {
  let search = isNumber(searchParam)
    ? `id.id==${searchParam}`
    : FilterUtils.buildFilter(['descricao'])(searchParam);

  search += ' and ativo==true';

  if (excludeId) {
    search += ` and id.id!=${excludeId}`;
  }

  return axios.get(resource, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const findAllByLocalPai = id => {
  return axios.get(`${resource}/local-pai/${id}`);
};

export const getRoteirosByLocalId = id => {
  return axios.get(`${resource}/${id}/roteiros`);
};

export const findByEntidadeSearch = (entidade, search = '') => {
  return axios.get(`${resource}/entidade/${entidade}`, {
    params: {
      search
    }
  });
};
