import { Editor } from 'tinymce';

import { emailAutomaticoEnum } from '../type/enum/EmailAutomaticoEnum';

export const getVariaveisContrato = (editor: Editor) => {
  const botoes: any = [];

  Object.entries(emailAutomaticoEnum).forEach(([chave, descricao]) => {
    const botao = {
      type: 'menuitem',
      text: descricao,
      onAction: function () {
        editor.insertContent(`[[${chave}]]`);
      }
    };
    botoes.push(botao);
  });
  return botoes;
};
