import { ExtraInfo, FormattedDateTime, Loading } from '@elotech/components';
import { useLoading } from 'common/hooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { ProcessoArquivoService } from 'service';
import { getIsLoggedSelector } from 'state';
import styled from 'styled-components';

import { situacaoByAnalise } from './ProcessoArquivos';
import { getLegenda } from './ProcessoArquivoUtils';

const DISABLED_DRAG_ELEMENTS = ['A', 'BUTTON'];

const StyledCustom = styled.div`
  .panel-table-outer th {
    min-width: initial !important;
  }

  .panel-table-outer td {
    min-width: initial !important;
  }
`;

const ARQUIVOS_ORDEM_SORTABLE_THEAD_KEY = 'arquivos-ordem-thead';

const SortableList = SortableContainer(
  ({
    items = [],
    renderActionButtons,
    renderInnerComponent,
    isProcessoMP,
    renderHeaderAgruparArquivo,
    onSortClick,
    isItemSortable
  }) => {
    const isLogged = useSelector(getIsLoggedSelector);

    const renderSortableItem = (item, index) => {
      const isSortable = isItemSortable?.(item) ?? true;
      const ItemComponent = isSortable ? SortableItem : Item;

      return (
        <ItemComponent
          isProcessoMP={isProcessoMP}
          item={item}
          index={index}
          key={item.ordem}
          renderActionButtons={renderActionButtons}
          renderInnerComponent={renderInnerComponent}
        />
      );
    };

    return (
      <StyledCustom>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <thead>
              <tr>
                <th></th>
                <th
                  name="ordem"
                  id={ARQUIVOS_ORDEM_SORTABLE_THEAD_KEY}
                  className="sortable"
                  onClick={onSortClick}
                >
                  Ordem
                </th>
                <th name="nome" className="sortable" onClick={onSortClick}>
                  Nome Documento
                </th>
                <th name="usuario" className="sortable" onClick={onSortClick}>
                  Usuário
                </th>
                <th
                  name="dataCriacao"
                  className="sortable"
                  onClick={onSortClick}
                >
                  Data Envio
                </th>
                {isLogged && (
                  <th
                    name="qtdAssinaturas"
                    className="sortable"
                    onClick={onSortClick}
                  >
                    Assinaturas
                  </th>
                )}
                {isLogged && (
                  <th
                    name="qtdAssinaturasPendentes"
                    className="sortable"
                    onClick={onSortClick}
                  >
                    Assinaturas Pendentes
                  </th>
                )}
                <th name="analise" className="sortable" onClick={onSortClick}>
                  Situação Análise
                </th>
                <th
                  name="motivoRecusa"
                  className="sortable"
                  onClick={onSortClick}
                >
                  Motivo Recusa
                </th>
                <th
                  name="sequenciaTramitacao"
                  className="sortable"
                  onClick={onSortClick}
                >
                  Trâmite
                </th>
                <th>{renderHeaderAgruparArquivo()}</th>
              </tr>
            </thead>
            <tbody>
              {items.length > 0 ? (
                items.map((item, index) => renderSortableItem(item, index))
              ) : (
                <tr>
                  <td colSpan={7} className="center">
                    Sem Registros
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </StyledCustom>
    );
  }
);

const Item = ({
  item,
  renderActionButtons,
  renderInnerComponent,
  isProcessoMP
}) => {
  const isLogged = useSelector(getIsLoggedSelector);

  return (
    <>
      <tr key={item.ordem} data-seq-tramite={item.sequenciaTramitacao}>
        <td>
          {
            <>
              {item.qtdAssinaturas > 0 && (
                <ExtraInfo
                  letter={'A'}
                  value={'Possui Assinatura'}
                  color={'negative'}
                />
              )}
              {getLegenda(item, isProcessoMP)}
            </>
          }
        </td>
        <td>{item.ordemCalculada}</td>
        <td>{item.nome}</td>
        <td>{item.usuario}</td>
        <td>
          <FormattedDateTime value={item.dataCriacao} />
        </td>
        {isLogged && <td>{item.qtdAssinaturas}</td>}
        {isLogged && <td>{item.qtdAssinaturasPendentes}</td>}
        <td>
          {item.cancelado ? (
            <>
              {'Cancelado em '} <FormattedDateTime value={item.dataCriacao} />
            </>
          ) : (
            situacaoByAnalise[item.analise]
          )}
        </td>
        <td>{item.cancelado ? item.motivoCancelamento : item.motivoRecusa}</td>
        <td>{item.sequenciaTramitacao}</td>
        <td>{renderActionButtons?.(item)}</td>
      </tr>
      {renderInnerComponent?.(item)}
    </>
  );
};

const SortableItem = SortableElement(props => {
  return <Item {...props} />;
});

const ProcessoArquivosSortable = ({
  arquivos = [],
  handleFiles,
  renderActionButtons,
  renderInnerComponent,
  isProcessoMP = false,
  renderHeaderAgruparArquivo,
  isItemSortable,
  onSortChange,
  sort
}) => {
  const { search: id } = useLocation();
  const [loading, setLoading] = useLoading();

  const onSortClick = event => {
    const isAsc =
      (!event.target.classList.contains('asc') &&
        !event.target.classList.contains('desc')) ||
      event.target.classList.contains('desc');

    event.target.parentNode.childNodes.forEach(thTable => {
      thTable.classList.remove('asc');
      thTable.classList.remove('desc');
    });

    event.target.classList.add(isAsc ? 'asc' : 'desc');

    const column = event.target.getAttribute('name');

    onSortChange({
      column: column,
      dir: isAsc ? 'asc' : 'desc'
    });
  };

  useEffect(() => {
    if (!sort) {
      const sortableTableHead = document.getElementById(
        ARQUIVOS_ORDEM_SORTABLE_THEAD_KEY
      );
      sortableTableHead?.parentNode.childNodes.forEach(thTable => {
        thTable.classList.remove('asc');
        thTable.classList.remove('desc');
      });
    }
  }, [sort]);

  const reorder = (oldIndex, newIndex) => {
    if (oldIndex === newIndex) {
      return;
    }

    const reorderedFiles = [...arquivos];
    const orders = reorderedFiles.map(a => a.ordem);
    const [removed] = reorderedFiles.splice(oldIndex, 1);
    reorderedFiles.splice(newIndex, 0, removed);

    const newFiles = reorderedFiles.map((f, index) => ({
      ...f,
      ordem: orders[index]
    }));

    return setLoading(
      ProcessoArquivoService.atualizarArquivos(id, newFiles).then(() =>
        handleFiles(newFiles)
      )
    );
  };

  const shouldCancelStart = event => {
    return DISABLED_DRAG_ELEMENTS.includes(event.target?.tagName);
  };

  const getValue = (arquivo, column) => {
    if (arquivo.cancelado && column === 'motivoRecusa') {
      return arquivo.motivoCancelamento;
    }

    if (arquivo.cancelado && column === 'analise') {
      return `Cancelado em ${arquivo.dataCancelamento}`;
    }
    return arquivo[column] || '';
  };

  const sortedArquivos = sort
    ? [...arquivos].sort((a, b) => {
        const column = sort.column;

        if (sort.dir === 'asc') {
          return getValue(a, column) > getValue(b, column) ? 1 : -1;
        }

        return getValue(a, column) < getValue(b, column) ? 1 : -1;
      })
    : arquivos;

  return (
    <>
      <Loading loading={loading} />
      <SortableList
        isProcessoMP={isProcessoMP}
        items={sortedArquivos}
        helperClass="onDrag"
        shouldCancelStart={shouldCancelStart}
        renderActionButtons={renderActionButtons}
        renderInnerComponent={renderInnerComponent}
        onSortEnd={({ oldIndex, newIndex }) => reorder(oldIndex, newIndex)}
        renderHeaderAgruparArquivo={renderHeaderAgruparArquivo}
        onSortClick={onSortClick}
        isItemSortable={isItemSortable}
      />
    </>
  );
};

export default ProcessoArquivosSortable;
