import { axios } from 'libs';

const resource = '/tributos';

export const possuiIntegracao = () => {
  return axios.get(`${resource}/integracao`);
};

export function integraPessoaAiseProcesso(processo) {
  return axios.post(`${resource}/${processo.id}`);
}

export const getRequerimento = (entidade, exercicio, requerimento) => {
  return axios.get(
    `${resource}/requerimentos/${entidade}/${exercicio}/${requerimento}`
  );
};
