import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/assuntos';
const defaultSort = {
  sort: 'id,asc'
};

const buildSearch = (search = '', filter) => {
  let newSearch = filter;

  if (isNumber(search)) {
    newSearch += ` and id=="${search}"`;
  } else {
    newSearch += ` and descricao=="*${search}*"`;
  }

  return newSearch;
};

export const findAllAutocomplete = (
  searchParam = '',
  idExclude,
  entidadeAtual,
  pagination,
  sort = defaultSort
) => {
  let search = 'ativo==true';

  if (searchParam) {
    search += isNumber(searchParam)
      ? ` and id==${searchParam}`
      : ` and ${FilterUtils.buildFilter(['descricao'])(searchParam)}`;
  }

  if (idExclude) {
    search += ` and id!=${idExclude}`;
  }

  return axios.get(`${resource}/find-all-filtrado-entidade`, {
    params: {
      search,
      entidadeAtual,
      ...sort,
      ...pagination
    }
  });
};

export const findAllAssuntosInternoAutocomplete =
  tipo =>
  (search, pagination, sort = defaultSort, tipoProcessoId = tipo) => {
    const filter = buildSearch(search, 'ativo==true');

    return axios.get(`${resource}/find-all-filtrado-entidade`, {
      params: {
        search: filter,
        ...sort,
        ...pagination,
        tipoProcessoId
      }
    });
  };

export const findAllAssuntosExternosAutocomplete =
  (tipoProcessoId, entidadeAtual) =>
  (search, pagination, sort = defaultSort) => {
    const filter = buildSearch(
      search,
      'assuntoInterno==false and ouvidoria==false and ativo==true'
    );

    return axios.get(`${resource}/find-all-filtrado-entidade`, {
      params: {
        search: filter,
        entidadeAtual,
        ...sort,
        ...pagination,
        tipoProcessoId
      }
    });
  };

export const findAllFilterAssuntosOuvidoriaAutocomplete =
  (tipoProcessoId, entidadeAtual) =>
  (searchParam = '', pagination, sort = defaultSort) => {
    const search = buildSearch(searchParam, 'ouvidoria==true and ativo==true');

    return axios.get(`${resource}/find-all-filtrado-entidade`, {
      params: {
        search,
        entidadeAtual,
        ...sort,
        ...pagination,
        tipoProcessoId
      }
    });
  };

export const findAllPesquisa =
  ({ tipoProcessoId, entidadeAtual, isOuvidoria }) =>
  (
    searchParam = '',
    pagination = {
      size: 999
    },
    sort = {
      sort: 'descricao,asc'
    }
  ) => {
    const ouvidoria = isOuvidoria
      ? 'ouvidoria==true'
      : 'assuntoInterno==false and ouvidoria==false';

    const search = buildSearch(searchParam, `${ouvidoria} and ativo==true`);

    return axios.get(`${resource}/pesquisa`, {
      params: {
        search,
        entidadeAtual,
        ...sort,
        ...pagination,
        tipoProcessoId
      }
    });
  };

export const findAll = (search, pagination, sort = { sort: 'id,desc' }) => {
  return axios.get(resource, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const findAllLogs = id => {
  return axios.get(`${resource}/history${id}`);
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = assunto => {
  return assunto.id
    ? axios.put(`${resource}/${assunto.id}`, assunto)
    : axios.post(resource, assunto);
};

export const findAllByAssuntoPai = id => {
  return axios.get(`${resource}/assunto-pai/${id}`);
};

export const assuntosMaisUtilizados = username => {
  return axios.get(`${resource}/assuntos-mais-utilizados`, {
    params: {
      username: username
    }
  });
};

export const republish = () => {
  return axios.post(`${resource}/republish`);
};
