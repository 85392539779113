import { KeycloakTypes } from 'state/types';

const initialState = {
  keycloakLoaded: false,
  currentUser: {},
  userExists: {},
  profile: {}
};

export const KeycloakReducer = (state = initialState, action) => {
  switch (action.type) {
    case KeycloakTypes.GET_USER_DATA: {
      return {
        ...state,
        keycloakLoaded: true,
        currentUser: action.payload,
        userExists: {
          userExists: true
        }
      };
    }
    case KeycloakTypes.SET_USER_PROFILE: {
      return {
        ...state,
        profile: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
