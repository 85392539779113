import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef
} from 'react';

export type InlineButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  label?: string;
  icon?: string;
  type?: 'button' | 'submit' | 'reset';
  color?: 'positive' | 'module-color' | 'negative' | 'warning' | 'neutral';
  className?: string;
  disabled?: boolean;
  id?: string;
};

const InlineButton = forwardRef(
  (props: InlineButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      label,
      icon = '',
      children,
      type = 'button',
      className = '',
      color = 'module-color',
      id = 'inline-button',
      ...rest
    } = props;

    return (
      <button
        {...rest}
        ref={ref}
        id={id}
        type={type}
        className={`btn ${color} ${icon && 'icon-left'} inline ${className}`}
      >
        {icon && <em className={icon} />} {label || children}
      </button>
    );
  }
);

InlineButton.displayName = 'InlineButton';

export { InlineButton };
