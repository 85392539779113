import { FilterUtils } from '@elotech/components';
import { axios } from 'libs';

const resource = '/usuariosResponsaveis';
const defaultSort = {
  sort: 'nome,asc'
};

export const findAllAutocomplete = (
  searchParam = '',
  pagination,
  sort = defaultSort
) => {
  const search = FilterUtils.buildFilter(['nome'])(searchParam);

  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};
