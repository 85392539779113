import { KeycloakService } from '@elotech/components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getConfigSelector } from 'state';
import { isAuthenticated } from 'utils';

export const RedirectContainer = ({ children }) => {
  const history = useHistory();
  const isLogged = isAuthenticated();
  const { config } = useSelector(state => ({
    config: getConfigSelector(state)
  }));

  useEffect(() => {
    if (!isLogged && history.location.pathname === '/') {
      if (config.isCloud) {
        KeycloakService.getInstance()
          .init({
            onLoad: 'check-sso',
            useNonce: false,
            checkLoginIframe: false
          })
          .success(authenticated => {
            history.push('/consultaProcesso');

            if (authenticated) {
              window.location.search = '?login=true';
            }
          })
          .error(() => {
            history.push('/consultaProcesso');
          });
      } else {
        history.push('/consultaProcesso');
      }
    }
  }, []);

  useEffect(() => {
    if (isLogged && window.location.search.includes('login')) {
      window.history.replaceState(null, null, window.location.pathname);
      history.push('/');
    }
  }, []);

  return <>{children}</>;
};
