import {
  BasicInput,
  Col,
  Container,
  FAB,
  FormikCheckBox,
  FormikInputInteger,
  Loading,
  Row,
  SectionTitle
} from '@elotech/components';
import { withFormikListOperations } from 'common/hocs';
import { useLoading } from 'common/hooks';
import { SqlModelo, SqlModeloDTO } from 'common/type';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import { FormGroup } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';

import { SqlModeloService } from '../../service';
import { TipoParametroModeloEnum } from '../../type/enum';
import { FormikDetalheConfiguracaoIntegracaoSelect } from '../select-tipo-integracao/FormikDetalheConfiguracaoIntegracaoSelect';
import { ParametroContainer } from './parametros/ParametroContainer';

const Editor = styled(AceEditor)`
  * {
    font-family: inherit;
  }
`;

const validationSchemaSqlModelo = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório').max(200),
  identificador: Yup.string()
    .required('Identificador é obrigatório')
    .max(200, 'Ultrapassou o máximo de 200 caracteres')
    .test(
      'identificador',
      'Variável/Sql com esse identificador já cadastrado',
      function (identificador) {
        const identificadorRepetido = this.resolve(
          Yup.ref('identificadorRepetido')
        );

        return identificador && !identificadorRepetido;
      }
    ),
  parametros: Yup.array()
    .min(1, 'Variável deve possuir pelo menos 1 parâmetro')
    .required('Parâmetros são obrigatórios'),
  fonte: Yup.number().required('Fonte é obrigatório')
});

type Params = {
  id: string;
};

type Props = {
  service: SqlModeloService;
};

const VariaveisComponent = (props: Props) => {
  const [variavel, setVariavel] = useState<SqlModelo>({
    id: undefined,
    identificador: '',
    nome: '',
    script: '',
    prestacao: false,
    parametros: [
      { nome: 'id', identificador: 'id', tipo: TipoParametroModeloEnum.STRING }
    ],
    integracao: false,
    tipoIntegracao: undefined,
    ordemIntegracao: undefined,
    fonte: 12
  });
  const [loading, setLoading] = useLoading();
  const history = useHistory();
  const { id } = useParams<Params>();
  const { service } = props;

  const isEditing = id && id !== 'new';

  useEffect(() => {
    if (isEditing) {
      setLoading(service.findById(id).then(({ data }) => setVariavel(data)));
    }
  }, [id, isEditing, setLoading]);

  const onSubmit = variavel => {
    return setLoading(
      service.saveOrUpdate(variavel).then(() => {
        history.push('/variaveis');
      })
    );
  };

  const onAdd = formProps => (value: SqlModeloDTO, index: number) => {
    const oldParametros = formProps.values.parametros || [];

    let newArrayParametros = [...oldParametros, value];

    if (index >= 0) {
      newArrayParametros = [
        ...oldParametros.slice(0, index),
        value,
        ...oldParametros.slice(index + 1)
      ];
    }

    formProps.setFieldValue('parametros', newArrayParametros);
  };

  const onRemove = formProps => (_, index) => {
    const oldParametros = formProps.values.parametros || [];

    const newArrayParametros = [
      ...oldParametros.slice(0, index),
      ...oldParametros.slice(index + 1)
    ];

    formProps.setFieldValue('parametros', newArrayParametros);
  };

  return (
    <>
      <Container title="Variáveis/Sql" icon="list">
        <Loading loading={loading} />
        <Formik
          enableReinitialize
          initialValues={variavel}
          onSubmit={onSubmit}
          validationSchema={() => validationSchemaSqlModelo}
        >
          {formProps => {
            return (
              <>
                <SectionTitle marginTop="0px">Dados Gerais</SectionTitle>
                <FormGroup>
                  <Row>
                    <BasicInput
                      size={3}
                      maxLength={200}
                      name="nome"
                      label="Nome"
                    />
                    <BasicInput
                      size={3}
                      maxLength={200}
                      name="identificador"
                      label="Identificador"
                      onBlur={({ target }) => {
                        if (target.value) {
                          service
                            .verificaIdentificadorRepetido(
                              id !== 'new' ? id : '',
                              target.value
                            )
                            .then(({ data }) => {
                              formProps.setFieldValue(
                                'identificadorRepetido',
                                data
                              );
                            });
                        } else {
                          formProps.setFieldValue(
                            'identificadorRepetido',
                            false
                          );
                        }
                        formProps.setFieldTouched('identificador', true);
                      }}
                    />
                    <FormikInputInteger
                      size={2}
                      label="Fonte"
                      name="fonte"
                      id="fonte"
                    />
                    <FormikCheckBox
                      size={2}
                      className="mt-xs"
                      label="Prestação"
                      name="prestacao"
                      id="prestacao"
                    />
                    <FormikCheckBox
                      size={2}
                      className="mt-xs"
                      label="Integração"
                      name="integracao"
                      id="integracao"
                    />
                  </Row>
                  {formProps.values.integracao && (
                    <Row>
                      <FormikDetalheConfiguracaoIntegracaoSelect
                        size={4}
                        name="tipoIntegracao"
                        value={formProps.values.tipoIntegracao}
                      />
                      <BasicInput
                        size={2}
                        maxLength={200}
                        name="ordemIntegracao"
                        label="Ordem Geração SQL"
                      />
                    </Row>
                  )}
                </FormGroup>
                <Row>
                  <Col sm={12}>
                    <ParametroContainer
                      error={formProps.errors.parametros}
                      parametros={formProps.values.parametros || []}
                      onAdicionarParametro={onAdd(formProps)}
                      onRemoverParametro={onRemove(formProps)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <SectionTitle>Script</SectionTitle>

                    <div className="form-group">
                      {!!formProps.errors.script && (
                        <small className="typography-caption help-message mb-xs">
                          {formProps.errors.script}
                        </small>
                      )}
                      <Editor
                        mode="sql"
                        width="full"
                        theme="chrome"
                        showPrintMargin={false}
                        fontSize={20}
                        onChange={script =>
                          formProps.setFieldValue('script', script)
                        }
                        value={formProps.values.script}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                          showLineNumbers: true
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="btn-save">
                  <FAB
                    icon="check"
                    iconColor="white"
                    type="submit"
                    title="Salvar"
                    onClick={formProps.submitForm}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

const withFormikOperations = withFormikListOperations<any>(VariaveisComponent);

export { withFormikOperations as VariaveisComponent };
