import { SectionTitle } from '@elotech/components';
import { InlineButton } from 'common/components';
import { useLoading } from 'common/hooks';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ProcessoAndamentoService } from 'service';

import { ProcessoAndamentosForm } from './ProcessoAndamentosForm';
import { ProcessoAndamentosList } from './ProcessoAndamentosList';

export const ProcessoAndamentosContainer = ({
  andamentos = [],
  processosArquivos = [],
  onAdd,
  onEdit,
  onRemove,
  canAdd,
  canEdit = true,
  canRemove = true,
  processo = undefined
}) => {
  const { search: id, hash } = useLocation();
  const [loading, setLoading] = useLoading();
  const [isOpen, setIsOpen] = useState(false);
  const [editingValue, setEditingValue] = useState(undefined);

  useEffect(() => {
    const id = '#andamentos';
    if (!!hash && hash === id) {
      document.querySelector(id).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [hash]);

  const add = andamento => {
    setIsOpen(false);
    setLoading(
      ProcessoAndamentoService.save(id, andamento)
        .catch(reason => {
          setIsOpen(true);
          return Promise.reject(reason);
        })
        .then(({ data }) => {
          if (editingValue) {
            const index = andamentos.findIndex(a => a.id === editingValue.id);
            onEdit(data, index);
          } else {
            onAdd(data);
          }
        })
    );
  };

  const remove = (andamento, index) => {
    setLoading(
      ProcessoAndamentoService.deleteById(andamento.id).then(() => {
        onRemove(index);
      })
    );
  };

  const edit = andamento => {
    setEditingValue(andamento);
    setIsOpen(true);
  };

  return (
    <div id="andamentos">
      <SectionTitle hasButton={!isOpen}>
        Andamentos
        {!isOpen && canAdd && (
          <InlineButton
            icon="fa fa-plus"
            label="Adicionar"
            onClick={() => setIsOpen(true)}
          />
        )}
      </SectionTitle>
      {isOpen && (
        <ProcessoAndamentosForm
          onAdd={add}
          onClose={() => {
            setIsOpen(false);
            setEditingValue(undefined);
          }}
          value={editingValue}
          processo={processo}
        />
      )}
      <ProcessoAndamentosList
        loading={loading}
        andamentos={andamentos}
        processosArquivos={processosArquivos}
        onRemove={remove}
        onEdit={edit}
        canEdit={canEdit}
        canRemove={canRemove}
      />
    </div>
  );
};
