import { FormattedDate, Table } from '@elotech/components';
import { CustomLink, TableContainer } from 'common/components';
import { useLoading } from 'common/hooks';
import { LABEL_ASSUNTO, LABEL_PROCESSO } from 'labels';
import { PARAM_PROCESSO_MP } from 'pages/parametros/Parametros';
import { useEffect, useState } from 'react';
import { ParametroService, ProcessoReferenciadoService } from 'service';

const ProcessosReferenciadosTableMP = ({ processoId }) => {
  const [loading, setLoading] = useLoading();
  const [referenciados, setReferenciados] = useState([]);
  const [paramProcessoMp, setParamProcessoMp] = useState(false);

  useEffect(() => {
    setLoading(
      ProcessoReferenciadoService.getReferenciadosAndPrincipalById(processoId)
        .then(({ data }) => {
          setReferenciados(data);
        })
        .then(
          ParametroService.findParametro(PARAM_PROCESSO_MP).then(({ data }) =>
            setParamProcessoMp(data?.valor === 'S')
          )
        )
    );
  }, []);

  return (
    <TableContainer>
      <Table loading={loading} values={referenciados} keyExtractor={r => r.id}>
        <Table.Column
          header="Tipo"
          value={r => `${r.tipo?.id} - ${r.tipo?.descricao}`}
        />
        <Table.Column
          header={LABEL_PROCESSO}
          value={r => (
            <CustomLink
              identificador={`${r.numero}/${r.ano}`}
              uri={
                paramProcessoMp
                  ? `/processo-mp/view/${r.id}`
                  : `/processos-novo/view/${r.id}`
              }
            />
          )}
        />
        <Table.Column
          header="Data Instauração"
          value={r => <FormattedDate value={r.processoMp?.dataInstauracao} />}
        />
        <Table.Column
          header="Local Atual"
          value={r => `${r.localDestino?.id} - ${r.localDestino?.descricao}`}
        />
        <Table.Column
          header={LABEL_ASSUNTO}
          value={r => `${r.assunto?.id} - ${r.assunto?.descricao}`}
        />
        <Table.Column
          header="Situação"
          value={r => `${r.situacao?.descricao}`}
        />
        <Table.Column header="Status" value={r => `${r.funcao}`} />
      </Table>
    </TableContainer>
  );
};

export default ProcessosReferenciadosTableMP;
