import {
  Col,
  FormikAutocomplete,
  FormikTextArea,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { InlineButton, Label } from 'common/components';
import { Formik } from 'formik';
import { LABEL_PARECER, LABEL_PARECERES } from 'labels';
import { useEffect, useRef } from 'react';
import { FormGroup } from 'react-bootstrap';
import { LocalService, SituacaoService } from 'service';

import { TramiteArquivo } from './TramiteArquivo';
import { TramitesProcessosPareceres } from './TramitesProcessosPareceres';
import { getLocalByRoteiro } from './TramiteUtils';

const validationSchema = Yup.object().shape({
  local: Yup.object().required('Local Destino é obrigatório'),
  situacao: Yup.object().required('Situação é obrigatória')
});

export const TramiteReabrirForm = ({
  processo,
  situacao,
  onTramitar,
  hasProcessoPai,
  processos = [],
  attProcessos,
  paramProcessoMp,
  ocultaArquivo = false
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (processo) {
      formikRef.current?.setFieldValue(
        'local',
        getLocalByRoteiro(processo, 'reabrir')
      );
    }
  }, [processo]);

  return (
    <Formik
      ref={formikRef}
      enableReinitialize
      initialValues={{
        situacao,
        localDestino: undefined,
        parecer: '',
        arquivos: [],
        processos
      }}
      onSubmit={onTramitar}
      validationSchema={validationSchema}
    >
      {formProps => {
        return (
          <>
            <Row>
              <FormikAutocomplete
                size={6}
                name="situacao"
                label="Situação"
                onSearch={SituacaoService.findAllAutocomplete}
                getOptionLabel={s => `${s.id} - ${s.descricao}`}
              />
              <FormikAutocomplete
                size={6}
                name="local"
                label="Local Destino"
                disabled={processo?.assunto?.controlaTramitacao}
                getOptionLabel={l => `${l.id} - ${l.descricao}`}
                onSearch={LocalService.findAllLocaisAutocomplete}
              />
            </Row>
            {!ocultaArquivo && (
              <Row>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label>Arquivos</Label>
                    <TramiteArquivo formProps={formProps} />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <SectionTitle>{LABEL_PARECERES}</SectionTitle>
            <Row>
              <FormikTextArea size={12} name="parecer" label={LABEL_PARECER} />
            </Row>
            {processos.length > 0 && (
              <TramitesProcessosPareceres
                processos={processos}
                attProcessos={attProcessos}
                paramProcessoMp={paramProcessoMp}
                ocultaProcFis={ocultaProcFis}
              />
            )}
            <Row className="mt-xs">
              <Col md={12} className="right ml-xs">
                <InlineButton
                  label="Reabrir"
                  disabled={hasProcessoPai}
                  onClick={formProps.submitForm}
                />
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
};
