import { Children, ReactElement } from 'react';
import styled from 'styled-components';

import { Item, ItemProps } from './Item';
import { LeftComponent } from './LeftComponent';
import { RightComponent } from './RightComponent';

export type TimelineTimelineProps = {
  showMore?: boolean;
  children: ReactElement<ItemProps> | ReactElement<ItemProps>[];
  onClickShowMore?: () => void;
};

const StyledTimelineContainer = styled.div`
  #head {
    display: flex;
    justify-content: space-between;
  }

  #last-button {
    width: 591px;
  }

  #timeLineTag {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 120px;
    max-width: 50%;
  }

  #timeline-item {
    width: 600px;
  }
`;

export const Timeline = (props: TimelineTimelineProps) => {
  const { children, onClickShowMore, showMore } = props;

  return (
    <StyledTimelineContainer className="timeline-container">
      <ul className="timeline type2">
        {Children.map(children, child => {
          const { onClickDetails, children, leftComponent, rightComponent } =
            child.props;

          return (
            <li key={child.key} id="timeline-item">
              <div className="datetime">{leftComponent}</div>
              <div className="info">
                <div id="head" className="head">
                  {rightComponent}
                </div>
                <div className="description">{children}</div>
                {onClickDetails && (
                  <div className="link" onClick={onClickDetails}>
                    <i className="fa fa-chevron-right hint clean module-color right">
                      <div className="hint-content">Detalhes</div>
                    </i>
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      <div
        id="last-button"
        onClick={onClickShowMore}
        className="button-loader-top"
      >
        <div
          className={`hint fa ${
            showMore ? 'fa-plus-circle' : 'fa-times-circle'
          } clean module-color`}
        >
          {showMore && <div className="hint-content">Carregar mais dados</div>}
        </div>
      </div>
    </StyledTimelineContainer>
  );
};

Timeline.Item = Item;
Timeline.LeftComponent = LeftComponent;
Timeline.RightComponent = RightComponent;
