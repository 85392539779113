import { axios } from 'libs';

const resource = '/datasignoradas';
const defaultSort = { sort: 'data,asc' };

export const findAll = (search = '', pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = dataginorar => {
  return dataginorar.id
    ? axios.put(`${resource}/${dataginorar.id}`, dataginorar)
    : axios.post(resource, dataginorar);
};

export const savePeriodo = dataginorar => {
  return axios.post(`${resource}/save-periodo/`, dataginorar);
};

export const retornaSugestaoDataTipoAndamento = (id, data) => {
  return axios.get(`${resource}/tipo-andamento/${id}/data/${data}`);
};
