export enum TipoPessoa {
  FISICA = 'F',
  JURIDICA = 'J'
}

export const tipoPessoaFormatada = {
  F: 'Física',
  J: 'Jurídica'
};

export const labelCnpjCpfByTipoPessoa = {
  F: 'CPF',
  J: 'CNPJ'
};
