import { HTMLAttributeAnchorTarget } from 'react';

type Props = {
  uri: string;
  identificador: string | number;
  target?: HTMLAttributeAnchorTarget;
  forceRight?: boolean;
};

const CustomLinkExternal = ({
  identificador,
  uri,
  target = '_blank',
  forceRight = false
}: Props) => {
  return (
    <a
      target={target}
      rel="noopener noreferrer"
      href={uri}
      style={forceRight ? { marginLeft: 'auto' } : {}}
    >
      {identificador}&nbsp;&nbsp;
      <i className="fas fa-external-link-alt blue-40" />
    </a>
  );
};

export { CustomLinkExternal };
