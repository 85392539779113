import { axios } from 'libs';

const resource = '/usuarios-ausencias';

export const findAusencias = (usuario, data) => {
  const url = `${resource}/ausencias/${usuario}`;

  return axios.get(url, {
    params: {
      data
    }
  });
};

export const findById = id => {
  const url = `${resource}/${id}`;

  return axios.get(url);
};

export const findAll = (search, pagination = {}, sort = {}) => {
  return axios.get(resource, {
    params: { search, ...sort, ...pagination }
  });
};

export const findAllWithUsername =
  username =>
  (search, pagination = {}, sort = { dataInicio: 'asc' }) => {
    const usernameFilter = `usuario.id==${username}`;
    const searchWithUsername = search
      ? `${search} and ${usernameFilter}`
      : usernameFilter;

    return findAll(searchWithUsername, pagination, sort);
  };

export const save = usuarioAusencia => {
  if (usuarioAusencia?.id) {
    return axios.put(`${resource}/${usuarioAusencia?.id}`, usuarioAusencia);
  }
  return axios.post(resource, usuarioAusencia);
};

export const deleteById = usuarioAusenciaId => {
  return axios.delete(`${resource}/${usuarioAusenciaId}`);
};
