import { useAuth } from '@elotech/components';
import { useSelector } from 'react-redux';
import { getConfigSelector, getUserPermissions } from 'state';

export const usePermissions = () => {
  const { userPermissions, hasRole } = useAuth();
  const { config, seletores } = useSelector(state => ({
    config: getConfigSelector(state),
    seletores: getUserPermissions(state)
  }));

  const enableNew = (role = '', seletor = '') => {
    if (config.isCloud) {
      return hasRole(role);
    }

    return seletores[seletor].inserir;
  };

  const enableView = (role = '', seletor = '') => {
    if (config.isCloud) {
      return hasRole(role);
    }

    return seletor in seletores;
  };

  const enableEdit = (role = '', seletor = '') => {
    if (config.isCloud) {
      return hasRole(role);
    }

    return seletores[seletor].alterar;
  };

  const enableDelete = (role = '', seletor = '') => {
    if (config.isCloud) {
      return hasRole(role);
    }

    return seletores[seletor].excluir;
  };

  const enableHistory = (role = '', seletor = '') => {
    if (config.isCloud) {
      return hasRole(role);
    }

    return seletores[seletor].inserir;
  };

  const enableAll = (role, seletor) => {
    return (
      enableNew(role, seletor) &&
      enableView(role, seletor) &&
      enableEdit(role, seletor) &&
      enableDelete(role, seletor) &&
      enableHistory(role, seletor)
    );
  };

  const hasSeletor = seletor => {
    const isPublic = !seletor && seletores;
    return isPublic || seletores?.hasOwnProperty(seletor);
  };

  const hasPermission = (role, seletor) => {
    return hasRole(role) || hasSeletor(seletor);
  };

  return {
    enableNew,
    enableView,
    enableEdit,
    enableDelete,
    enableHistory,
    enableAll,
    hasSeletor,
    hasRole,
    hasPermission,
    permissions: config.isCloud ? userPermissions.clientRoles : seletores
  };
};
