import { axios } from 'libs';

const resource = '/consultaprocesso';

export const getObrigaSenha = entidadeId => {
  return axios.get(
    entidadeId
      ? `${resource}/obriga-senha/${entidadeId}`
      : `${resource}/obriga-senha`
  );
};

export function getProcesso(tipo, numero, ano, cpfSenha, idEntidade) {
  return axios.get(
    idEntidade
      ? `${resource}?tipo=${tipo}&numero=${numero}&ano=${ano}&cpfSenha=${cpfSenha}&entidade=${idEntidade}`
      : `${resource}?tipo=${tipo}&numero=${numero}&ano=${ano}&cpfSenha=${cpfSenha}`
  );
}

export function getProcessoByCpfAndChave(cpf, chave) {
  return axios.get(`${resource}/chave`, {
    params: {
      cpf,
      chave
    }
  });
}
