import { Col, DisplayData, Row } from '@elotech/components';

export const ProcessoSolicitacaoDados = ({ solicitacao }) => {
  const renderValue = ([k, v]) => {
    const value = v === null || v === undefined ? '' : v;
    switch (typeof value) {
      case 'object':
        return (
          <>
            <b className="dd-title">{k}</b>
            <div className="col-md-12 col-sm-12">
              <ProcessoSolicitacaoDados solicitacao={v} />
            </div>
          </>
        );
      case 'boolean':
        return (
          <>
            <b className="dd-title">{k}</b>
            {v ? (
              <i className="fa fa-check green-80" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-times red-80" aria-hidden="true"></i>
            )}
          </>
        );
      default:
        return <DisplayData title={k}> {v} </DisplayData>;
    }
  };
  return (
    <div className="display-data border small">
      {Object.entries(solicitacao).map(([k, v]) => (
        <Row key={k}>
          <Col sm={12} md={12}>
            {renderValue([k, v])}
          </Col>
        </Row>
      ))}
    </div>
  );
};
