export const getUserPermissions = state => {
  if (state.config.isCloud) {
    return [];
  }

  return state.auth.userAccess;
};

export const getUserInfoSelector = state => {
  return state.config.isCloud
    ? state.user.currentUser
    : { ...state.auth.userInfo, username: state.auth.userInfo?.usuario };
};

export const getUsernameSelector = state =>
  state.config.isCloud
    ? state.user.currentUser?.username
    : state.auth.userInfo?.usuario;

export const getIsLoggedSelector = state =>
  state.config.isCloud ? !!state.user.currentUser?.username : state.auth.logged;

export const getExercicioPadraoUserInfoSelector = state =>
  state.auth?.userInfo?.exercicio?.id || new Date().getFullYear();
