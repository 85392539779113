import { axios } from 'libs';

const resource = '/processos/anexos';

export const hasProcessoPai = id => {
  return axios.get(`${resource}/buscaProcessoPai${id}`);
};

export const searchProcessosAnexos = id => {
  return axios.get(`${resource}${id}`);
};

export const deleteProcessoAnexo = (processo, processoAnexo) => {
  return axios.delete(resource, {
    data: {
      processo,
      processoAnexo
    }
  });
};

export const saveProcessoAnexo = (processo, processoAnexo) => {
  return axios.post(resource, { processo, processoAnexo });
};
