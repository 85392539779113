import {
  BasicInput,
  FormikAutocomplete,
  FormikInputDate,
  Row
} from '@elotech/components';
import React from 'react';
import { ProcessoService } from 'service';

const PADISection: React.FC<unknown> = () => {
  return (
    <>
      <Row>
        <BasicInput name="processoMp.requerido" label="Requerido" size={6} />
        <FormikAutocomplete
          name="procedimentoOrigem"
          label="Procedimento Origem"
          size={6}
          onSearch={ProcessoService.findAllAutocomplete}
          getOptionLabel={(p: any) =>
            `Tipo:${p.tipo?.descricao} - Número:${p.numero} - Ano:${p.ano}`
          }
        />
      </Row>
      <Row>
        <BasicInput
          name="processoMp.resolucaoInstauradora"
          label="Resolução Instauradora"
          size={6}
        />
        <FormikInputDate
          name="processoMp.prazoPrescricional"
          label="Prazo Prescricional"
          size={6}
        />
      </Row>
      <Row>
        <BasicInput
          name="processoMp.dispositivoLegal"
          label="Dispositivo Legal Infringido"
          size={12}
          maxLength={500}
        />
      </Row>
    </>
  );
};

export default PADISection;
