import React, { ChangeEvent, FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  placeholder: string;
  onChange?(event: ChangeEvent): void;
  showFilter?: boolean;
  showTopDivider?: boolean;
};

export const FloatSidebarList: FC<Props> = ({
  children,
  onChange,
  placeholder,
  showFilter = true,
  showTopDivider = false
}) => {
  return (
    <div className="container">
      {showTopDivider && <div className="divider" />}

      {showFilter && onChange && (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <input
                type="text"
                onChange={onChange}
                className="has-input-icon"
                placeholder={placeholder}
                aria-label="Campo de pesquisa"
              />
              <div className="divider" />
            </div>
          </div>
        </div>
      )}

      <div className="select-list form-group pt-none">{children}</div>
      <div className="divider" />
    </div>
  );
};
