import {
  Col,
  FormikAutocomplete,
  Row,
  TableChild,
  useShowNotification
} from '@elotech/components';
import { InlineButton } from 'common/components';
import { useLoading } from 'common/hooks';
import { Form, Formik } from 'formik';
import { LABEL_PROCESSO } from 'labels';
import { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { ProcessoAnexoService, ProcessoService } from 'service';

import { ProcessoAnexo } from '../processos-novo/processo/ProcessoAnexo';

const toProcessoAnexo = a => ({
  ...a,
  tipoAnexo: a.tipo,
  numeroAnexo: a.numero,
  anoAnexo: a.ano
});

const AnexosChildTable = ({ processo, useTableChild = true }) => {
  const [anexos, setAnexos] = useState([]);
  const [loading, setLoading] = useLoading();
  const showNotification = useShowNotification();

  useEffect(() => {
    if (processo) {
      setLoading(
        ProcessoAnexoService.searchProcessosAnexos(processo.id).then(
          ({ data }) => setAnexos(data.map(toProcessoAnexo))
        )
      );
    }
  }, []);

  const renderForm = () => (
    <>
      <Formik
        initialValues={{ processo: undefined }}
        onSubmit={(values, actions) =>
          setLoading(
            ProcessoAnexoService.saveProcessoAnexo(processo, values.processo)
              .then(({ data }) => {
                setAnexos(prev => [
                  ...prev,
                  toProcessoAnexo(data.processoAnexo)
                ]);
                actions.resetForm();
              })
              .catch(err => {
                if (err.response?.data?.violations?.length) {
                  for (const v of err.response.data.violations) {
                    showNotification({
                      level: 'error',
                      message: v.message
                    });
                  }
                }
              })
          )
        }
      >
        {formProps => (
          <Form>
            <Row>
              <FormikAutocomplete
                label={LABEL_PROCESSO}
                size={4}
                onSearch={ProcessoService.findAllProcessoArquivadoAutocomplete}
                name="processo"
                getOptionLabel={p =>
                  `${p.numero}/${p.ano} - ${p.tipo?.id} - ${p.tipo?.descricao}`
                }
              />
              <Col md={2} className="mt-xs">
                <FormGroup>
                  <InlineButton
                    label="Anexar"
                    disabled={!formProps.values.processo}
                    onClick={formProps.submitForm}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <ProcessoAnexo
        anexos={anexos}
        loading={loading}
        onRemove={(anexo, index) =>
          setLoading(
            ProcessoAnexoService.deleteProcessoAnexo(anexo, anexo).then(() =>
              setAnexos(prev => [
                ...prev.slice(0, index),
                ...prev.slice(index + 1)
              ])
            )
          )
        }
      />
    </>
  );

  return (
    <>
      {useTableChild ? <TableChild>{renderForm()}</TableChild> : renderForm()}
    </>
  );
};

export default AnexosChildTable;
