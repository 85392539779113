import { axios } from 'libs';

const resource = '/taxas-assunto';

export const findTaxasByProcessoTaxa = processoTaxaId => {
  return axios.get(`${resource}/processo-taxa/${processoTaxaId}`);
};

export const findTaxasByAssunto = assuntoId => {
  return axios.get(`${resource}/assunto/${assuntoId}`);
};
