import React, { ReactNode } from 'react';

type Props = {
  icon: string;
  hint?: boolean;
  bubble?: string;
  bubbleClass?: string;
  buttonSlide?: string;
  hintMessage?: ReactNode;
  onButtonClick: () => void;
};

export const FloatSidebarButton = ({
  hint = false,
  hintMessage = '',
  icon,
  onButtonClick,
  bubble,
  bubbleClass,
  buttonSlide
}: Props) => {
  return (
    <div
      data-testid="float-sidebar-button"
      onClick={onButtonClick}
      style={{ marginTop: buttonSlide }}
      className={`float-button ${icon} ${
        hint ? 'hint' : ''
      } center-right module-color`}
    >
      {bubble && (
        <div
          className={bubbleClass || 'btn negative circle'}
          style={{
            position: 'absolute',
            right: 0,
            marginRight: '60px',
            marginBottom: '30px',
            zIndex: 10,
            fontSize: '10px'
          }}
        >
          {bubble}
        </div>
      )}
      <div className="hint-content">{hintMessage}</div>
    </div>
  );
};
