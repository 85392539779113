import { BasicInput, Col, FormikSelect, Row } from '@elotech/components';
import { InlineButton } from 'common/components';
import { Form, Formik } from 'formik';
import { FormGroup } from 'react-bootstrap';
import * as Yup from 'yup';

const validationSchemaParametro = (parametros, editingIndex) =>
  Yup.object().shape({
    nome: Yup.string().required('Nome é obrigatório').max(200),
    identificador: Yup.string()
      .required('Identificador é obrigatório')
      .max(200, 'Ultrapassou o máximo de 200 caracteres')
      .test(
        'identificador',
        'Parâmetro com esse identificador já cadastrado para essa Variável/Sql',
        function (identificador) {
          let parametrosValidation = parametros;

          if (editingIndex >= 0) {
            parametrosValidation = [
              ...parametrosValidation.slice(0, editingIndex),
              ...parametrosValidation.slice(editingIndex + 1)
            ];
          }

          const identificadorRepetido = parametrosValidation.some(
            p => p.identificador === identificador
          );

          return identificador && !identificadorRepetido;
        }
      ),
    tipo: Yup.string().required('Tipo é obrigatório')
  });

const ParametroForm = ({
  parametros,
  parametro,
  onVoltar,
  onAdicionarParametro,
  editingIndex = undefined,
  showVoltar = false
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={parametro}
      validationSchema={() =>
        validationSchemaParametro(parametros, editingIndex)
      }
      onSubmit={(values, actions) => {
        onAdicionarParametro(values);
        actions.resetForm();
      }}
    >
      {formProps => {
        return (
          <Form>
            <Row>
              <BasicInput
                id="nome_parametro"
                label="Nome"
                name="nome"
                size={3}
              />
              <BasicInput
                size={3}
                name="identificador"
                label="Identificador"
                id="identificador_parametro"
              />
              <FormikSelect
                name="tipo"
                label="Tipo parâmetro"
                getOptionLabel={value => value}
                getOptionValue={value => value}
                options={['BOOLEAN', 'DATE', 'NUMBER', 'STRING']}
                size={3}
              />
            </Row>
            <Row>
              <Col sm={5} className="mt-xs">
                <FormGroup>
                  <InlineButton
                    type="submit"
                    label="Salvar"
                    className="mb-xs"
                    onClick={formProps.submitForm}
                  />
                  {showVoltar && (
                    <InlineButton
                      type="button"
                      label="Voltar"
                      color="neutral"
                      className="mb-xs"
                      onClick={onVoltar}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export { ParametroForm };
