import { axios } from 'libs';

const resource = '/processos/relatorios';

const options = { responseType: 'blob' };

const handleFile = response => {
  return Promise.resolve(response.data)
    .then(file => URL.createObjectURL(file))
    .then(fileURL => window.open(fileURL, '_blank'));
};

const fetchFile = resource => axios.get(resource, options).then(handleFile);

export const createPapeletaTramite = (params, tipo = 'LOCAL') => {
  return axios
    .post(`${resource}/papeleta/${tipo}`, params, options)
    .then(handleFile);
};

export const createVisualizacaoProcesso = id => {
  return fetchFile(`${resource}/visualizacao${id}`);
};

export const createCapaProcesso = id => {
  return fetchFile(`${resource}/capa${id}`);
};

export const createComprovanteProcesso = id => {
  return fetchFile(`${resource}/comprovante${id}`, options);
};

export const createRequerimentoProcesso = (processo, rptName) => {
  return fetchFile(`${resource}/requerimento${processo.id}&modelo=${rptName}`);
};

export const createEtiquetaProcesso = (processo, rptName) => {
  const { ano, tipo, numero, entidade } = processo;

  const params = {
    ano,
    tipo: tipo.id,
    numeroini: numero,
    numerofim: numero,
    modelo: rptName,
    entidade: entidade.id
  };

  return axios
    .post('/relatorios/processos/etiqueta', params, options)
    .then(handleFile);
};

export const imprimirEstatisticas = params => {
  return axios
    .post(`${resource}/estatisticas/impressao/pdf`, params, {
      responseType: 'blob'
    })
    .then(handleFile);
};
