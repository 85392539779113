import { StorageService, axios } from 'libs';

const resource = '/usuarios';

export const getUserData = () => {
  return axios.get(`${resource}/me`);
};

export const getUserPermissions = () => {
  const entidadeKey = StorageService.getItem('entidade');
  if (entidadeKey) {
    return axios.get(`${resource}/permissoes`).catch(e => {
      StorageService.removeItem('entidade');
    });
  } else {
    return Promise.resolve({ data: { clientRoles: [] } });
  }
};
