import { KeycloakService } from '@elotech/components';
import { ProtocoloAuthService } from 'service';

import { mockedState } from './StoreUtils';

export const isAuthenticated = () => {
  return (
    KeycloakService.getInstance().authenticated ||
    ProtocoloAuthService.isLogged()
  );
};

export const getToken = () => {
  if (process.env.NODE_ENV !== 'test') {
    if (KeycloakService.getToken()) {
      KeycloakService.refreshToken({});
      return KeycloakService.getToken();
    }

    return ProtocoloAuthService.getAuthorizationToken();
  }

  return mockedState.user.currentUser.username;
};
