import { getVariaveisContrato } from '../../utils/EmailAutomaticoUtils';
import { TinyMCEAction, TinyMCEConfig } from './TinyMCE';

const plugins: string[] = [
  'advlist anchor autolink',
  'charmap code',
  'fullscreen',
  'image insertdatetime',
  'link lists',
  'media',
  'pagebreak paste preview print',
  'searchreplace',
  'table',
  'visualblocks visualchars',
  'wordcount',
  'spellchecker'
];

const toolbar: string = [
  'undo redo', // desfazer/refazer
  'formatselect', // blocos de texto
  'bold italic underline strikethrough link forecolor backcolor removeformat', // formatação/coloração do texto
  'alignleft aligncenter alignright alignjustify outdent indent pagebreak', // alinhamento do texto
  'bullist numlist', // listas
  'image media file table', // mídias
  'fullscreen preview exportpdf', // ações
  'inserirVariavel', // variáveis de contrato
  'spellchecker' // Corretor ortográfico
].join(' | ');

export const getTinyMceConfig = (actions: TinyMCEAction[]): TinyMCEConfig => {
  return {
    branding: false,
    content_style:
      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    height: 300,
    language: 'pt_BR',
    language_url: `${process.env.PUBLIC_URL}/langs/pt_BR.js`,
    placeholder: 'Escreva algo...',
    plugins,
    preview_styles: 'color: red',
    setup: function (editor) {
      // lógica de botões customizados
      if (actions.length > 0) {
        const exportPdfAction = actions.find(a => a.name === 'exportpdf');
        const inserirVariavel = actions.find(a => a.name === 'inserirVariavel');

        if (exportPdfAction) {
          editor.ui.registry.addButton('exportpdf', {
            icon: 'print',
            tooltip: 'Exportar PDF',
            onAction: () => exportPdfAction?.execute?.(editor.getContent())
          });
        }

        if (inserirVariavel) {
          editor.ui.registry.addMenuButton('inserirVariavel', {
            text: 'Inserir Variável',
            fetch: function (callback) {
              const items = [
                {
                  type: 'nestedmenuitem',
                  text: 'Variáveis de Contrato',
                  getSubmenuItems: function () {
                    return getVariaveisContrato(editor);
                  }
                }
              ];
              callback(items);
            }
          });
        }
      }

      editor.on('drop', e => {
        if (e.dataTransfer) {
          const html = e.dataTransfer.getData('text/html');
          const content = html.replace(/<meta.*?>/, '');
          editor.selection.placeCaretAt(e.clientX, e.clientY);
          editor.insertContent(content);
          e.preventDefault();
        }
      });
    },
    skin: 'oxide',
    toolbar,
    forced_root_block: false,
    spellchecker_language: 'pt_BR',
    spellchecker_languages: 'Portuguese=pt_BR',
    browser_spellcheck: true
  };
};
