import { axios } from 'libs';

const resource = '/parametros';

export const findParametro = parametro => {
  return axios.get(`${resource}/${parametro}`);
};

export const findAllParametros = () => {
  return axios.get(`${resource}/lista`);
};

export const save = parametros => {
  return axios.post(`${resource}/update-lista`, parametros);
};

export const validarGrupo = id => {
  return axios.get(`${resource}/validar-grupo/${id}`);
};
