import { axios } from 'libs';

const resource = '/comprovantes-entregas';
const defaultSort = { sort: 'id,asc' };

export const findAll = (search = '', pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = comprovante => {
  return comprovante.id
    ? axios.put(`${resource}/${comprovante.id}`, comprovante)
    : axios.post(resource, comprovante);
};
