import axios from 'axios';
import { StorageService } from 'libs';
import { ProtocoloAuthService } from 'service';
import { getEntidadeByPathname, getToken } from 'utils';

import { getItem } from './StorageService';

const MODULO_PROTOCOLO = '4';
const EXERCICIO_DEFAULT = new Date().getFullYear();

const axiosInstance = axios.create({
  baseURL: '/protocolo-api/api',
  headers: {
    Accept: 'application/json, application/pdf;q=0.9, */*;q=0.8',
    'Content-Type': 'application/json',
    modulo: MODULO_PROTOCOLO
  }
});

axiosInstance.interceptors.request.use(async config => {
  const { ...newConfig } = config;

  const TOKEN = getToken();

  const ENTIDADE = Number(
    getEntidadeByPathname() || parseInt(getItem('entidade'))
  );

  if (TOKEN) {
    newConfig.headers['Authorization'] = `Bearer ${TOKEN}`;
  }
  if (ENTIDADE) {
    newConfig.headers['entidade'] = ENTIDADE;
  }
  newConfig.headers['exercicio'] = EXERCICIO_DEFAULT;
  newConfig.headers['modulo'] = MODULO_PROTOCOLO;
  newConfig.headers['x-tenant-host'] = window.location.host;

  return Promise.resolve(newConfig);
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      StorageService.removeItem('entidade');
      ProtocoloAuthService.clearAuthentication();
      window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  }
);
export default axiosInstance;
