import { Alert } from '@elotech/components';

import { AlertProps } from './types';
import { buildParams, noop } from './utils';

export const alert = ({
  onConfirm = noop,
  onCancel = noop,
  type,
  ...rest
}: AlertProps) => {
  return Alert[type]?.(buildParams(rest)).then(({ value }) => {
    if (value) {
      return onConfirm && onConfirm(value);
    } else {
      return onCancel && onCancel(value);
    }
  });
};
