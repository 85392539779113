import { useHistory } from 'react-router';

const ProcessosForbidden = ({
  title = 'Não Autorizado',
  message = 'Você não possui acesso para esta página'
}) => {
  const history = useHistory();

  return (
    <div className="not-found ">
      <span className="fa fa-ban"></span>
      <h2>{title}</h2>
      <small>{message}</small>
      <button
        onClick={() => history.goBack()}
        className="btn module-color icon-right center mt-xs"
      >
        Voltar
      </button>
    </div>
  );
};

export default ProcessosForbidden;
