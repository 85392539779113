import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/processos';

const defaultSort = {
  sort: 'id.ano,id.numero,desc'
};

export const findAllAutocomplete = (
  search = '',
  pagination,
  sort = defaultSort
) => {
  let filter = isNumber(search) ? `id.numero==${search},id.ano==${search}` : '';

  filter = `${!!filter ? `(${filter}) and ` : filter} fechado==false`;

  return axios.get(`${resource}/lista`, {
    params: {
      search: filter,
      ...sort,
      ...pagination
    }
  });
};

export const findByLote = lote => {
  return axios.get(`${resource}/lote/${lote}`).then(res => {
    const data = res.data.filter(p => !p.fechado);
    return {
      ...res,
      data
    };
  });
};
