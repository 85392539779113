import { Container, Loading } from '@elotech/components';
import React, { useEffect, useState } from 'react';

import { ModulosReactEnum } from '../../type/enum';
import { IFrame } from '../iframe';

type Props = {
  modulo: ModulosReactEnum;
};

const UnicoConsultaDinamicaComponent: React.FC<Props> = ({ modulo }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window?.document?.addEventListener(
      'iframe-onInit',
      e => {
        setLoading(false);
      },
      false
    );
  }, []);

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <IFrame
        path={`/unico/consulta-de-dados-dinamica?iframe=true&modulo=${modulo}`}
      />
    </Container>
  );
};

export { UnicoConsultaDinamicaComponent };
