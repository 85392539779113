import { useOnClickOutside } from '@elotech/components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classnames from 'classnames';
import { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';

import QuickViewHeader from './QuickViewHeader';
import QuickViewItem from './QuickViewItem';

type DivWidthAndTopProps = {
  width: number;
  height: number;
  top?: number;
  hasScroll?: boolean;
};

type QuickViewAction = {
  label: string;
  icon?: IconProp;
  hidden?: boolean;
  onClick: () => void;
};

type QuickViewProps = {
  top?: number;
  width?: number;
  height?: number;
  fixed?: boolean;
  onClose: () => void;
  actions?: QuickViewAction[];
  children: ReactNode;
  canClose?: boolean;
  hasScroll?: boolean;
};

const TOP_QUICKVIEW_FIXED = 160;
const WIDTH_QUICKVIEW = 450;
const HEIGHT_QUICKVIEW = 450;
const PRIMARY = 'hsl(117, 49%, 37%)';

const DivWidthAndTop = styled.div<DivWidthAndTopProps>`

  &.quick-view {
    width: ${props => props.width}px;

    &.open {
      box-shadow: none;
    }
  }

  &.quick-view-scroll {
    overflow: scroll;
    height: ${props => props.height}px;
  }

  &.quick-view-mobile {
    .quick-view-scroll;
  }

  &.quick-view.fixed {
    top: ${props => props.top}px;
  }

  .quick-header {
    p,
    label {
      color: #fff;
    }

    border-top-right-radius: ${props => (props.hasScroll ? '0px' : '10px')};
  }

  .actions {
    background-color: #fff;
    border-radius: 15px;
    color: ${PRIMARY};
    cursor: pointer;
    padding: 3px 7px;
    position: absolute;
    right: 7px;
    top: 7px;
    transition: 0.3s all;

    :hover {
      background-color: ${PRIMARY};
      color: #fff;
      cursor: pointer;
    }
  }

  .fa-ellipsis-v {
    z-index: 2;
  }

  ul {
    position: absolute;
    right: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
  }
`;

export const QuickView = ({
  children,
  onClose,
  fixed = false,
  top = TOP_QUICKVIEW_FIXED,
  width = WIDTH_QUICKVIEW,
  height = HEIGHT_QUICKVIEW,
  actions = [],
  canClose = true,
  hasScroll = false
}: QuickViewProps) => {
  const ref = useRef(null);
  const showMoreRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const mobileClassname =
    document.body.clientWidth < 768 ? 'quick-view-mobile' : '';
  const scrollClassname = hasScroll ? 'quick-view-scroll' : '';

  const classes = classnames(
    'quick-view open',
    scrollClassname,
    mobileClassname,
    {
      fixed
    }
  );

  useOnClickOutside(ref, () => {
    if (canClose) {
      onClose();
      setShowMore(false);
    }
  });

  useOnClickOutside(showMoreRef, () => {
    if (canClose) {
      setShowMore(false);
    }
  });

  const hideActions =
    (actions.length > 0 && actions.every(a => a.hidden)) ||
    actions.length === 0;

  return (
    <DivWidthAndTop
      ref={ref}
      top={top}
      width={width}
      height={height}
      className={classes}
      hasScroll={hasScroll}
      data-testid="quick-view"
    >
      {hideActions && (
        <i className="fa fa-times no-print" onClick={onClose}></i>
      )}
      <div ref={showMoreRef} className="btn-dropdown open">
        {!hideActions && (
          <i
            onClick={() => setShowMore(prev => !prev)}
            className="fa fa-ellipsis-v actions no-print btn-dropdown opener"
          ></i>
        )}
        {showMore && (
          <ul className="btn-dropdown-list">
            {actions.map(a =>
              !a.hidden ? (
                <li key={a.label} onClick={a.onClick}>
                  {a.icon && (
                    <i className={`with-icon fa fa-fw fa-${a.icon}`}></i>
                  )}
                  {a.label}
                </li>
              ) : null
            )}
          </ul>
        )}
      </div>
      <div className="preview">{children}</div>
    </DivWidthAndTop>
  );
};

QuickView.Header = QuickViewHeader;
QuickView.Item = QuickViewItem;
