import cookie from 'react-cookie';

const AUTHORIZATION = 'authorization';

export const getEnvironment = () => {
  if (window.location.origin.includes('.eloweb.')) {
    return 'local';
  }
  if (
    process.env.NODE_ENV === 'production' ||
    window.location.origin.includes('.oxy.')
  ) {
    return 'production';
  }

  if (window.location.origin.includes('.qa.')) {
    return 'qa';
  }

  if (window.location.origin.includes('.dev.')) {
    return 'dev';
  }

  return 'local';
};

export const getHost = () => {
  return window.location.origin;
};

export const getDomain = () => {
  return window.location.hostname;
};

export const saveAuthentication = token => {
  cookie.save(AUTHORIZATION, token, {
    path: '/',
    domain: getDomain()
  });

  return token;
};

export const getAuthorizationToken = () => {
  return cookie.load(AUTHORIZATION);
};

export const clearAuthentication = () => {
  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() - 1);

  cookie.save(AUTHORIZATION, null, {
    path: '/',
    domain: getDomain(),
    expires: expireDate
  });
};

export const isLogged = () => {
  return !!cookie.load(AUTHORIZATION);
};

export const redirectToLoginUnico = () => {
  const url = `${getHost()}/login/auth?redirect=${getHost()}/protocolo`;

  window.location.href = url;
};
