import { axios } from 'libs';

const resource = '/arquivos-assinaturas';

const defaultSort = {
  sort: 'id,desc'
};

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      fields:
        'id,usuarioSolicitante.id,usuarioSolicitante.nome,usuario.id,usuario.nome,dataAssinatura,numeroProcesso,tipoProcesso,tipoProcessoDescricao,anoProcesso,identificador,nome,dataCriacao,urlS3,motivoRecusa,dataRecusa,situacao,erro,idProcessamento',
      search,
      ...pagination,
      ...sort
    }
  });
};
export const assinar = assinaturaDto => {
  return axios.post(`${resource}/assinar`, assinaturaDto);
};

export const assinarLote = assinaturasDto => {
  return axios.post(`${resource}/assinar-lote`, assinaturasDto);
};

export const solicitarAssinatura = ({ identificador, usuarios }) => {
  return axios.post(`${resource}/${identificador}/solicitar`, usuarios);
};

export const solicitarAssinaturaDigital = identificador => {
  return axios.post(
    `${resource}/${identificador}/solicitar-assinatura-digital`
  );
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const removerPendentes = identificador => {
  return axios.delete(`${resource}/${identificador}/remover-pendentes`);
};

export const finalizarAssinaturaDigital = idsFinalizados => {
  const ids = idsFinalizados.split(',');
  const dtoIdFinalizados = { ids: ids };
  return axios.post(`${resource}/assinar-digitalmente`, dtoIdFinalizados);
};

const resolveCurrentEnvironment = () => {
  if (window.location.hostname.includes('.dev.')) {
    return 'dev';
  } else if (window.location.hostname.includes('.qa.')) {
    return 'qa';
  } else return 'oxy';
};

export const runSigner = (id, urlS3, tenant) => {
  const enviroment = resolveCurrentEnvironment();
  const urlCallBack = `${window.location.origin}${
    window.location.pathname
  }?idsFinalizados=${id}$${encodeURIComponent(window.location.search)}`;
  const url = `https://${tenant}.${enviroment}.elotech.com.br/app-signer/signature?keys=${urlS3}&redirect_uri=${encodeURIComponent(
    urlCallBack
  )}`;

  window.location.href = url;
};

export const rejeitarAssinatura = (identificador, motivo) => {
  return axios.post(`${resource}/${identificador}/rejeitar-assinatura`, motivo);
};

export const getQuantidadeAssinarLote = rsql => {
  return axios.get(`${resource}/quantidade-assinar-lote`, {
    params: {
      rsql
    }
  });
};

export const rejeitarAssinaturasEmLote = rejeitarAssinaturaLoteDTO => {
  return axios.post(
    `${resource}/rejeitar-assinatura-lote`,
    rejeitarAssinaturaLoteDTO
  );
};
