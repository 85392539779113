export enum TipoAndamentoEnum {
  EMAIL = 'EMAIL',
  PREVISAOCONCLUSAO = 'PREVISAOCONCLUSAO',
  ANEXOEXTERNO = 'ANEXOEXTERNO',
  OFICIO = 'OFICIO',
  SUSPENSAO = 'SUSPENSAO',
  ALTERACAOCATEGORIA = 'ALTERACAOCATEGORIA',
  COMUNICACAOINTERNA = 'COMUNICACAOINTERNA',
  DISPENSAPRORROGACAO = 'DISPENSAPRORROGACAO',
  DEFINICAOSIGILOSO = 'DEFINICAOSIGILOSO',
  BLOQUEIOANEXOEXTERNO = 'BLOQUEIOANEXOEXTERNO',
  ALTERASITUACAO = 'ALTERASITUACAO'
}

export const tipoAndamentoEnum = {
  EMAIL: 'Email',
  PREVISAOCONCLUSAO: 'Previsão Conclusão',
  ANEXOEXTERNO: 'Anexo Externo',
  OFICIO: 'Ofício',
  SUSPENSAO: 'Suspensão',
  ALTERACAOCATEGORIA: 'Alteração de Categoria',
  COMUNICACAOINTERNA: 'Comunicação Interna',
  DISPENSAPRORROGACAO: 'Dispensa Prorrogação',
  DEFINICAOSIGILOSO: 'Definição Sigiloso',
  BLOQUEIOANEXOEXTERNO: 'Libera/Bloqueia Anexos Consulta Externa',
  ALTERASITUACAO: 'Altera Situação'
};
