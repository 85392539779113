import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = 'escolaridades';

export const findAllAutocomplete = searchParam => {
  const search = isNumber(searchParam)
    ? ` id=="${searchParam}"`
    : ` descricao=="*${searchParam}*"`;

  return axios.get(resource, {
    params: {
      search
    }
  });
};
