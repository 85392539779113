import { axios } from 'libs';

const resource = '/processos-agrupados';

export const findProcesso = (tipo, numero, ano) => {
  return axios.get(resource, {
    params: {
      tipo,
      numero,
      ano
    }
  });
};

export const findProcessosByLote = lote => {
  return axios.get(`${resource}/lote/${lote}`);
};

export const tramitarProcessosEmLote = wrapper => {
  return axios.post(`${resource}/tramitar-lote`, wrapper);
};

export const receberProcessosEmLote = processo =>
  tramitarProcessosEmLote({ ...processo, funcao: 'Recebido' });

export const encaminharProcessosEmLote = processo =>
  tramitarProcessosEmLote({ ...processo, funcao: 'Encaminhado' });

export const arquivarProcessosEmLote = processo =>
  tramitarProcessosEmLote({ ...processo, funcao: 'Arquivado' });

export const reabrirProcessosEmLote = processo =>
  tramitarProcessosEmLote({ ...processo, funcao: 'Encaminhado' });
