import axios from 'libs/axios';

const resource = '/processos/agrupamentos-anuais';

export const findAgrupamentosAnuais = (
  tipoAgrupamento,
  exercicioUm,
  exercicioDois
) => {
  return axios.get(
    `${resource}/${tipoAgrupamento}/${exercicioUm}/${exercicioDois}`
  );
};
