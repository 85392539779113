import { Icon } from '@elotech/components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactNode } from 'react';

export type RightComponentProps = {
  tag: string;
  status?: string;
  title?: string;
  icon?: IconProp;
  description?: ReactNode;
};

const backgroundStatus = {
  positive: '',
  error: 'bg-red-60',
  warning: 'bg-orange-60',
  info: 'bg-gray-60',
  primary: 'bg-blue-60'
};

export const RightComponent = (props: RightComponentProps) => {
  const { tag, icon, status, title } = props;

  return (
    <>
      {title && <div className="title">{title}</div>}
      <div
        id="timeLineTag"
        className={`tag ${backgroundStatus[status!] || ''}`}
      >
        {tag || ''}
        {icon && <Icon icon={icon} color="white" className="ml-xs" />}
      </div>
    </>
  );
};
