import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

export type EllipsisProps = {
  value: string;
  maxLength?: number;
  initialShowMore?: boolean;
  teste?: boolean;
};

const StyledSmall = styled.small`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const DEFAULT_LENGTH = 150;

export const Ellipsis = ({
  value = '',
  maxLength = DEFAULT_LENGTH,
  initialShowMore = false
}: EllipsisProps) => {
  const [showMore, setShowMore] = useState(initialShowMore);

  const canExpand = useMemo(() => value.length > maxLength, [value, maxLength]);

  useEffect(() => {
    setShowMore(initialShowMore);
  }, [initialShowMore]);

  return (
    <span style={{ wordBreak: 'break-word' }}>
      {canExpand && !showMore ? `${value.slice(0, maxLength)}...` : value}{' '}
      {canExpand && (
        <StyledSmall onClick={() => setShowMore(prev => !prev)}>
          {showMore ? 'Ver Menos' : 'Ver Mais'}
        </StyledSmall>
      )}
    </span>
  );
};
