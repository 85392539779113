import { Col, DisplayData, FormattedDate, Row } from '@elotech/components';
import React from 'react';

type Props = { processo: any };

const PadiViewSection: React.FC<Props> = ({ processo }) => {
  return (
    <>
      <Row>
        <Col sm={6} md={6}>
          <DisplayData title="Procedimento Origem">
            {processo.processoReferencia}
          </DisplayData>
        </Col>
        <Col sm={6} md={6}>
          <DisplayData title="Requerido">
            {processo.processoMp.requerido}
          </DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={6}>
          <DisplayData title="Resolução Instauradora">
            {processo.processoMp.resolucaoInstauradora}
          </DisplayData>
        </Col>
        <Col sm={6} md={6}>
          <DisplayData title="Prazo Prescricional">
            <FormattedDate value={processo.processoMp.prazoPrescricional} />
          </DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <DisplayData title="Dispositivo Legal Infringido">
            {processo.processoMp.dispositivoLegal}
          </DisplayData>
        </Col>
      </Row>
    </>
  );
};

export default PadiViewSection;
