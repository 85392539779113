import { ErrorText } from '@elotech/components';
import { Col, FormGroup, Row } from 'react-bootstrap';

const TableDocumentosProcesso = ({
  assuntoDocumento = [],
  arquivosInseridos = [],
  adicionarArquivo,
  removerArquivo,
  disabled = false,
  possuiErroArquivos = false,
  processoExterno = false
}) => {
  const onChangeFile = documento => event => {
    const { files } = event.target;
    const [arquivo] = files;

    if (arquivo) {
      const arquivoNovo = Object.assign(arquivo, {
        documento,
        preview: URL.createObjectURL(
          new Blob([arquivo], { type: arquivo.type })
        )
      });

      const arquivoInserido = getArquivoByDocumento(documento);

      if (arquivoInserido) {
        removerArquivo(arquivoInserido);
      }

      adicionarArquivo(arquivoNovo);
    }
  };

  const getNomeArquivo = arquivo => {
    return arquivo?.name || arquivo?.nome;
  };

  const getArquivoByDocumento = documento => {
    return arquivosInseridos.find(a => a.documento?.id === documento.id);
  };

  const getObrigatorio = documento => {
    const assunto = assuntoDocumento.find(
      ad => ad.documento?.id === documento.id
    );
    return processoExterno
      ? assunto?.obrigatorioexterno
      : assunto?.obrigatoriointerno;
  };

  return (
    <div>
      {assuntoDocumento.map(({ documento }) => {
        const arquivo = getArquivoByDocumento(documento);
        const isObrigatorio = getObrigatorio(documento);

        return (
          <Row key={documento.id}>
            <Col md={12}>
              <FormGroup>
                <label htmlFor={documento.id}>{documento.descricao}</label>

                <div className="file-uploader">
                  <input
                    type="file"
                    id={documento.id}
                    disabled={disabled}
                    className={`file-uploader-input ${
                      possuiErroArquivos && !arquivo && isObrigatorio
                        ? 'error'
                        : ''
                    }`}
                    onChange={onChangeFile(documento)}
                    title="Clique ou arraste para anexar"
                  />

                  <label
                    className="input"
                    htmlFor={documento.id}
                    data-title={
                      arquivo
                        ? getNomeArquivo(arquivo)
                        : 'Clique ou arraste para anexar'
                    }
                  />

                  <div htmlFor={documento.id} className="file-uploader-icon" />

                  {possuiErroArquivos && !arquivo && isObrigatorio && (
                    <ErrorText>{documento.descricao} é obrigatório</ErrorText>
                  )}
                </div>
              </FormGroup>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default TableDocumentosProcesso;
