import { Col } from '@elotech/components';
import { FormGroup } from 'react-bootstrap';

const InputFile = ({ id, label, onChangeFile, size }) => {
  return (
    <>
      <Col md={size}>
        <FormGroup>
          <label htmlFor={id}>{label}</label>

          <div className="file-uploader">
            <input
              type="file"
              id={id}
              className="file-uploader-input"
              onChange={event => onChangeFile(event)}
              title="Clique ou arraste para anexar"
            />

            <label
              className="input"
              htmlFor={id}
              data-title={`${id ? id : 'Clique ou arraste para anexar'}`}
            />

            <div htmlFor={id} className="file-uploader-icon" />
          </div>
        </FormGroup>
      </Col>
    </>
  );
};

export default InputFile;
