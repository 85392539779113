import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/sqls-modelos';

const defaultSort = {
  sort: 'id,desc'
};

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findById = sqlId => {
  return axios.get(`${resource}/${sqlId}`);
};

export const saveOrUpdate = sql => {
  return sql.id
    ? axios.put(`${resource}/${sql.id}`, sql)
    : axios.post(resource, sql);
};

export const deleteSql = sqlId => {
  return axios.delete(`${resource}/${sqlId}`);
};

export const findAllAutocomplete = (
  search = '',
  pagination,
  sort = defaultSort
) => {
  const filter = isNumber(search) ? `id==${search}` : `nome==*${search}*`;

  return axios.get(resource, {
    params: {
      search: filter,
      ...sort,
      ...pagination
    }
  });
};

export const verificaIdentificadorRepetido = (id, identificador) => {
  return axios.get(`${resource}/verifica-identificador-repetido`, {
    params: {
      id,
      identificador
    }
  });
};
