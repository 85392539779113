import { axios } from 'libs';

const resource = '/parametrizacoes-integracao';

const defaultSort = {
  sort: 'id,asc'
};

export const findParametroIntegracao = parametro => {
  return axios.get(`${resource}/${parametro}`);
};

export const findAllParametros = (search, pagination, sort = defaultSort) => {
  return axios.get(`${resource}`, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const save = parametro => {
  return parametro.id
    ? axios.put(`${resource}/${parametro.id}`, parametro)
    : axios.post(`${resource}`, parametro);
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findParametroIntegracaoById = id => {
  return axios.get(`${resource}/${id}`);
};
