export const FileNameFormatter = ({ originalName, shouldTruncate = true }) => {
  let formattedName = originalName || 'Erro ao buscar o nome do arquivo';

  if (shouldTruncate && formattedName.length > 30) {
    const itemName = originalName.split('.');
    const extension = itemName[itemName.length - 1];
    const name = `${formattedName.substr(0, 30 - extension.length)}...`;
    formattedName = name + extension;
  }

  return formattedName;
};
