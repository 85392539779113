export enum AnaliseArquivoEnum {
  ACEITO = 'A',
  RECUSADO = 'R',
  NAO_ANALISADO = 'N'
}

export const analiseArquivoEnum = {
  A: 'Aceito',
  R: 'Recusado',
  N: 'Não Analisado'
};
