import {
  TelasCadastroUnicoAdmRoutesEnum,
  TelasCadastroUnicoEnum
} from 'common/type/enum';
import { useHistory } from 'react-router';

import { UnicoCadastrosComponent } from './UnicoCadastrosComponent';

const UnicoCadastros = () => {
  const history = useHistory();

  const getTelaUnicoByRoute = () => {
    switch (history.location.pathname) {
      case TelasCadastroUnicoAdmRoutesEnum.BAIRRO:
        return TelasCadastroUnicoEnum.BAIRRO;
      case TelasCadastroUnicoAdmRoutesEnum.CIDADE:
        return TelasCadastroUnicoEnum.CIDADE;
      case TelasCadastroUnicoAdmRoutesEnum.LEGISLACAO:
        return TelasCadastroUnicoEnum.LEGISLACAO;
      case TelasCadastroUnicoAdmRoutesEnum.LOGRADOURO:
        return TelasCadastroUnicoEnum.LOGRADOURO;
      case TelasCadastroUnicoAdmRoutesEnum.PESSOA:
        return TelasCadastroUnicoEnum.PESSOA;
      case TelasCadastroUnicoAdmRoutesEnum.RAMOS:
        return TelasCadastroUnicoEnum.RAMOS;
      default:
        return TelasCadastroUnicoEnum.AGENCIA;
    }
  };

  return (
    <>
      {history?.location?.pathname && (
        <UnicoCadastrosComponent telaUnico={getTelaUnicoByRoute()} />
      )}
    </>
  );
};

export { UnicoCadastros };
