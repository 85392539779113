import { axios } from 'libs';

const resource = '/processosExterno';

export const getTipoProcessoPadrao = () => {
  return axios.get(`${resource}/tipo-padrao`);
};

export const salvarArquivos = (processoId, files) => {
  const promises = files
    .map(file => {
      const formData = new FormData();

      formData.append('files', file);

      if (file.documento) {
        formData.append('documento', file.documento.id);
      }

      return formData;
    })
    .map(data => {
      let url = `${resource}/arquivo`;

      if (data.get('documento')) {
        url = `${url}/documento/${data.get('documento')}`;
      }

      return axios.post(`${url}/${processoId}`, data);
    });

  return Promise.all(promises);
};

export const save = (entidadeId, processo) => {
  return axios.post(
    entidadeId ? `${resource}/${entidadeId}` : resource,
    processo
  );
};

export const getConfig = entidade => {
  return axios.get(
    entidade ? `${resource}/${entidade}/config` : `${resource}/config`
  );
};
