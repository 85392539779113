export const getEntidadeByPathname = () => {
  const pathname = window.location.pathname.replace('/protocolo', '');
  const positions = pathname.split('/');
  const entidade = parseInt(positions[1]);

  return Number.isInteger(entidade) ? parseInt(entidade) : 0;
};

export const configureIntl = () => {
  if (!global.Intl) {
    global.Intl = require('intl');
  }

  if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/dist/locale-data/pt');
    require('@formatjs/intl-pluralrules/dist/locale-data/en');
  }

  if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/pt');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  }
};

export const onClassChange = (element, callback) => {
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class'
      ) {
        callback(mutation.target);
      }
    });
  });

  observer.observe(element, { attributes: true });

  return observer.disconnect;
};
