import { Modulo } from '../type';

export const getUrl = (modulo: Modulo, path: string) => {
  if (!modulo || !path) {
    throw new Error('Módulo e path são obrigatórios.');
  }

  const { hostname, origin } = window.location;
  const isLocalhost = hostname.includes('local');

  return `${origin}${isLocalhost ? '' : `/${modulo}`}${path}`;
};
