import { AuthTypes } from 'state/types';

const initialState = {
  logged: false,
  userInfo: undefined,
  userAccess: undefined
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthTypes.SET_USER_PROFILE: {
      const { telas, ...userInfo } = action.payload;
      const userAccess = telas.reduce((acc, cur) => {
        acc[cur.seletor.id] = cur;
        return acc;
      }, {});

      return {
        ...state,
        logged: true,
        userAccess,
        userInfo
      };
    }
    case AuthTypes.LOGOUT:
    default:
      return state;
  }
};
