import { Modal, Panel } from '@elotech/components';
import { Timeline } from 'common/components';

const statusByFuncao = {
  ABERTO: 'positive',
  ENCAMINHADO: 'warning',
  RECEBIDO: 'positive',
  ARQUIVADO: 'primary'
};

const iconByFuncao = {
  ABERTO: 'check-circle',
  ENCAMINHADO: 'paper-plane',
  RECEBIDO: 'thumbs-up',
  ARQUIVADO: 'file-archive'
};

const TramitesTimelineModal = ({ processo, onClose, tramitesUsuarios }) => {
  const tramites = processo.tramites.sort(
    (t1, t2) => t2.sequencia - t1.sequencia
  );

  return (
    <Modal onClose={onClose} maxWidth="60%">
      <Panel title={`Linha do Tempo: ${processo.numero}/${processo.ano}`}>
        <Timeline>
          {tramites.map(tramite => (
            <Timeline.Item
              key={tramite.sequencia}
              leftComponent={
                <Timeline.LeftComponent
                  date={tramite.data}
                  hour={tramite.hora}
                />
              }
              rightComponent={
                <Timeline.RightComponent
                  tag={tramite.funcao}
                  title={tramite.nomeUsuario}
                  icon={iconByFuncao[tramite.funcao]}
                  status={statusByFuncao[tramite.funcao]}
                />
              }
            >
              <>
                <p>
                  {tramite.local.descricao}, {tramite.quantidadeDiasLocal || 0}{' '}
                  {tramite.quantidadeDiasLocal === 1 ? 'dia' : 'dias'} no local.
                </p>
                {tramite.caixa && <p>Caixa {tramite.caixa.descricao}.</p>}
                {tramitesUsuarios?.[tramite.usuarioResponsavelTramite] && (
                  <p>
                    Usuário Responsável:{' '}
                    {`${tramitesUsuarios?.[tramite.usuarioResponsavelTramite]}`}
                  </p>
                )}
              </>
            </Timeline.Item>
          ))}
        </Timeline>
      </Panel>
    </Modal>
  );
};

export default TramitesTimelineModal;
