import { ApolloProvider } from '@apollo/client';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { axios } from 'libs';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getConfigSelector } from 'state';

import client from '../pages/dashboards/graphql/client';

const createCubejsApi = cubejsIp => {
  return cubejs(
    async () => {
      const res = await axios.get('/cubejs/auth');
      return res.data;
    },
    {
      apiUrl: `${cubejsIp}/cubejs-api/v1`
    }
  );
};

export const CubejsProvider = ({ children }) => {
  const { cubejsIp } = useSelector(getConfigSelector);

  const cubejsApi = useMemo(() => {
    return createCubejsApi(cubejsIp);
  }, [cubejsIp]);

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </CubeProvider>
  );
};
