import { FilterUtils } from '@elotech/components';
import axios from 'libs/axios';

const defaultSort = {
  sort: 'descricao,asc'
};

const resource = '/grupos-assinantes';

export const findAll = (searchParam, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search: searchParam,
      ...sort,
      ...pagination
    }
  });
};

export const findAllAutocomplete = (
  searchParam,
  pagination,
  sort = defaultSort
) => {
  const search = FilterUtils.buildFilter(['descricao'])(searchParam);

  return axios.get(resource, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = grupoAssinante => {
  return grupoAssinante.id
    ? axios.put(`${resource}/${grupoAssinante.id}`, grupoAssinante)
    : axios.post(resource, grupoAssinante);
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};
