import saveAs from 'file-saver';
import { axios } from 'libs';

const resource = '/arquivos/assuntos';

export const download = ({ nome, arquivoId }) => {
  return axios
    .get(`${resource}/${arquivoId}/download`, { responseType: 'blob' })
    .then(res => saveAs(new Blob([res.data], { type: res.data.type }), nome));
};

export const preview = ({ arquivoId }) => {
  return axios
    .get(`${resource}/${arquivoId}/download`, { responseType: 'blob' })
    .then(({ data }) => {
      const url = URL.createObjectURL(new Blob([data], { type: data.type }));
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 6e4);

      if (!window.open(url)) {
        throw new Error('Sem permissão para mostrar popups');
      }
    });
};

export const salvarArquivos = (assuntoId, files) => {
  const promises = files
    .map(file => {
      const formData = new FormData();

      formData.append('files', file);

      return formData;
    })
    .map(data => axios.post(`${resource}/${assuntoId}`, data));

  return Promise.all(promises);
};

export const removerArquivo = (idAssunto, idArquivo) => {
  return axios.delete(`${resource}/${idArquivo}/${idAssunto}`);
};
