import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

export const configureStore = reducer => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(applyMiddleware(thunk));

  return createStore(reducer, {}, enhancer);
};
