import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const defaultSort = {
  sort: 'id,desc'
};

const resource = '/roteiros';

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const findAllAutocomplete = (
  search = '',
  pagination,
  sort = defaultSort
) => {
  let filter = isNumber(search)
    ? `id==${search}`
    : FilterUtils.buildFilter(['descricao'])(search);

  filter += ' and ativo==true';

  return axios.get(resource, {
    params: {
      search: filter,
      ...sort,
      ...pagination
    }
  });
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = roteiro => {
  return roteiro.id
    ? axios.put(`${resource}/${roteiro.id}`, roteiro)
    : axios.post(resource, roteiro);
};
