import { EntidadeTypes } from 'state/types';

const initialState = {
  entidade: undefined
};

export const EntidadeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EntidadeTypes.SET_ENTIDADE:
      return {
        entidade: action.payload
      };
    case EntidadeTypes.GET_ENTIDADE_PRINCIPAL:
      return {
        entidade: action.payload
      };
    case EntidadeTypes.CLEAR_ENTIDADE:
    default:
      return state;
  }
};
