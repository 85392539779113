import { FC } from 'react';
import styled from 'styled-components';

type FloatSidebarListItemProps = {
  onClick?: () => void;
  active?: boolean;
  showTitle?: boolean;
  title?: string;
  draggable?: boolean;
  onDragStart?: (e) => void;
};

const DraggableDiv = styled.div`
  cursor: ${props =>
    props.onClick ? 'pointer' : props.draggable ? 'grab' : ''};
  :active {
    cursor: ${props => (props.draggable ? 'grabbing' : '')};
  }
  svg {
    cursor: pointer;
  }
`;

export const FloatSidebarListItem: FC<FloatSidebarListItemProps> = ({
  children,
  onClick,
  active,
  title,
  showTitle,
  draggable = false,
  onDragStart
}) => {
  return (
    <>
      <DraggableDiv
        draggable={draggable}
        onDragStart={onDragStart}
        className={`row ${active ? 'bg-cyan-5' : ''}`}
        onClick={onClick}
      >
        <div className="select-list-item col-md-12">
          <div className="row">{children}</div>
        </div>
      </DraggableDiv>
      {showTitle && (
        <div className="row">
          <div className="title-block">{title}</div>
        </div>
      )}
    </>
  );
};
