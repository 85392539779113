export enum TelasCadastroUnicoEnum {
  AGENCIA = '/unico/agencias',
  BAIRRO = '/unico/bairros',
  CIDADE = '/unico/cidades',
  LEGISLACAO = '/unico/leis-atos',
  LOGRADOURO = '/unico/logradouros',
  PESSOA = '/unico/pessoas',
  RAMOS = '/unico/ramos-atividade'
}

export enum TelasCadastroUnicoAdmRoutesEnum {
  AGENCIA = '/unico-agencia',
  BAIRRO = '/unico-bairro',
  CIDADE = '/unico-cidade',
  LEGISLACAO = '/unico-legislacao',
  LOGRADOURO = '/unico-logradouro',
  PESSOA = '/unico-pessoa',
  RAMOS = '/unico-ramos-atividades'
}
