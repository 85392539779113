import { Loading } from '@elotech/components';
import { useLoading } from 'common/hooks';
import { StorageService } from 'libs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UserInfoService } from 'service';
import { AuthActions, EntidadeActions } from 'state';
import { isAuthenticated } from 'utils';

export const ProtocoloAuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useLoading();

  useEffect(() => {
    if (isAuthenticated()) {
      setLoading(
        UserInfoService.reviveUserInfo().then(({ data }) => {
          dispatch(AuthActions.setUserProfile(data));

          const entidadeKey = StorageService.getItem('entidade');
          const entidade = data.entidade || data.entidadePadrao;

          if (entidade && entidade.id === entidadeKey) {
            dispatch(EntidadeActions.setEntidadeSelecionada(entidade));
          }
        })
      );
    }
  }, []);

  if (loading) {
    return <Loading loading />;
  }

  return <>{children}</>;
};
