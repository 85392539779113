import { FilterUtils } from '@elotech/components';
import { axios } from 'libs';

const defaultSort = {
  sort: 'id,desc'
};

const resource = '/modelos';

export const findAll = (searchParam = '', pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search: searchParam,
      ...sort,
      ...pagination
    }
  });
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = modeloDados => {
  return modeloDados.id
    ? axios.put(`${resource}/${modeloDados.id}`, modeloDados)
    : axios.post(`${resource}`, modeloDados);
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const preview = modeloParamsDto => {
  return axios.post(`${resource}/preview`, modeloParamsDto);
};

export const findAllByAssunto = (searchParam = '', assunto) => {
  const semAssuntos = 'assuntos.assunto==null';
  let search = 'codigo==null and ativo==true';

  if (assunto) {
    search += ` and (${semAssuntos} or assuntos.assunto.id==${assunto.id})`;
  } else {
    search += ` and ${semAssuntos}`;
  }

  if (searchParam) {
    search += ` and nome=="*${searchParam}*" `;
  }

  return axios.get(resource, {
    params: {
      search
    }
  });
};

export const clone = (id, nomeModelo) => {
  return axios.post(`${resource}/${id}/clone`, {
    nome: nomeModelo
  });
};

export const findAllAutocomplete = searchParam => {
  const search = `${FilterUtils.buildFilter(['nome'])(
    searchParam
  )} and email==true and ativo==true`;

  return axios.get(resource, {
    params: {
      search
    }
  });
};

export const findTemplate = id => {
  return axios.get(`${resource}/${id}/template`);
};
