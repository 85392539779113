import { CollapseRow, DiffTable, FormattedDateTime } from '@elotech/components';
import React, { FC } from 'react';

type EnversHistory = {
  revision: Revision;
  changes: Change[];
};

type Revision = {
  revisionDate: string;
  userName: string;
  revisionType: RevisionType;
};

type Change = {
  type: string;
  field: string;
  before: string;
  after: string;
};

enum RevisionType {
  ADD,
  MOD,
  DEL
}

type Props = {
  history: EnversHistory;
};

const getEnumDescription = {
  ADD: 'Adicionado',
  MOD: 'Modificado',
  DEL: 'Removido'
};

export const emptyEnversHistory: EnversHistory = {
  revision: {
    revisionDate: '',
    revisionType: RevisionType.ADD,
    userName: ''
  },
  changes: []
};

const renderRowHeader = (revision: any) => {
  return (
    <>
      <td data-header="Data">
        <b className="td-label">Data</b>
        <FormattedDateTime value={revision.revisionDate} />
      </td>
      <td data-header="Nome do Responsável">
        <b className="td-label">Nome do Responsável</b>
        {revision.userName}
      </td>
      <td>
        <b className="td-label">Tipo de Alteração</b>
        {getEnumDescription[revision.type]}
      </td>
    </>
  );
};

export const AuditFilterTableRow: FC<Props> = ({
  history = emptyEnversHistory
}) => {
  return (
    <CollapseRow
      className="reduced"
      buttonLabel="Visualizar Modificações"
      renderHeader={() => renderRowHeader(history.revision)}
    >
      <DiffTable>
        {history.changes.map((change, index) => (
          <tr key={index}>
            <td>{change.type}</td>
            <td>{change.field}</td>
            <td>
              {change.before === 'true'
                ? 'Sim'
                : change.before === 'false'
                ? 'Não'
                : change.before}
            </td>
            <td>
              {change.after === 'true'
                ? 'Sim'
                : change.after === 'false'
                ? 'Não'
                : change.after}
            </td>
          </tr>
        ))}
      </DiffTable>
    </CollapseRow>
  );
};
