import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import {
  TransProgressAddFileEvent,
  TransProgressUpdateFileEvent,
  TransProgressUpdateKind
} from './types';

export enum TransProgressKind {
  UPLOAD = 'upload',
  DOWNLOAD = 'download',
  PREVIEW = 'eye'
}

export interface ProgressAxiosRequestConfig extends AxiosRequestConfig {
  fileId: string;
  transient: boolean;
}

export const transientRequest = (
  configs: ProgressAxiosRequestConfig,
  shouldProgress: boolean = false,
  shouldFinish: boolean = false
): ProgressAxiosRequestConfig => {
  const { onDownloadProgress, onUploadProgress, ...anotherConfigs } = configs;
  const progress = shouldProgress
    ? { onDownloadProgress, onUploadProgress }
    : {};
  return {
    ...anotherConfigs,
    ...progress,
    transient: !shouldFinish
  };
};

export const useTransProgress = (
  name: string,
  kind: TransProgressKind,
  config?: AxiosRequestConfig
) => {
  const id = `arq-${name}-${kind}-${Math.random()}-${new Date()}`;
  const cancelToken = Axios.CancelToken.source();
  const downloading = kind !== TransProgressKind.UPLOAD;

  document.dispatchEvent(
    new TransProgressAddFileEvent({
      cancelToken,
      id,
      kind,
      name
    })
  );

  const handleProgress = ({ loaded, total }) => {
    document.dispatchEvent(
      new TransProgressUpdateFileEvent({
        id,
        updateKind: TransProgressUpdateKind.PROGRESS,
        loaded,
        total
      })
    );
  };

  return {
    fileId: id,
    cancelToken: cancelToken.token,
    [downloading ? 'onDownloadProgress' : 'onUploadProgress']: handleProgress,
    ...config,
    transient: false
  } as ProgressAxiosRequestConfig;
};

export const initializeAxios = (axiosInstance: AxiosInstance) => {
  const handleFinished = (response: any): any => {
    if (response?.config?.fileId && !response?.config?.transient) {
      document.dispatchEvent(
        new TransProgressUpdateFileEvent({
          id: response.config.fileId,
          updateKind: TransProgressUpdateKind.FINISHED
        })
      );
    }
    return response;
  };

  const handleRejected = (error: any) => {
    if (error?.config?.fileId) {
      document.dispatchEvent(
        new TransProgressUpdateFileEvent({
          id: error.config.fileId,
          updateKind: TransProgressUpdateKind.FAILED,
          reason: error
        })
      );
    }
    return Promise.reject(error);
  };

  return {
    axiosInstance,
    request: axiosInstance.interceptors.request.use(req => req, handleRejected),
    response: axiosInstance.interceptors.response.use(
      handleFinished,
      handleRejected
    )
  };
};
