import { BasicInput, ErrorText } from '@elotech/components';
import { Props as BasicInputProps } from '@elotech/components/dist/types/formik/BasicInput';
import classnames from 'classnames';
import { getIn } from 'formik';

type Props = Omit<BasicInputProps, 'render'> & {
  customProps: {
    value?: any;
    className?: string;
  };
};

export const BasicInputObject = (props: Props) => {
  if (!props.name?.includes('.')) {
    return null;
  }

  const innerRender = ({ field, form }) => {
    const hasError =
      (form.errors[field.name] && form.touched[field.name]) ||
      (getIn(form.errors, field.name) && getIn(form.touched, field.name));

    const classes = classnames(props.customProps?.className, {
      error: hasError
    });

    const handleChange = e => {
      if (props.onChange) {
        return props.onChange?.(e);
      }

      form.setFieldValue(field.name, e.target.value);
      form.setFieldTouched(field.name, true);
    };

    return (
      <>
        <input
          {...field}
          className={classes}
          onChange={handleChange}
          value={props.customProps?.value || field.value}
        />
        {hasError && <ErrorText>{form.errors[field.name]}</ErrorText>}
      </>
    );
  };

  return <BasicInput {...props} fast={false} render={innerRender} />;
};
