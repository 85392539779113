import { Col, DisplayData, Row } from '@elotech/components';
import React from 'react';

import { TiposDocumentoMp } from '../../../types';

type Props = { processo: any };

const DocumentacaoViewSection: React.FC<Props> = ({ processo }) => {
  return (
    <Row>
      <Col sm={4} md={4}>
        <DisplayData title="Tipo Documento">
          {TiposDocumentoMp[processo?.processoMp?.tipoDocumento]}
        </DisplayData>
      </Col>
      <Col sm={4} md={4}>
        <DisplayData title="Interessado">
          {processo?.processoMp?.interessado}
        </DisplayData>
      </Col>
    </Row>
  );
};

export default DocumentacaoViewSection;
