import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';
import { Editor as EditorType, RawEditorSettings } from 'tinymce';

import { getTinyMceConfig } from './utils';

type ActionName = 'exportpdf' | 'inserirVariavel';

export type TinyMCEConfig = RawEditorSettings & {
  selector?: undefined;
  target?: undefined;
};

export type TinyMCEAction = {
  name: ActionName;
  execute?: (currentTemplate?: string) => any;
};

export type TinyMCEProps = {
  value: string;
  actions?: TinyMCEAction[];
  disabled?: boolean;
  onChange?: (content: string) => void;
  editorId?: string;
};

export const TinyMCE = (props: TinyMCEProps) => {
  const {
    value,
    onChange,
    actions = [],
    disabled = false,
    editorId = 'modelo-dados-editor'
  } = props;

  const ref = useRef<EditorType | null>(null);

  const handleEditorChange = () => {
    if (ref.current) {
      onChange?.(ref.current.getContent());
    }
  };

  return (
    <Editor
      id={editorId}
      value={value}
      disabled={disabled}
      init={getTinyMceConfig(actions)}
      onEditorChange={handleEditorChange}
      onInit={(_, editor) => (ref.current = editor)}
    />
  );
};
