import { alert } from 'common/components';
import { bytesToText } from 'common/utils';
import React from 'react';
import Dropzone from 'react-dropzone';
import { ArquivoService } from 'service';

import DragDropItem from './DragDropItem';

const DEFAULT_MAX_SIZE = 20971520;

export default class DragDropFiles extends React.Component {
  onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const time = setTimeout(() => {
        this.props.addFile(file);
      }, 200);
      return () => clearTimeout(time);
    });
  };

  onDropRejected = tamanhoArquivo => () => {
    const tamanhoFormatado = bytesToText(tamanhoArquivo);
    const config = {
      title: 'Informação',
      text: `Não é permitido arquivo maior do que ${tamanhoFormatado}.`,
      type: 'info',
      confirmButtonText: 'Fechar'
    };

    alert(config);
  };

  renderAlertaExclusao = (arquivo, index) => {
    if (!this.props.showAlertExclusao) {
      return this.excluirArquivo(arquivo, index);
    }

    alert({
      type: 'warning',
      title: 'Exclusão',
      text: 'Deseja mesmo excluir o arquivo?',
      cancelButtonColor: '#c7382d',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      onConfirm: () => this.excluirArquivo(arquivo, index)
    });
  };

  renderAlertaRejeicao = (arquivo, index) => {
    alert({
      type: 'warning',
      title: 'Rejeitar',
      input: 'textarea',
      showCancelButton: true,
      cancelButtonColor: '#c7382d',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Rejeitar',
      onConfirm: motivo => this.rejeitarArquivo(arquivo, index, motivo),
      text: 'Deseja mesmo rejeitar o arquivo?',
      inputPlaceholder: 'Informe o motivo da rejeição',
      inputValidator: value => {
        return new Promise(resolve => {
          if (!value) {
            return resolve('Informe o motivo de estar recusando o arquivo.');
          }

          resolve(false);
        });
      }
    });
  };

  renderAlertaAprovacao = (arquivo, index) => {
    alert({
      type: 'warning',
      title: 'Aprovar',
      showCancelButton: true,
      cancelButtonColor: '#c7382d',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aprovar',
      onConfirm: () => this.aprovarArquivo(arquivo, index),
      text: 'Deseja mesmo aprovar o arquivo?'
    });
  };

  excluirArquivo = (data, index) => {
    this.props.removeFile(data, index);
  };

  rejeitarArquivo = async (arquivo, index, motivo) => {
    const { analisarArquivo, replaceFile } = this.props;

    const analise = {
      motivo,
      analise: 'R',
      identificador: arquivo.identificador
    };

    const res = await analisarArquivo(analise);
    replaceFile && replaceFile(res.value, index);
  };

  renameFile = async (arquivo, index) => {
    const { replaceFile } = this.props;

    alert({
      title: 'Renomear arquivo',
      input: 'text',
      inputValue: arquivo.nome,
      type: 'info',
      text: 'Renomear nomenclatura do arquivo',
      inputPlaceholder: 'Digite o novo nome do arquivo',
      showCancelButton: true,
      confirmButtonText: 'Renomear',
      cancelButtonText: 'Cancelar',
      onConfirm: async nome => {
        await ArquivoService.editarNomeArquivo({
          identificador: arquivo.identificador,
          nome
        }).then(novoArquivo => replaceFile && replaceFile(novoArquivo, index));
      },
      inputValidator: value => {
        return new Promise(resolve => {
          if (value) {
            resolve();
          } else {
            resolve('Digite o nome.');
          }
        });
      }
    });
  };

  aprovarArquivo = async (arquivo, index) => {
    const { analisarArquivo, replaceFile } = this.props;

    const analise = {
      analise: 'A',
      identificador: arquivo.identificador
    };

    const res = await analisarArquivo(analise);
    replaceFile && replaceFile(res.value, index);
  };

  renderItens = (item, index) => {
    const {
      downloadFile,
      readOnly,
      previewFile,
      canDelete,
      canDownload,
      canApprove,
      canReject,
      canRename,
      analisarArquivo
    } = this.props;

    return (
      <DragDropItem
        item={item}
        index={index}
        readOnly={readOnly}
        canDelete={canDelete}
        onPreview={previewFile}
        canDownload={canDownload}
        canReject={item.analise === 'N' && canReject && analisarArquivo}
        canApprove={item.analise === 'N' && canApprove && analisarArquivo}
        canRename={canRename && !readOnly}
        onRenameFile={this.renameFile}
        onDownload={downloadFile}
        onRemove={this.renderAlertaExclusao}
        onReject={this.renderAlertaRejeicao}
        onApprove={this.renderAlertaAprovacao}
        key={`${item.id}-${item.nome}-${index}`}
      />
    );
  };

  render() {
    const {
      files,
      readOnly,
      canAdd,
      maxSize = DEFAULT_MAX_SIZE,
      style
    } = this.props;

    return (
      <section>
        <div className="dragdrop" style={style}>
          {!readOnly && canAdd && (
            <Dropzone
              onDrop={this.onDrop}
              onDropRejected={this.onDropRejected(maxSize)}
              maxSize={maxSize}
              data-message={`Clique aqui ou arraste os arquivos para esta área. Tamanho máximo por arquivo: ${bytesToText(
                maxSize
              )}`}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps?.()}
                  className="dragdrop-space"
                  data-message={`Clique aqui ou arraste os arquivos para esta área. Tamanho máximo por arquivo: ${bytesToText(
                    maxSize
                  )}`}
                >
                  <input {...getInputProps?.()} />
                </div>
              )}
            </Dropzone>
          )}
          {!!files?.length && <ul>{files.map(this.renderItens)}</ul>}
        </div>
      </section>
    );
  }
}

DragDropFiles.defaultProps = {
  canDownload: true,
  canReject: true,
  canApprove: true,
  showAlertExclusao: true,
  canRename: false
};
