import {
  FormikCheckBox,
  FormikEditableInput,
  Table
} from '@elotech/components';
import { TableContainer } from 'common/components';
import { Field, FieldArray } from 'formik';
import { LABEL_PARECER, LABEL_PROCESSO } from 'labels';

export const TramitesProcessosPareceres = ({
  processos = [],
  paramProcessoMp,
  ocultaProcFis
}) => {
  return (
    <FieldArray
      name="processos"
      render={() => (
        <TableContainer bodyStyle={{ paddingBottom: 1 }}>
          <Table fixed values={processos} keyExtractor={p => p.id}>
            {!ocultaProcFis && (
              <Table.Column
                header="Físico"
                value={(processo, index) => (
                  <Field
                    size={1}
                    render={({ field }) => (
                      <FormikCheckBox
                        name={`processos.${index}.processoFisico`}
                        id={`processos.${index}.id`}
                      />
                    )}
                  />
                )}
              />
            )}
            <Table.Column
              header={LABEL_PROCESSO}
              value={p => `${p.numero}/${p.ano}`}
            />
            <Table.Column
              header="Tipo"
              value={p => `${p.tipo.id} - ${p.tipo.descricao}`}
            />
            <Table.Column
              header={LABEL_PARECER}
              value={(_, index) => (
                <FormikEditableInput
                  noLabel
                  size={12}
                  defaultOpened
                  name={`processos.${index}.parecer`}
                />
              )}
            />
          </Table>
        </TableContainer>
      )}
    />
  );
};
