import { useState } from 'react';

export const useExpandedIds = <T>() => {
  const [expandedIds, setExpandedIds] = useState<T[]>([]);

  const filter =
    (value: T | T[]): ((prevIds: T[]) => T[]) =>
    (prevIds: T[]): T[] => {
      if (Array.isArray(value)) {
        const all = prevIds.filter(i => !value.includes(i)).concat(value);

        if (containsAll(value)) {
          return all.filter(i => !value.includes(i));
        }

        return all;
      } else if (prevIds.indexOf(value) === -1) {
        return [...prevIds, value];
      } else {
        return prevIds.filter(i => i !== value);
      }
    };

  const containsAll = (value: T[]): boolean => {
    return expandedIds.length > 0 && value.every(i => expandedIds.includes(i));
  };

  const handleExpandedIds = (value: T | T[]): void => {
    setExpandedIds(filter(value));
  };

  return {
    expandedIds,
    handleExpandedIds
  };
};
