import { BasicInput, TextArea } from '@elotech/components';

export const FormikTextAreaCustom = props => {
  return (
    <BasicInput
      {...props}
      render={({ field }) => (
        <>
          <TextArea
            resize={props.resize}
            placeholder={props.placeholder}
            {...field}
            id={props.name}
            rows={props.rows}
            style={props.style}
          />
          {props.maxLength && (
            <div
              className="help-message gray-60 right col-md-12"
              data-testid="contador-textarea"
            >
              {`${field.value ? field.value.length : 0}/${props.maxLength}`}
            </div>
          )}
        </>
      )}
    />
  );
};
