import { ExtraInfo } from '@elotech/components';

export const getLegenda = (item, isProcessoMP) => {
  return (
    <div style={{ display: 'flex' }}>
      {item.analise === 'R' && (
        <ExtraInfo letter={'R'} value={'Arquivo recusado'} color={'negative'} />
      )}
      {!!item.sigiloExterno && (
        <ExtraInfo
          letter={'E'}
          value={'Arquivo não demonstrado na consulta externa'}
          color={'warning'}
        />
      )}
      {isProcessoMP && !!item.salvoPeloSistema && (
        <ExtraInfo
          letter={'T'}
          value={'Template salvo pelo Sistema'}
          color={'success'}
        />
      )}
      {item.cancelado && (
        <ExtraInfo letter="C" value="Arquivo Cancelado" color="negative" />
      )}
    </div>
  );
};
