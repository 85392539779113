import { axios } from 'libs';

const resource = '/modelos-dados-exporter';

const executarExportacao = executorDTO => {
  return axios
    .post(resource, executorDTO, { responseType: 'blob' })
    .then(({ data }) => URL.createObjectURL(data))
    .then(fileURL => {
      window.open(fileURL, '_blank');
      return Promise.resolve({
        data: [],
        status: 200,
        statusText: 'OK',
        headers: [],
        config: {}
      });
    });
};

export const exportarModeloPdf = modelo => {
  const exporterParam = {
    type: 'PDF',
    fileName: modelo.nome
  };

  return executarExportacao({
    name: modelo.nome,
    text: modelo.templateHtml,
    exporterParam
  });
};
