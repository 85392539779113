export const groupBy = function (array, field) {
  const groupedArr = [];
  array.forEach(function (e) {
    let group = groupedArr.find(g => g['field'] === e[field]);
    if (group === undefined) {
      group = { field: e[field], groupList: [] };
      groupedArr.push(group);
    }
    group.groupList.push(e);
  });

  return groupedArr;
};
