import { FormattedDateTime, useOnClickOutside } from '@elotech/components';
import { LABEL_PARECER } from 'labels';
import { useEffect, useRef, useState } from 'react';
import { TramiteService } from 'service';

const HistoricoParecer = ({
  processoId,
  onClose,
  sequencia,
  paramProcessoMp
}) => {
  const dialogRef = useRef(null);
  const [pareceres, setPareceres] = useState([]);

  useOnClickOutside(dialogRef, () => {
    onClose();
  });

  useEffect(() => {
    TramiteService.listaAlteracaoPareceres(processoId, sequencia).then(
      ({ data }) => {
        setPareceres(data);
      }
    );
  }, [processoId, sequencia]);

  return (
    <div className="dialoguebox alt sucess open">
      <div className="alt-box" ref={dialogRef}>
        <div className="alt-box-content alt-box-scroll">
          {pareceres.map((parecer, index) => {
            return (
              <div key={index}>
                <div className="row mt-xs">
                  <div className="col-md-12">
                    <p> Usuário: {parecer.usuarioReg} </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      Data/Hora: <FormattedDateTime value={parecer.dataReg} />
                    </p>
                  </div>
                </div>
                {index > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        {LABEL_PARECER}: {parecer.parecer}
                      </p>
                    </div>
                  </div>
                )}

                <hr className="mt-xs" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HistoricoParecer;
