import { Icon } from '@elotech/components';
import { Col } from 'react-bootstrap';

import { bytesToText } from '../../utils';
import {
  FloatSidebar,
  FloatSidebarList,
  FloatSidebarListItem
} from '../float-sidebar';
import { InlineButton } from '../inline-button';
import { TransProgressKind } from './TransProgressExternal';
import { TransProgressFile, TransProgressStatus } from './types';

function TransProgress({ files, cancel, remove }) {
  if (!files?.length) {
    return null;
  }

  const renderFile = (file: TransProgressFile) => {
    const renderStatus = () => {
      const acao =
        file.kind === TransProgressKind.UPLOAD ? 'Upload' : 'Download';
      switch (file.status) {
        case TransProgressStatus.STARTING: {
          return `Iniciando ${acao}`;
        }
        case TransProgressStatus.TRANSFERING: {
          if (file.size! > 0 && file.transfered! > 0) {
            return file.size === file.transfered ? (
              `Finalizando ${acao}...`
            ) : (
              <>
                <b>Progresso: </b>
                {`${
                  Math.round((file.transfered! / file.size!) * 10000) / 100
                }%`}
              </>
            );
          }
          if (file.transfered! > 0) {
            return `${
              file.kind === TransProgressKind.UPLOAD ? 'Enviando' : 'Baixando'
            }: ${bytesToText(file.transfered!, 2)}`;
          }
          return `Realizando ${acao}`;
        }
        case TransProgressStatus.CANCELED: {
          return 'Operação cancelada pelo usuário';
        }
        case TransProgressStatus.FAILED: {
          return `${file.statusInfo}`;
        }
        case TransProgressStatus.FINISHED: {
          return `${acao} completo`;
        }
      }
    };

    const getStatusColor = () => {
      switch (file.status) {
        case TransProgressStatus.FAILED:
          return 'negative-color';
        case TransProgressStatus.CANCELED:
          return 'gray-50';
        case TransProgressStatus.FINISHED:
          return 'module-color';
      }
      return '';
    };

    return (
      <FloatSidebarListItem key={file.id}>
        <Col md={12}>
          <Col md={1}>
            <em className={`fa fa-${file.kind}`} />
          </Col>
          <Col md={10}>
            <b className={getStatusColor()}>
              {file.name || 'Arquivo sem nome'}
            </b>
            {file.size && (
              <>
                <br />
                <small>Tamanho: {bytesToText(file.size, 2)}</small>
              </>
            )}
          </Col>
        </Col>
        <Col md={12}>
          <Col md={10}>
            {file.active && (
              <Icon icon="spinner" spin color="red" className="mr-xs" />
            )}
            {renderStatus()}
          </Col>
          {file.status !== TransProgressStatus.FINISHED && (
            <Col md={1}>
              <InlineButton
                className="text"
                color="negative"
                icon={`fa fa-${file.active ? 'ban' : 'trash'}`}
                label=""
                onClick={() => {
                  file.active ? cancel(file.id) : remove(file.id);
                }}
              ></InlineButton>
            </Col>
          )}
        </Col>
      </FloatSidebarListItem>
    );
  };

  const getBubbleClass = () =>
    `btn circle ${
      files.some(file => file.status === TransProgressStatus.FAILED)
        ? 'negative'
        : 'module-color'
    } ${files.some(file => file.active) ? 'fa-beat' : ''}`;

  const getIcon = () =>
    files.every(file => file.kind !== TransProgressKind.UPLOAD)
      ? 'fa fa-cloud-download-alt'
      : files.every(file => file.kind === TransProgressKind.UPLOAD)
      ? 'fa fa-cloud-upload-alt'
      : 'fa fa-cloud';

  return (
    <FloatSidebar
      title="Transferências em progresso"
      hint
      buttonSlide="10vh"
      bubble={`${files.length}`}
      bubbleClass={getBubbleClass()}
      icon={getIcon()}
      defaultActive={false}
    >
      <FloatSidebarList placeholder="" showFilter={false}>
        {files.map(renderFile)}
      </FloatSidebarList>
    </FloatSidebar>
  );
}

export default TransProgress;
