import { axios } from 'libs';

const resource = '/dashboard';

export const getQtdReceber = () => {
  return axios.get(`${resource}/recebimento-count`);
};

export const getQtdReceberPeloUsuario = () => {
  return axios.get(`${resource}/recebimento-count-usuario`);
};

export const getQtdEncaminhar = () => {
  return axios.get(`${resource}/encaminhar-count`);
};

export const getQtdAssinaturasPendentesByUsuario = () => {
  return axios.get(`${resource}/quantidade-assinaturas-pendentes`);
};

export const getMediaDiasParadoSetor = () => {
  return axios.get(`${resource}/media-dias-parado`);
};

export const getTramitacaoMensal = () => {
  return axios.get(`${resource}/tramitacao-mensal`);
};

export const getSituacaoProcessos = () => {
  return axios.get(`${resource}/quantidade-situacao-processos`);
};

export const getTramitesPorLocal = () => {
  return axios.get(`${resource}/tramites-por-local`);
};

export const getQtdEncaminharParalisado = () => {
  return axios.get(`${resource}/encaminhar-paralisado-count`);
};

export const getQuantidadeAnexosExternos = () => {
  return axios.get(`${resource}/anexos-externos`);
};

export const getNovosComunicados = () => {
  return axios.get(`${resource}/novos-comunicados`);
};
