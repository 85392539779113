import { FormikProps } from 'formik';
import React, { Component, ComponentType } from 'react';

import { getIn } from '../../utils';

export type FormikListOperationsProps = {
  doAdd: <T>(name: string, value: T, formProps: FormikProps<any>) => T[];
  doReplace: <T>(
    name: string,
    value: T,
    index: number,
    formProps: FormikProps<any>
  ) => T[];
  doRemove: <T>(
    name: string,
    index: number,
    formProps: FormikProps<any>
  ) => T[];
};

export const withFormikListOperations = <WCProps,>(
  WrappedComponent: ComponentType<WCProps>
) => {
  return class extends Component<FormikListOperationsProps & WCProps> {
    doAdd = <T,>(name: string, value: T, formProps: FormikProps<any>): T[] => {
      const values = getIn(formProps.values, name.split('.'));

      if (!!values) {
        const newValues: T[] = [...values, value];

        formProps.setFieldValue(name, newValues, true);
        formProps.setFieldTouched(name, true);

        return newValues;
      }

      throw new Error('Invalid property name.');
    };

    doReplace = <T,>(
      name: string,
      value: T,
      index: number,
      formProps: FormikProps<any>
    ): T[] => {
      const values = getIn(formProps.values, name.split('.'));

      if (!!values) {
        const newValues = [
          ...values.slice(0, index),
          value,
          ...values.slice(index + 1)
        ];

        formProps.setFieldValue(name, newValues, true);
        formProps.setFieldTouched(name, true);

        return newValues;
      }

      throw new Error('Invalid property name.');
    };

    doRemove = <T,>(
      name: string,
      index: number,
      formProps: FormikProps<any>
    ): T[] => {
      const values = getIn(formProps.values, name.split('.'));

      if (!!values) {
        const newValues = [
          ...values.slice(0, index),
          ...values.slice(index + 1)
        ];

        formProps.setFieldValue(name, newValues, true);
        formProps.setFieldTouched(name, true);

        return newValues;
      }

      throw new Error('Invalid property name.');
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          doAdd={this.doAdd}
          doReplace={this.doReplace}
          doRemove={this.doRemove}
        />
      );
    }
  };
};
