import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/secretarias';
const defaultSort = { sort: 'id.id,asc' };

export const findAllAutocomplete = (
  searchParam = '',
  pagination,
  sort = defaultSort
) => {
  const search = isNumber(searchParam)
    ? `id.id==${searchParam}`
    : FilterUtils.buildFilter(['descricao'])(searchParam);

  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findAll = (search = '', pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = secretaria => {
  return secretaria.id
    ? axios.put(`${resource}/${secretaria.id}`, secretaria)
    : axios.post(resource, secretaria);
};
