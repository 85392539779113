import { FilterUtils } from '@elotech/components';
import { axios } from 'libs';

const resource = '/ufs';
const defaultSort = { sort: 'id,asc' };

export const findAllAutocomplete = (
  searchParam = '',
  pagination,
  sort = defaultSort
) => {
  const search = FilterUtils.buildFilter(['id', 'descricao'])(searchParam);

  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};
