import { NumberUtils, useShowNotification } from '@elotech/components';
import { useLoading } from 'common/hooks';
import { ArquivoService } from 'common/service';
import { ArquivoEntity, ArquivoUrl } from 'common/type';
import { FilePathEnum, FileSizeEnum, MimeType } from 'common/type/enum';
import saveAs from 'file-saver';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Accept } from 'react-dropzone';

import { TransProgressKind, useTransProgress } from '../transference-progress';
import { ArquivoUtils } from './arquivoUtils';
import DragDropFiles from './DragDropFiles';

type Props = {
  id?: string;
  byTipo?: string;
  canDelete?: boolean;
  canUpload?: boolean;
  isLoading?: boolean;
  service: ArquivoService;
  filePath?: FilePathEnum;
  onVisualizarArquivoHtml?: (template: string) => void;
  extraButton?: (file: ArquivoEntity) => any;
  labels?: (file: ArquivoEntity) => any;
  maxFileNameLength?: number;
};

export type ArquivoComponentRef = {
  saveArquivosTemporarios: (domainEntityId: string) => Promise<void>;
};

export type ArquivoEntityWithTemp = Omit<ArquivoEntity, 'domainEntity'> & {
  temporario?: boolean;
  localFile?: File;
  domainEntity?: ArquivoEntity['domainEntity'];
};

const acceptedFiles = {
  [MimeType.APPLICATION_PDF]: ['.pdf'],
  [MimeType.IMAGE_JPEG]: ['.jpg', 'jpeg'],
  [MimeType.IMAGE_PNG]: ['.png'],
  [MimeType.APLICATION_DOCX]: ['.docx'],
  [MimeType.APPLICATION_MSWORD]: ['.doc'],
  [MimeType.TEXT_PLAIN]: ['.txt'],
  [MimeType.APLICATION_XLS]: ['.xls'],
  [MimeType.APLICATION_XLSX]: ['.xlsx'],
  [MimeType.APPLICATION_ZIP]: ['.zip'],
  [MimeType.APPLICATION_RAR]: ['.rar'],
  [MimeType.APPLICATION_ODT]: ['.odt'],
  [MimeType.APPLICATION_ODS]: ['.ods'],
  [MimeType.APPLICATION_OTS]: ['.ots'],
  [MimeType.IMAGE_BMP]: ['.bmp'],
  [MimeType.IMAGE_GIF]: ['.gif'],
  [MimeType.APPLICATION_PPT]: ['.pot', '.ppa', '.pps', '.ppt', '.pwz'],
  [MimeType.APPLICATION_PPTX]: ['.pptx']
} as Accept;

const ArquivoComponent = forwardRef<ArquivoComponentRef, Props>(
  (props, ref) => {
    const {
      id,
      byTipo,
      service,
      canDelete = true,
      canUpload = true,
      isLoading = false,
      onVisualizarArquivoHtml,
      filePath = FilePathEnum.COMPRAS_ATOS,
      extraButton,
      labels,
      maxFileNameLength
    } = props;
    const [maxSize, setMaxSize] = useState<number>(
      FileSizeEnum.TAMANHO_ARQUIVO_DEFAULT
    );
    const [loading, setLoading] = useLoading();
    const [arquivosEntity, setArquivosEntity] = useState<
      ArquivoEntityWithTemp[]
    >([]);

    const showNotification = useShowNotification();
    const utils = ArquivoUtils(service, filePath);

    const getArquivos = (entityId: string) => {
      setArquivosEntity([]);

      if (byTipo) {
        return setLoading(
          service
            .buscarArquivosPorTipo(entityId, byTipo)
            .then(({ data }) => setArquivosEntity(data))
        );
      } else {
        return setLoading(
          service
            .buscarArquivos(entityId)
            .then(({ data }) => setArquivosEntity(data))
        );
      }
    };

    const saveFile = ({
      file,
      entityId,
      waitSaveEntity
    }: {
      file: File;
      entityId: string;
      waitSaveEntity?: boolean;
    }) => {
      const fileNameUnaccent = utils.getUnaccentFileName(file.name);

      return service
        .gerarUrlparaUpload(filePath, fileNameUnaccent, file.type)
        .then(({ data: arquivoUrl }) => {
          const saveEntityFn = utils
            .saveFileToEntity({
              arquivoUrl: arquivoUrl,
              file: file,
              entityId: entityId,
              fileName: fileNameUnaccent
            })
            .then(({ data }) => {
              setArquivosEntity(prevState => [
                ...prevState,
                {
                  ...data
                }
              ]);
            })
            .catch(error =>
              showNotification({
                level: 'error',
                message: `Não foi possível salvar o arquivo. - ${
                  error.response?.data?.message ||
                  error.response?.data ||
                  error.message
                }`
              })
            );

          return waitSaveEntity ? saveEntityFn : Promise.resolve();
        })
        .catch(error =>
          showNotification({
            level: 'error',
            message: `Não foi possível preparar o arquivo para ser enviado. - ${
              error.response?.data?.message ||
              error.response?.data ||
              error.message
            }`
          })
        );
    };

    const onAdicionarArquivo = (file: File) => {
      const fileNameUnaccent = utils.getUnaccentFileName(file.name);

      const arquivoEncontrado = arquivosEntity.some(
        ({ arquivo }) => arquivo.nome === fileNameUnaccent
      );

      if (arquivoEncontrado) {
        return showNotification({
          level: 'error',
          message: 'Arquivo já inserido.'
        });
      }

      if (!id) {
        const arquivoEntity = utils.arquivoUrlToArquivoEntity(
          {
            fullPath: URL.createObjectURL(file)
          },
          file
        );

        return setArquivosEntity(prevState => [...prevState, arquivoEntity]);
      }

      return setLoading(
        saveFile({
          file,
          entityId: id
        })
      );
    };

    const onDeleteArquivo = (
      arquivoEntity: ArquivoEntityWithTemp,
      index: number
    ) => {
      const deleteFn = (id: string) => {
        if (arquivoEntity.temporario) return Promise.resolve();
        return service.deletarArquivo(id);
      };

      setLoading(
        deleteFn(arquivoEntity.id!)
          .then(() => {
            setArquivosEntity([
              ...arquivosEntity.slice(0, index),
              ...arquivosEntity.slice(index + 1)
            ]);
          })
          .catch(({ response }) =>
            showNotification({
              level: 'error',
              message: `Não foi possível deletar o arquivo. - ${
                response.data.message || response.data
              }`
            })
          )
      );
    };

    const onVisualizarArquivo = ({
      arquivo,
      temporario
    }: ArquivoEntityWithTemp) => {
      const isHtml = arquivo.mimeType === MimeType.TEXT_HTML;

      if (!arquivo.fullPath) return;

      if (temporario) {
        if (isHtml && onVisualizarArquivoHtml) {
          return onViewHtmlFile({
            dataCriacao: arquivo.dataCriacao || new Date().toISOString(),
            fullPath: arquivo.fullPath,
            urlAssinada: arquivo.fullPath
          });
        }

        return onDownloadArquivo({ arquivo, temporario });
      }

      setLoading(
        service
          .gerarUrlParaDownload(arquivo.fullPath!, arquivo.nome!)
          .then(({ data: url }) => {
            if (
              arquivo.mimeType === MimeType.TEXT_HTML &&
              onVisualizarArquivoHtml
            ) {
              onViewHtmlFile(url);
              return;
            }

            if (arquivo.mimeType !== MimeType.APPLICATION_PDF) {
              onDownloadArquivo({ arquivo });
              return;
            }

            service
              .receberArquivoDoServidor(
                url.urlAssinada,
                useTransProgress(
                  arquivo.nome || 'Arquivo sem nome',
                  TransProgressKind.PREVIEW
                )
              )
              .then(({ data }) => {
                const file = new Blob([data], { type: arquivo.mimeType });
                const fileURL = URL.createObjectURL(file);
                setTimeout(() => {
                  URL.revokeObjectURL(fileURL);
                }, 6e4);

                if (!window.open(fileURL)) {
                  throw new Error('Sem permissão para mostrar popups');
                }
              });
          })
      );
    };

    const onViewHtmlFile = (url: ArquivoUrl) => {
      return fetch(url.urlAssinada).then(data =>
        data.text().then(onVisualizarArquivoHtml)
      );
    };

    const onDownloadArquivo = ({
      arquivo: { fullPath, nome, mimeType },
      temporario
    }: Pick<ArquivoEntityWithTemp, 'arquivo' | 'temporario'>) => {
      if (!fullPath) return;

      const download = (url: string) =>
        utils
          .downloadArquivo({
            mimeType,
            nome,
            url
          })
          .then(blob => saveAs(blob, nome));

      if (temporario) {
        return download(fullPath);
      }

      setLoading(
        service
          .gerarUrlParaDownload(fullPath, nome || '')
          .then(({ data: url }) => download(url.urlAssinada))
      );
    };

    const onChangeSigilo = (file: ArquivoEntityWithTemp, index: number) => {
      if (file.temporario) {
        return setArquivosEntity(prevValues => {
          prevValues[index].sigiloso = !file.sigiloso;
          return [...prevValues];
        });
      }

      setLoading(
        service
          .alterarSigilo(file.id!)
          .then(response => {
            const newArquivosEntity = [...arquivosEntity];
            newArquivosEntity[index] = response.data;
            setArquivosEntity([...newArquivosEntity]);
          })
          .catch(({ response }) =>
            showNotification({
              level: 'error',
              message: `Não foi possível alterar o sigilo do arquivo. - ${
                response.data.message || response.data
              }`
            })
          )
      );
    };

    const handleSaveArquivosTemporarios = async (
      entityId: NonNullable<Props['id']>
    ) => {
      const arquivosTemporarios = arquivosEntity.filter(
        arquivo => arquivo.temporario && arquivo.localFile
      );

      return setLoading(
        Promise.all(
          arquivosTemporarios.map(arquivo =>
            saveFile({
              entityId,
              file: arquivo.localFile!,
              waitSaveEntity: true
            })
          )
        ).then(() => getArquivos(entityId))
      );
    };

    const handleExtraButton = (file: ArquivoEntityWithTemp) => {
      if (file.temporario) return;

      return extraButton?.(file as ArquivoEntity);
    };

    const handleLabels = (file: ArquivoEntityWithTemp) => {
      if (file.temporario) return;

      return labels?.(file as ArquivoEntity);
    };

    const clearNotTempFiles = () => {
      setArquivosEntity(prevValues => {
        return prevValues.filter(value => value.temporario);
      });
    };

    const onReorder = (files: ArquivoEntityWithTemp[]) => {
      service.reorder(files.map(file => file.id!));
    };

    useEffect(() => {
      setLoading(
        service.getMaxSize().then(({ data }) => {
          if (NumberUtils.isNumeric(data)) {
            setMaxSize(data);
          }
        })
      );
    }, [service, setLoading]);

    useEffect(() => {
      if (!id) {
        clearNotTempFiles();
        return;
      }
      getArquivos(id);
    }, [id, byTipo, service, setLoading, isLoading]);

    useImperativeHandle(
      ref,
      () => ({
        saveArquivosTemporarios: handleSaveArquivosTemporarios
      }),
      [arquivosEntity]
    );

    return (
      <DragDropFiles
        maxSize={maxSize}
        canDelete={canDelete}
        canUpload={canUpload}
        files={arquivosEntity}
        setFiles={setArquivosEntity}
        onView={onVisualizarArquivo}
        acceptedFiles={acceptedFiles}
        onAddFile={onAdicionarArquivo}
        onRemoveFile={onDeleteArquivo}
        onDownload={onDownloadArquivo}
        onChangeSigilo={onChangeSigilo}
        loading={loading || isLoading}
        extraButton={handleExtraButton}
        labels={handleLabels}
        maxFileNameLength={maxFileNameLength}
        onReorder={onReorder}
      />
    );
  }
);

export { ArquivoComponent as default, ArquivoComponent };
