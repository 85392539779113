import { Container } from '@elotech/components';
import React from 'react';

import { TelasCadastroUnicoEnum } from '../../type/enum';
import { IFrame } from '../iframe';

type Props = {
  telaUnico: TelasCadastroUnicoEnum;
};

const UnicoCadastrosComponent: React.FC<Props> = ({ telaUnico }) => {
  return (
    <Container breadcrumb>
      <IFrame path={`${telaUnico}?iframe=true`} />
    </Container>
  );
};

export { UnicoCadastrosComponent };
