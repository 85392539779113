import { KeycloakService } from '@elotech/components';
import { ProtocoloAuthService, UsuarioCloudService } from 'service';
import { KeycloakTypes } from 'state/types';

export const getUserData = () => dispatch => {
  return UsuarioCloudService.getUserData().then(response => {
    ProtocoloAuthService.saveAuthentication(KeycloakService.getToken());
    dispatch({ type: KeycloakTypes.GET_USER_DATA, payload: response.data });
  });
};

export const setUserProfile = payload => ({
  type: KeycloakTypes.SET_USER_PROFILE,
  payload
});
