export enum TipoAgrupamentoAnualProcesso {
  ASSUNTO = 'ASSUNTO',
  COMPLEMENTO_ASSUNTO = 'COMPLEMENTO_ASSUNTO',
  LOCAL = 'LOCAL',
  SITUACAO = 'SITUACAO'
}

export enum DescTipoAgrupamentoAnuaProcesso {
  ASSUNTO = 'Assunto',
  COMPLEMENTO_ASSUNTO = 'Complemento Assunto',
  LOCAL = 'Local',
  SITUACAO = 'Situação'
}
