import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/orc-unidades';
const defaultSort = {
  sort: 'codigo,asc'
};

export const findAllAutocomplete = (
  searchParam = '',
  pagination,
  sort = defaultSort
) => {
  const search = isNumber(searchParam)
    ? `unidadeComposta==${searchParam}`
    : FilterUtils.buildFilter(['descricao'])(searchParam);

  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};
