import { ReactElement, ReactNode } from 'react';

import { LeftComponentProps } from './LeftComponent';
import { RightComponentProps } from './RightComponent';

export type ItemProps = {
  title?: string;
  children?: ReactNode;
  status?: 'positive' | 'warning' | 'error';
  leftComponent: ReactElement<LeftComponentProps>;
  rightComponent: ReactElement<RightComponentProps>;
  onClickDetails?: () => void;
};

export const Item = (props: ItemProps) => null;
