import { Spinner } from 'common/components';
import { PieChart } from 'components';

export const parseDataToPieChart = (data = [], colors = []) => {
  const labels = [];
  const parsedData = [];

  data.forEach(item => {
    labels.push(item.label);
    parsedData.push(item.value);
  });

  return {
    labels: labels,
    datasets: [
      {
        data: parsedData,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        links: [
          '/processos-novo?tipo=LISTA_VENCIDOS',
          '/processos-novo?tipo=LISTA_A_VENCER',
          '/processos-novo?tipo=LISTA_DENTRO_PRAZO'
        ]
      }
    ]
  };
};

export const getChartContent = (
  loading,
  exists,
  data,
  colors,
  emptyMessage = 'Não há dados configurados para a exibição do gráfico.'
) => {
  let content = null;

  if (loading) {
    content = <Spinner size="2x" />;
  } else if (exists) {
    content = <PieChart data={parseDataToPieChart(data, colors)} />;
  } else {
    content = <h3>{emptyMessage}</h3>;
  }

  return content;
};
