import { useCallback, useEffect, useRef, useState } from 'react';

export const useSingletonLoading = () => {
  const mounted = useRef(false);
  const pendingPromises = useRef({});
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    async (promise: () => Promise<any>, key: string): Promise<any> => {
      setLoading(true);

      if (!pendingPromises.current[key]) {
        pendingPromises.current[key] = promise();
      }

      return pendingPromises.current[key]?.finally(() => {
        delete pendingPromises.current[key];

        const isPromisesEmpty =
          Object.values(pendingPromises.current).length === 0;

        if (isPromisesEmpty && mounted.current) {
          setLoading(false);
        }
      });
    },
    []
  );

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return [loading, fetch] as const;
};
