export enum ModulosReactEnum {
  OXY_COMPRAS = 'OXY_COMPRAS',
  OXY_PATRIMONIO = 'OXY_PATRIMONIO',
  OXY_PROCESSOS = 'OXY_PROCESSOS',
  OXY_OBRAS = 'OXY_OBRAS',
  OXY_CONTROLE_INTERNO = 'OXY_CONTROLE_INTERNO'
}

export enum CodigoModulosReactEnum {
  OXY_COMPRAS = '10',
  OXY_PATRIMONIO = '7',
  OXY_PROCESSOS = '51',
  OXY_OBRAS = '25',
  OXY_CONTROLE_INTERNO = '12'
}
