import { Yup } from '@elotech/components';

const documentoSchema = Yup.object().shape({
  interessado: Yup.string().required('Interessado é obrigatório'),
  tipoDocumento: Yup.string().required('Tipo Documento é obrigatório')
});

const procedimentoInternoSchema = Yup.object().shape({
  dataAutuacao: Yup.date().required('Data Autuação é obrigatória'),
  distribuicao: Yup.object().required('Distribuição é obrigatório'),
  tipoRequerente: Yup.string().required('Tipo Requerente é obrigatório'),
  requerente: Yup.string().required('Requerente é obrigatório'),
  tipoRequerido: Yup.string().required('Tipo Requerido é obrigatório'),
  requerido: Yup.string().required('Requerido é obrigatório')
});

const PADISchema = Yup.object().shape({
  resolucaoInstauradora: Yup.string().required(
    'Resolução Instauradora é obrigatória'
  )
});

const recomendacaoEspecificaSchema = Yup.object().shape({
  promotor: Yup.string().required('Promotor é obrigatório'),
  promotoria: Yup.string().required('Promotoria é obrigatória'),
  comarca: Yup.string().required('Comarca é obrigatória'),
  dataRecomendacao: Yup.date().required('Data Recomendação é obrigatória')
});

const sindicanciaSchema = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório'),
  resolucaoInstauradora: Yup.string().required(
    'Resolução Instauradora é obrigatório'
  )
});

const validationSchema = Yup.object().shape({
  numero: Yup.string().matches(
    /^[0-9]*$/,
    'Somente permitido números inteiros'
  ),
  tipo: Yup.object().required('Tipo é obrigatório'),
  assunto: Yup.object().required('Assunto é obrigatório'),
  situacao: Yup.object().required('Situação é obrigatória'),
  localDestino: Yup.object().required('Destino é obrigatório'),
  usuarioResponsavelTramite: Yup.object().required('Usuário é obrigatório'),
  processoMp: Yup.object()
    .shape({
      descricao: Yup.string().trim().required('Descrição é obrigatório')
    })
    .when('tipo.tipoProcessoMp', {
      is: 'DOCUMENTACAO',
      then: schema => schema.concat(documentoSchema)
    })
    .when('tipo.tipoProcessoMp', {
      is: 'PROCEDIMENTO_INTERNO',
      then: schema => schema.concat(procedimentoInternoSchema)
    })
    .when('tipo.tipoProcessoMp', {
      is: 'PADI',
      then: schema => schema.concat(PADISchema)
    })
    .when('tipo.tipoProcessoMp', {
      is: 'RECOMENDACAO_ESPECIFICA',
      then: schema => schema.concat(recomendacaoEspecificaSchema)
    })
    .when('tipo.tipoProcessoMp', {
      is: 'SINDICANCIA',
      then: schema => schema.concat(sindicanciaSchema)
    })
});

export default validationSchema;
