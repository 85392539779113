import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getConfigSelector, getEntidadeSelector } from 'state';

export const useEnsureProcessoIdEntity = (
  processoId?: string | null
): string | null | undefined => {
  const { entidadeLogada, config } = useSelector(state => ({
    entidadeLogada: getEntidadeSelector(state),
    config: getConfigSelector(state)
  }));

  const entidade = entidadeLogada?.id || config?.idEntidade;

  const processoIdWithEntidade = useMemo(() => {
    try {
      if (!entidade || !processoId) return processoId;
      const params = new URLSearchParams(processoId);
      if (!params.has('entidade')) {
        params.set('entidade', entidade);
      }
      return `?${params.toString()}`;
    } catch (error) {
      return processoId;
    }
  }, [processoId, entidade]);

  return processoIdWithEntidade;
};
