import { Col, DisplayData, FormattedDate, Row } from '@elotech/components';
import React from 'react';

type Props = { processo: any };

const RecomendacaoEspecificaViewSection: React.FC<Props> = ({ processo }) => {
  return (
    <>
      <Row>
        <Col sm={4} md={4}>
          <DisplayData title="Procedimento Origem">
            {processo.processoReferencia}
          </DisplayData>
        </Col>
        <Col sm={4} md={4}>
          <DisplayData title="Data Recomendação">
            <FormattedDate value={processo.processoMp?.dataRecomendacao} />
          </DisplayData>
        </Col>
        <Col sm={4} md={4}>
          <DisplayData title="Data Ciência Recomendação">
            <FormattedDate
              value={processo.processoMp?.dataCienciaRecomendacao}
            />
          </DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={4} md={4}>
          <DisplayData title="Comarca">
            {processo.processoMp?.comarca}
          </DisplayData>
        </Col>
        <Col sm={4} md={4}>
          <DisplayData title="Promotor">
            {processo.processoMp?.promotor}
          </DisplayData>
        </Col>
        <Col sm={4} md={4}>
          <DisplayData title="Promotoria">
            {processo.processoMp?.promotoria}
          </DisplayData>
        </Col>
      </Row>
    </>
  );
};

export default RecomendacaoEspecificaViewSection;
