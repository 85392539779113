import { axios } from 'libs';

const resource = '/controle-emails';
const defaultSort = { sort: 'dataCriacao,desc' };

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const marcarLeitura = controleEmail => {
  return axios.post(`${resource}/marcar-leitura`, controleEmail);
};
