import {
  ApplicationProvider,
  KeycloakContext,
  Loading
} from '@elotech/components';
import { useSingletonLoading } from 'common/hooks';
import { StorageService } from 'libs';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActuatorService,
  EloNotificationService,
  EntidadeService,
  UsuarioCloudService
} from 'service';
import {
  EntidadeActions,
  getConfigSelector,
  getEntidadeSelector,
  getUserInfoSelector
} from 'state';
import { isAuthenticated } from 'utils';

import { ProtocoloAuthProvider } from './ProtocoloAuthProvider';
import { ProtocoloKeycloakProvider } from './ProtocoloKeycloakProvider';

const ApplicationProviderDefault = ({
  notificationService,
  onSearchEntidades,
  getUserPermission,
  onGetVersaoSobreSistema,
  onGetEntidadePrincipal,
  onChangeEntidade,
  getFirebaseToken,
  children
}) => <>{children}</>;

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { config, entidadeLogada, currentUser } = useSelector(state => ({
    config: getConfigSelector(state),
    entidadeLogada: getEntidadeSelector(state),
    currentUser: getUserInfoSelector(state)
  }));

  const [loading, setLoading] = useSingletonLoading();

  const getUserPermissions = useCallback(() => {
    return setLoading(
      () => UsuarioCloudService.getUserPermissions(),
      'user-permissions'
    );
  }, []);

  const getEntidadeLogada = useCallback(() => {
    return setLoading(
      () => EntidadeService.getEntidadeLogada(config.isCloud),
      'entidade-logada'
    );
  }, [config.isCloud]);

  const ProfileProvider = config.isCloud
    ? ProtocoloKeycloakProvider
    : ProtocoloAuthProvider;

  const ApplicationContainer = isAuthenticated()
    ? ApplicationProvider
    : ApplicationProviderDefault;

  const onChangeEntidade = entidade => {
    const entidadeKey = StorageService.getItem('entidade');

    if (
      entidade &&
      entidadeLogada &&
      entidade.id !== entidadeLogada.id &&
      entidade.id !== entidadeKey
    ) {
      StorageService.saveItem('entidade', entidade.id, true);
      dispatch(EntidadeActions.setEntidadeSelecionada(entidade));
      StorageService.removeItem('config');
      StorageService.removeItem('configExterna');
      window.location.reload();
    }

    if (entidade && !entidadeLogada) {
      StorageService.saveItem('entidade', entidade.id, true);
      dispatch(EntidadeActions.setEntidadeSelecionada(entidade));
    }
  };
  const getTenant = tenantId => {
    return Promise.resolve({
      data: {
        identifier: config.tenant
      }
    });
  };

  const userInfo = {
    nome: currentUser.nome,
    username: currentUser.usuario
  };

  return (
    <KeycloakContext.Provider value={{ userInfo }}>
      <ProfileProvider>
        <ApplicationContainer
          onChangeEntidade={onChangeEntidade}
          onGetEntidadePrincipal={getEntidadeLogada}
          disableNotification={!config.notificacaoHabilitada}
          eloNotificationService={EloNotificationService}
          onSearchEntidades={() =>
            EntidadeService.findAll().then(res => ({
              ...res,
              data: res.data.map(e => ({ ...e.entidade }))
            }))
          }
          getUserPermission={config.isCloud ? getUserPermissions : undefined}
          onGetVersaoSobreSistema={ActuatorService.getInfo}
          getTenantData={getTenant}
          tenantIdentifier={!config.isCloud ? config.tenant : undefined}
          userProfileStandalone={
            !config.isCloud
              ? { username: currentUser.usuario, name: currentUser.nome }
              : undefined
          }
        >
          {loading ? <Loading loading /> : children}
        </ApplicationContainer>
      </ProfileProvider>
    </KeycloakContext.Provider>
  );
};
