import { SectionTitle, Yup } from '@elotech/components';
import { InlineButton } from 'common/components';
import { useLoading } from 'common/hooks';
import { validateCpfCnpj } from 'common/utils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProcessoInteressadoService } from 'service';
import { getIsLoggedSelector } from 'state';

import { InteressadosForm } from './InteressadosForm';
import { InteressadosList } from './InteressadosList';

const validationSchema = (interessados = [], isLogged = false) =>
  Yup.object().shape({
    pessoa: isLogged
      ? Yup.object()
          .required('Pessoa é obrigatória')
          .test(
            'pessoa',
            'Pessoa já está inserida',
            value => value && !interessados.some(i => i.pessoa.id === value.id)
          )
      : Yup.object({
          cnpjCpf: Yup.string()
            .required('CNPJ / CPF é obrigatório')
            .test('cnpjCpf', 'CNPJ / CPF já está inserido', value => {
              if (!value) {
                return true;
              }

              return !interessados.some(i => i.pessoa.cnpjCpf === value);
            })
            .test('cnpjCpf', 'CNPJ / CPF inválido', value => {
              if (!value) {
                return true;
              }

              return validateCpfCnpj(value);
            }),
          nome: Yup.string()
            .required('Requerente é obrigatório')
            .max(150, 'Ultrapassou o máximo de 150 caracteres'),
          celular: Yup.string()
            .required('Telefone é obrigatório')
            .max(15, 'Ultrapassou o máximo de 15 caracteres'),
          email: Yup.string()
            .email('Email inválido')
            .required('Email é obrigatório')
            .max(100, 'Ultrapassou o máximo de 100 caracteres')
        }),
    tipoParticipante: Yup.object().required('Tipo é obrigatório'),
    localTrabalho: Yup.string().max(
      100,
      'Ultrapassou o máximo de 100 caracteres'
    )
  });

export const InteressadosContainer = ({
  canAdd = true,
  canRemove = true,
  interessados = [],
  onAdd = i => {},
  onRemove = i => {},
  label = 'Pessoas Extras',
  processoId = '',
  isPersist = false
}) => {
  const isLogged = useSelector(getIsLoggedSelector);
  const [showForm, setShowForm] = useState(false);
  const [interessadosApi, setInteressadosApi] = useState([]);
  const [loading, setLoading] = useLoading();

  useEffect(() => {
    if (isPersist) {
      setLoading(
        ProcessoInteressadoService.findByProcesso(processoId).then(({ data }) =>
          setInteressadosApi(data)
        )
      );
    }
  }, []);

  const add = interessado => {
    setShowForm(false);

    if (isPersist) {
      return setLoading(
        ProcessoInteressadoService.create(processoId, interessado).then(
          ({ data }) => setInteressadosApi([...interessadosApi, data])
        )
      );
    }

    onAdd && onAdd([...interessados, interessado]);
  };

  const remove = (interessado, index) => {
    if (isPersist) {
      const id = `${processoId}&codigo=${interessado.codigo}`;
      return setLoading(
        ProcessoInteressadoService.deleteById(id).then(() => {
          setInteressadosApi([
            ...interessadosApi.slice(0, index),
            ...interessadosApi.slice(index + 1)
          ]);
        })
      );
    }

    onRemove &&
      onRemove([
        ...interessados.slice(0, index),
        ...interessados.slice(index + 1)
      ]);
  };

  const getInteressados = () => {
    return isPersist ? interessadosApi : interessados;
  };

  return (
    <>
      <SectionTitle hasButton>
        {label}
        {canAdd && !showForm && (
          <InlineButton
            icon="fa fa-plus"
            label="Adicionar"
            onClick={() => setShowForm(true)}
          />
        )}
      </SectionTitle>
      {canAdd && showForm && (
        <InteressadosForm
          onAdd={add}
          onCancel={() => setShowForm(false)}
          validationSchema={validationSchema(getInteressados(), isLogged)}
        />
      )}
      <InteressadosList
        interessados={getInteressados()}
        onRemove={canRemove ? remove : undefined}
        isLoading={loading}
      />
    </>
  );
};
