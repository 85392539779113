import axios from 'libs/axios';

const resource = '/assinantes';

export const findAll = (searchParam, pagination) => {
  return axios.get(resource, {
    params: {
      search: searchParam,
      ...pagination
    }
  });
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = assinante => {
  return assinante.id
    ? axios.put(`${resource}/${assinante.id}`, assinante)
    : axios.post(resource, assinante);
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};
