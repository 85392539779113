import { KeycloakProvider, KeycloakService } from '@elotech/components';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { KeycloakActions } from 'state/actions';
import { isAuthenticated } from 'utils';
export const ProtocoloKeycloakProvider = ({ children }) => {
  const dispatch = useDispatch();

  const checkUserExists = () => {
    const userExists = {
      payload: {
        userExists: true
      }
    };

    return Promise.resolve(userExists);
  };

  const setUserProfile = useCallback(
    payload => {
      dispatch(KeycloakActions.setUserProfile(payload));
    },
    [dispatch]
  );

  const getUserData = useCallback(
    () => dispatch(KeycloakActions.getUserData()),
    [dispatch]
  );

  const fastInitialization =
    isAuthenticated() ||
    new URLSearchParams(window.location.search).get('login') === 'true';

  return (
    <KeycloakProvider
      isKeycloakLoaded
      keycloakService={KeycloakService}
      loadUserProfile={setUserProfile}
      setKeycloakLoaded={() => {}}
      checkUserExists={checkUserExists}
      getUserData={getUserData}
      fastInitialization={fastInitialization}
    >
      {children}
    </KeycloakProvider>
  );
};
