import { FAB } from '@elotech/components';
import React from 'react';

type Props = {
  onClick: () => void;
};

const ButtonSave: React.FC<Props> = ({ onClick }) => (
  <div className="btn-save">
    <FAB icon="check" iconColor="white" title="Salvar" onClick={onClick} />
  </div>
);

export { ButtonSave };
