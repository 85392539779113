import { axios } from 'libs';

const resource = '/assinaturas-configuracao';

const defaultSort = {
  sort: 'id,desc'
};

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findByEntidadeLogada = () => {
  return axios.get(`${resource}/get-config-by-entidade`);
};

export const save = configuracao => {
  if (configuracao.id) {
    return axios.put(`${resource}/${configuracao.id}`, configuracao);
  }
  return axios.post(`${resource}`, configuracao);
};

export const saveArquivo = (id, file, tipo) => {
  const formData = new FormData();

  formData.append('files', file);

  return axios.post(`${resource}/${id}/arquivo/${tipo}`, formData);
};

export const deleteArquivo = (id, idArquivo, tipo) => {
  return axios.delete(`${resource}/${id}/arquivo/${tipo}/${idArquivo}`);
};
