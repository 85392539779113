import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/grausigilos';
const defaultSort = {
  sort: 'id,asc'
};

export const findAllAutocomplete = (search, pagination, sort = defaultSort) => {
  const filter = isNumber(search)
    ? `id==${search}`
    : FilterUtils.buildFilter(['descricao'])(search);

  return axios.get(resource, {
    params: {
      search: filter,
      ...pagination,
      ...sort
    }
  });
};

export const findAll = (search = '', pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = grauSigilo => {
  return grauSigilo.id
    ? axios.put(`${resource}/${grauSigilo.id}`, grauSigilo)
    : axios.post(resource, grauSigilo);
};
