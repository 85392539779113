import * as Yup from 'yup';

import { ModeloDados } from '../../type';

export const validationSchemaModelo = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório').max(200),
  categoria: Yup.string().required('Categoria é obrigatória').max(200)
});

export const initialModelo: ModeloDados = {
  id: '',
  nome: '',
  entidade: undefined,
  exercicio: undefined,
  codigo: undefined,
  categoria: undefined,
  sqls: [],
  audit: undefined,
  templateHtml: '',
  sqlModeloPrestacao: undefined
};

export const getActionMessage = (
  type: 'sqls' | 'parametros',
  action: 'add' | 'remove'
) => {
  const actionText = action === 'add' ? 'adicionado' : 'removido';

  if (type === 'sqls') {
    return `Sql ${actionText} com sucesso!`;
  }

  return `Parâmetro ${actionText} com sucesso!`;
};

export const groupBy = function (array: any, field: any) {
  const groupedArr: any = [];
  array.forEach(function (e: any) {
    let group = groupedArr.find(g => g['field'] === e[field]);

    if (group === undefined) {
      group = { field: e[field], groupList: [] };
      groupedArr.push(group);
    }

    group.groupList.push(e);
  });

  return groupedArr;
};
