import classnames from 'classnames';
import { ForwardedRef, MouseEvent, ReactNode, forwardRef } from 'react';

type Props = {
  active?: boolean;
  loading?: boolean;
  className?: string;
  minHeight?: string;
  children: ReactNode;
  onClick?: (event: MouseEvent<any>) => void;
};

export const Card = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      children,
      loading = false,
      onClick,
      active = false,
      className = '',
      minHeight = '200px'
    } = props;

    const classNames = classnames(`card ${className}`, {
      pointer: !!onClick,
      'placeholder-loader': loading,
      active
    });

    return (
      <div
        ref={ref}
        onClick={onClick}
        className={classNames}
        style={{ minHeight }}
      >
        {active && (
          <i className="fa fa-check-circle pull-right hint module-color clean active-button" />
        )}
        {loading ? <div className="loading" /> : children}
      </div>
    );
  }
);
