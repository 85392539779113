import { SectionTitle } from '@elotech/components';
import { InlineButton } from 'common/components';
import { useEffect, useState } from 'react';

import { ParametroForm } from './ParametroForm';
import { ParametroList } from './ParametroList';

const ParametroContainer = ({
  onAdicionarParametro,
  onRemoverParametro,
  parametros,
  error
}) => {
  const [formOpen, setFormOpen] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [parametro, setParametro] = useState({});

  useEffect(() => {
    setFormOpen(parametros.length === 0);
  }, [parametros]);

  const onSelectParametro = index => {
    setIndex(index);
    setFormOpen(true);
    setParametro(parametros[index]);
  };

  const onAddOrUpdateParametro = parametro => {
    onAdicionarParametro(parametro, index);
    resetForm();
  };

  const resetForm = () => {
    setParametro({});
    setFormOpen(false);
    setIndex(undefined);
  };

  const onRemove = (parametro, index) => {
    setFormOpen(parametros.length === 0);
    onRemoverParametro(parametro, index);
  };

  return (
    <>
      <SectionTitle hasButton>
        Parâmetros
        <small className="typography-caption help-message">{error}</small>
        {!formOpen && (
          <InlineButton label="Adicionar" onClick={() => setFormOpen(true)} />
        )}
      </SectionTitle>

      {formOpen ? (
        <ParametroForm
          parametros={parametros}
          onVoltar={resetForm}
          parametro={parametro}
          showVoltar={parametros.length > 0}
          editingIndex={index}
          onAdicionarParametro={onAddOrUpdateParametro}
        />
      ) : (
        <ParametroList
          parametros={parametros}
          onRemoverParametro={onRemove}
          onSelectParametro={onSelectParametro}
        />
      )}
    </>
  );
};

export { ParametroContainer };
