import { FilterUtils } from '@elotech/components';
import axios from 'libs/axios';

const defaultSort = {
  sort: 'descricao,asc'
};

const resource = '/tiposandamento';

export const findAll = (searchParam, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search: searchParam,
      ...sort,
      ...pagination
    }
  });
};

export const findAllAutocomplete = (
  searchParam,
  idOriginal,
  id,
  extraSearch = '',
  sort = defaultSort
) => {
  let search = FilterUtils.buildFilter(['descricao'])(searchParam);

  if (id && id !== 'new') {
    search += ` and id != ${id} `;
  }

  if (idOriginal) {
    search += ` and (ativo==true or id == ${idOriginal}) `;
  } else {
    search += ' and ativo==true';
  }

  if (extraSearch) {
    search += ` and ${extraSearch}`;
  }

  return axios.get(resource, {
    params: {
      search,
      ...sort
    }
  });
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = tipoAndamento => {
  return tipoAndamento.id
    ? axios.put(`${resource}/${tipoAndamento.id}`, tipoAndamento)
    : axios.post(resource, tipoAndamento);
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};
