import { axios } from 'libs';

const resource = '/processos-agrupamento';

export const agruparArquivosEmUnicoPdf = (agrupamento, processoId) => {
  return axios.post(`${resource}/agrupar-arquivos${processoId}`, agrupamento);
};

export const verificarAgrupamentoPendente = processoId => {
  return axios.put(`${resource}/verificar-agrupamento${processoId}`);
};

export const existeProcessoEmAgrupamento = processoId => {
  return axios.get(`${resource}/existe-processo-em-agrupamento${processoId}`);
};
