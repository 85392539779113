export const formataCpfOuCnpj = (cpfCnpj: string = '') => {
  cpfCnpj = cpfCnpj.replace(/[^a-zA-Z0-9*]/g, '');

  if (cpfCnpj.length > 11) {
    return formataCnpj(cpfCnpj);
  }

  return formataCpf(cpfCnpj);
};

export const formataCpf = (cpf = '') => {
  cpf = cpf.replace(/(?!\*)\D/g, ''); //Remove tudo o que não é dígito
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
  return cpf;
};

export const formataCnpj = (cnpj = '') => {
  cnpj = cnpj.replace(/(?!\*)\D/g, ''); //Remove tudo o que não é dígito
  cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
  cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
  return cnpj;
};

export const retirarFormatacaoCpf = (cpf: string) => {
  return cpf.replace(/(\.|-)*/g, '');
};

export const retirarFormatacaoCnpj = (cnpj: string) => {
  return cnpj.replace(/(\.|-|\/)*/g, '');
};

export const retirarFormatacaoCnpjCpf = (cnpjCpf: string = '') => {
  return cnpjCpf.length > 14
    ? retirarFormatacaoCnpj(cnpjCpf)
    : retirarFormatacaoCpf(cnpjCpf);
};
