import {
  Col,
  FormikAutocomplete,
  FormikCheckBox,
  Row,
  Yup
} from '@elotech/components';
import { FormikTinyMCE, InlineButton } from 'common/components';
import { getUrl } from 'common/utils';
import { Form, Formik } from 'formik';
import { FormGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  ModeloDadosService,
  PessoaService,
  TipoAndamentoService
} from 'service';
import { getConfigSelector } from 'state';
import { TipoAndamentoEnum } from 'types';
import { unicoUrl, unicoUrlParams } from 'utils';

const initialValues = {
  pessoa: undefined,
  tipoAndamento: undefined,
  mensagem: '',
  obrigaVisualizacao: true
};

const validationSchema = () =>
  Yup.object().shape({
    pessoa: Yup.object()
      .required('Pessoa é obrigatória')
      .test('pessoaEmail', 'Pessoa sem email informado!', function (value) {
        if (!value) {
          return true;
        }

        return value.email;
      }),
    tipoAndamento: Yup.object().required('Tipo Andamento é obrigatório')
  });

const ProcessoObservadorExternoForm = ({ onCancel, onAdd }) => {
  const config = useSelector(getConfigSelector);

  const onSubmit = (values, actions) => {
    onAdd(values);
    actions.resetForm(initialValues);
  };

  const onCreateRequerente = () => {
    const url =
      config.integradoUnico || config.isCloud
        ? `${unicoUrl}/pessoas/new${unicoUrlParams}`
        : getUrl('protocolo', '/pessoa/new');

    window.open(url, '_blank');

    return Promise.resolve();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {formProps => (
        <Form>
          <Row>
            <FormikAutocomplete
              size={2}
              fast={false}
              name="pessoa"
              label="Pessoa"
              onCreateValue={onCreateRequerente}
              newValueLabel="Adicionar Pessoa"
              onSearch={PessoaService.findAllAutocomplete}
              getOptionLabel={r => r.nome}
            />
            <FormikAutocomplete
              name="tipoAndamento"
              label="Tipo Andamento"
              getOptionLabel={s => `${s.id} - ${s.descricao}`}
              onSearch={search =>
                TipoAndamentoService.findAllAutocomplete(
                  search,
                  formProps?.values?.tipoAndamento?.id,
                  null,
                  `tipo==${TipoAndamentoEnum.EMAIL}`
                )
              }
              size={2}
            />
            <FormikAutocomplete
              name="modelo"
              label="Modelo"
              getOptionLabel={md => md.nome}
              onSearch={ModeloDadosService.findAllAutocomplete}
              size={2}
              onItemSelected={(formProps, value) => {
                if (value?.id) {
                  ModeloDadosService.findTemplate(value.id).then(({ data }) =>
                    formProps.setFieldValue('mensagem', data)
                  );
                }
              }}
            />
            <FormikCheckBox
              name="obrigaVisualizacao"
              label="Obriga Visualização"
              className="mt-xs"
              size={3}
            />
            <Col sm={1} md={1} className="mt-xs">
              <FormGroup>
                <InlineButton
                  label="Adicionar"
                  onClick={formProps.submitForm}
                />
              </FormGroup>
            </Col>
            <Col sm={1} md={1} className="mt-xs">
              <FormGroup>
                <InlineButton
                  label="Cancelar"
                  color="negative"
                  onClick={() => {
                    formProps.resetForm(initialValues);
                    onCancel();
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <FormikTinyMCE name="mensagem" label="Mensagem" size={12} />
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ProcessoObservadorExternoForm;
