import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/modelos-impressao';
const defaultSort = {
  sort: 'id,asc'
};

export const searchCustomRpt = tipo => {
  return axios.get(`${resource}/search-rpt-custom?tipo=${tipo}`);
};

export const findAll = (search = '', pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = modelo => {
  return modelo.id
    ? axios.put(`${resource}/${modelo.id}`, modelo)
    : axios.post(resource, modelo);
};

export const findAllAutocomplete = (
  searchParam = '',
  pagination,
  sort = defaultSort
) => {
  const search = isNumber(searchParam)
    ? `id==${searchParam}`
    : FilterUtils.buildFilter(['descricao'])(searchParam);

  return axios.get(resource, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};
