import { ActionButton, ActionsGroup, Table } from '@elotech/components';
import { TableContainer } from 'common/components';
import { useHistory } from 'react-router';

export const ProcessoAnexo = ({ anexos = [], onRemove, loading = false }) => {
  const history = useHistory();

  return (
    <TableContainer>
      <Table
        values={anexos}
        loading={loading}
        keyExtractor={a => `${a.numeroAnexo}-${a.anoAnexo}`}
      >
        <Table.Column
          header="Tipo"
          value={a => `${a?.tipoAnexo?.id} - ${a?.tipoAnexo?.descricao}`}
        />
        <Table.Column header="Número" value={a => a?.numeroAnexo} />
        <Table.Column header="Ano" value={a => a?.anoAnexo} />
        <Table.Column
          header=""
          value={(a, index) => (
            <ActionsGroup>
              <ActionButton
                label="Visualizar"
                onClick={() =>
                  history.push(
                    `/processos-novo/view/?tipo=${a.tipoAnexo?.id}&numero=${a?.numeroAnexo}&ano=${a?.anoAnexo}&entidade=${a?.entidadeAnexo.id}`
                  )
                }
                icon="external-link-alt"
              />
              {onRemove && (
                <ActionButton
                  icon="trash"
                  label="Remover"
                  onClick={() => onRemove(a, index)}
                />
              )}
            </ActionsGroup>
          )}
        />
      </Table>
    </TableContainer>
  );
};
