import React, { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  classes?: string;
  title?: string;
  withIcon?: boolean;
  type?: 'INFO' | 'SUCCESS' | 'ERROR' | 'WARNING';
};

export const Informativo: FC<Props> = ({
  children,
  classes = '',
  title = '',
  withIcon = false,
  type
}) => {
  let colorClass = '';
  let iconClass = '';

  switch (type) {
    case 'INFO':
      iconClass = 'fa-info-circle';
      colorClass = 'primary';
      break;

    case 'SUCCESS':
      iconClass = 'fa-check-circle';
      colorClass = 'positive';
      break;

    case 'ERROR':
      iconClass = 'fa-times-circle';
      colorClass = 'negative';
      break;

    case 'WARNING':
      iconClass = 'fa-exclamation-triangle';
      colorClass = 'warning';
      break;

    default:
      break;
  }

  if (withIcon) {
    return (
      <div className={`info ${colorClass} icon ${classes}`}>
        <div className="icon">
          <em className={`fa ${iconClass}`} />
        </div>

        <article>
          <h4>{title}</h4>
          <p>{children}</p>
        </article>
      </div>
    );
  }

  return (
    <article className={`info ${colorClass} ${classes}`}>{children}</article>
  );
};
