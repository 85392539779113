import { ProcessosAgrupadosTypes } from 'state/types';

const initialState = {
  processosAgrupados: []
};

export const ProcessosAgrupadosReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProcessosAgrupadosTypes.ADD_PROCESSO_AGRUPADO: {
      if (state.processosAgrupados.some(p => p.id === action.payload.id)) {
        return state;
      }

      return {
        ...state,
        processosAgrupados: [action.payload, ...state.processosAgrupados]
      };
    }
    case ProcessosAgrupadosTypes.ADD_PROCESSOS_AGRUPADOS: {
      const ids = state.processosAgrupados.map(p => p.id);

      return {
        ...state,
        processosAgrupados: action.payload
          .filter(p => !ids.includes(p.id))
          .concat(state.processosAgrupados)
      };
    }
    case ProcessosAgrupadosTypes.REMOVE_PROCESSO_AGRUPADO:
      return {
        ...state,
        processosAgrupados: state.processosAgrupados.filter(
          p => p.id !== action.payload.id
        )
      };
    case ProcessosAgrupadosTypes.REMOVE_PROCESSOS_AGRUPADOS: {
      const idsRemove = action.payload.map(p => p.id);

      return {
        ...state,
        processosAgrupados: state.processosAgrupados.filter(
          p => !idsRemove.includes(p.id)
        )
      };
    }
    case ProcessosAgrupadosTypes.SET_PROCESSOS_AGRUPADOS:
      return {
        ...state,
        processosAgrupados: action.payload
      };
    case ProcessosAgrupadosTypes.CLEAR_PROCESSOS_AGRUPADOS:
      return {
        ...state,
        processosAgrupados: []
      };
    default:
      return state;
  }
};
