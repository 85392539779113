import Chart from 'chart.js';
import React from 'react';
import { withRouter } from 'react-router';

class PieChart extends React.Component {
  static defaultProps = {
    options: {
      segmentShowStroke: true,
      segmentStrokeColor: '#fff',
      segmentStrokeWidth: 2,
      percentageInnerCutout: 0, // Setting this to zero convert a doughnut into a Pie
      animationSteps: 100,
      animationEasing: 'easeOutBounce',
      animateRotate: true,
      animateScale: false,
      responsive: true,
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  componentDidMount() {
    const { data, options } = this.props;
    const ctx = this.refs.chart.getContext('2d');

    if (ctx) {
      this.pieChart = new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: {
          ...options,
          onClick: (event, item) => {
            const index = item[0]._index;
            this.props.history.push(data.datasets[0].links[index]);
          }
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.pieChart) {
      this.pieChart.destroy();
    }
  }

  render() {
    return (
      <div>
        <canvas ref="chart"> </canvas>
      </div>
    );
  }
}

export default withRouter(PieChart);
