const cache = [];

export const safeStringify = (key, value) => {
  if (typeof value === 'object' && value !== null) {
    if (cache.includes(value)) return;
    cache.push(value);
  }

  return value;
};
