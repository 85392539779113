const isProcessoMp = () => {
  const key = 'config';
  const value = localStorage.getItem(key) || sessionStorage.getItem(key);

  if (value) {
    return JSON.parse(value)?.isProcessoMp;
  }

  return false;
};

export const LABEL_PROCESSO = isProcessoMp() ? 'Procedimento' : 'Processo';
export const LABEL_PROCESSOS = isProcessoMp() ? 'Procedimentos' : 'Processos';

export const LABEL_PROCESSO_COLUMN = isProcessoMp()
  ? 'Nº Procedimento e PAD'
  : 'Processo';

export const LABEL_ASSUNTO = isProcessoMp() ? 'Categoria' : 'Assunto';
export const LABEL_ASSUNTOS = isProcessoMp() ? 'Categorias' : 'Assuntos';

export const LABEL_PARECER = isProcessoMp() ? 'Observação' : 'Parecer';
export const LABEL_PARECERES = isProcessoMp() ? 'Observações' : 'Pareceres';

export const LABEL_DESFAVORITAR = isProcessoMp()
  ? 'Desatribuir como prioritário'
  : 'Desfavoritar';
export const LABEL_FAVORITAR = isProcessoMp()
  ? 'Atribuir como prioritário'
  : 'Favoritar';
export const LABEL_PARALISAR = isProcessoMp() ? 'Suspender' : 'Paralisar';
