import { usePermissions } from 'hooks';

export const ProtectedComponent = ({
  children,
  role,
  seletor,
  customCondition = true
}) => {
  const { hasPermission } = usePermissions();

  if (customCondition && hasPermission(role, seletor)) {
    return children;
  }

  return null;
};
