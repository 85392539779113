import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/caixas';
const defaultSort = {
  sort: 'descricao,asc'
};

export const findAllAutocomplete = (
  searchParam = '',
  pagination,
  sort = defaultSort
) => {
  const search = isNumber(searchParam)
    ? `id.codigo==${searchParam}`
    : FilterUtils.buildFilter(['descricao'])(searchParam);

  return axios.get(resource, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const save = values => {
  return values.id
    ? axios.put(`${resource}/${values.id}`, values)
    : axios.post(resource, values);
};

export const alterarCaixa = (processo, anoCaixa, codigoCaixa) => {
  const tipoProcesso = processo.tipo.id;
  const anoProcesso = processo.ano;
  const numeroProcesso = processo.numero;
  const entidadeProcesso = processo.entidade.id;

  return axios.put(
    `/processos/alterar-caixa?tipoProcesso=${tipoProcesso}&anoProcesso=${anoProcesso}&numeroProcesso=${numeroProcesso}&anoCaixa=${anoCaixa}&codigoCaixa=${codigoCaixa}&entidadeProcesso=${entidadeProcesso}`
  );
};
