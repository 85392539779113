import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { FC, MouseEvent } from 'react';

export type ChipTextType = 'positive' | 'negative' | 'module-color' | 'warning';

type ChipTextProps = {
  label?: string;
  outlined?: boolean;
  type?: ChipTextType;
  interactive?: boolean;
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  className?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  onClickLeftIcon?: (event: MouseEvent<SVGSVGElement>) => void;
  onClickRightIcon?: (event: MouseEvent<SVGSVGElement>) => void;
};

const ChipText: FC<ChipTextProps> = ({
  label,
  children,
  type = 'positive',
  outlined = true,
  interactive = false,
  leftIcon,
  rightIcon,
  className = '',
  onClick = () => {},
  onClickLeftIcon = () => {},
  onClickRightIcon = () => {}
}) => {
  const chipContainerClasses = classnames(
    `chip-container ${type} ${className}`,
    {
      outlined,
      interactive
    }
  );

  return (
    <>
      {label && <label className="label">{label}</label>}
      <div className={chipContainerClasses} onClick={onClick}>
        {leftIcon && (
          <FontAwesomeIcon
            className="chip-icon-left"
            icon={leftIcon}
            spin={leftIcon === 'spinner'}
            onClick={onClickLeftIcon}
          />
        )}
        <p className="chip-text">{children}</p>
        {rightIcon && (
          <FontAwesomeIcon
            className="chip-icon-right"
            icon={rightIcon}
            onClick={onClickRightIcon}
          />
        )}
      </div>
    </>
  );
};

export { ChipText };
