import React, { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

type Props = {
  children: ReactNode;
  hasElementsAbove?: boolean;
  classNames?: string;
  style?: CSSProperties;
  bodyStyle?: CSSProperties;
};

const TableContainer: React.FC<Props> = ({
  children,
  hasElementsAbove = false,
  classNames = '',
  style = {},
  bodyStyle = {}
}) => {
  return (
    <div
      className={`panel table table-responsive ${
        hasElementsAbove ? '' : 'mt-xs'
      } ${classNames}`}
      style={style}
    >
      <div className="panel-body" style={bodyStyle}>
        {children}
      </div>
    </div>
  );
};

export { TableContainer };
