import { axios } from 'libs';

const resource = '/processos-observadores-externos';

export const sendEmail = id => {
  return axios.post(`${resource}/${id}/send-email`);
};

export const save = values => {
  return axios.post(`${resource}`, values);
};

export const remove = id => {
  return axios.delete(`${resource}/${id}`);
};
