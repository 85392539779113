import { ApplicationContext, RoleUsuarioDTO } from '@elotech/components';
import { RenderResult, render } from '@testing-library/react';
import { History, createMemoryHistory } from 'history';
import { ReactElement } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Context as ResponsiveContext } from 'react-responsive';
import { Route, Router } from 'react-router';
import { Store } from 'redux';
import configureMockStore from 'redux-mock-store';
import thunk from 'redux-thunk';

export type RenderWithRouterResult = RenderResult & { history: History };

export type RenderWithReduxRouterResult = RenderResult & {
  history: History;
  store: Store;
};

const middlewares = [thunk];
export const createMockedStore = (initialState = {}) =>
  configureMockStore(middlewares)(initialState);

export const renderWithRouter = (
  children,
  {
    path = '/',
    initialRoute = '/',
    history = createMemoryHistory({ initialEntries: [initialRoute] })
  } = {}
): RenderWithRouterResult => {
  return {
    ...render(
      <IntlProvider locale="pt-BR">
        <Router history={history}>
          <Route path={path}>{children}</Route>
        </Router>
      </IntlProvider>
    ),
    history
  };
};

export const renderWithReduxRouter = (
  children,
  { path = '/', initialRoute = '/' } = {},
  initialState = {}
): RenderWithReduxRouterResult => {
  const store = createMockedStore(initialState);

  return {
    ...renderWithRouter(<Provider store={store}>{children}</Provider>, {
      path,
      initialRoute
    }),
    store
  };
};

export const renderWithReduxRouterRoles = (
  children: ReactElement,
  roles: RoleUsuarioDTO,
  { path = '/', initialRoute = '/' } = {},
  initialState = {}
): RenderWithReduxRouterResult => {
  return {
    ...renderWithReduxRouter(
      <ApplicationContext.Provider
        value={{
          setEntidade: () => {},
          userPermissions: roles
        }}
      >
        <ResponsiveContext.Provider value={{ width: 1000 }}>
          {children}
        </ResponsiveContext.Provider>
      </ApplicationContext.Provider>,
      {
        path,
        initialRoute
      },
      initialState
    )
  };
};
