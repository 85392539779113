import { axios } from 'libs';

const resource = '/processos/usuarios/view';
const defaultSort = {
  sort: 'data,desc'
};

export const saveUserView = id => {
  return axios.post(`${resource}${id}`);
};

export const findAllByProcesso = (
  entidade,
  ano,
  tipo,
  numero,
  pagination,
  sort = defaultSort
) => {
  return axios.get(`${resource}/dto`, {
    params: {
      search: `processo.id.entidade.id==${entidade} and processo.id.ano==${ano} and processo.id.numero==${numero} and processo.id.tipo.id==${tipo}`,
      ...sort,
      ...pagination
    }
  });
};
