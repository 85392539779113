import { ActionButton, ActionsGroup, Table } from '@elotech/components';
import { TableContainer } from 'common/components';

import { ParametroModelo } from '../../../type';

const ParametroList = ({
  parametros,
  onRemoverParametro,
  onSelectParametro
}) => (
  <TableContainer>
    <Table
      fixed
      reduced={false}
      values={parametros}
      keyExtractor={(p: ParametroModelo) =>
        `${p.id}-${p.nome}-${p.identificador}`
      }
    >
      <Table.Column
        header="Nome"
        value={(parametro: ParametroModelo) => parametro.nome}
      />
      <Table.Column
        header="Identificador"
        value={(parametro: ParametroModelo) => parametro.identificador}
      />
      <Table.Column
        header="Tipo"
        value={(parametro: ParametroModelo) => parametro.tipo}
      />
      <Table.Column
        header=""
        value={(parametro, index) => {
          return (
            <ActionsGroup>
              <ActionButton
                label="Editar"
                icon="pencil-alt"
                onClick={() => onSelectParametro(index)}
              />
              <ActionButton
                label="Remover"
                icon="trash"
                onClick={() => onRemoverParametro(parametro, index)}
              />
            </ActionsGroup>
          );
        }}
      />
    </Table>
  </TableContainer>
);

export { ParametroList };
