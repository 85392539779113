import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { StorageService, axios } from 'libs';

const resource = '/entidades';

export const findAll = () => {
  return axios.get(resource);
};

export const findAllAutoComplete = (searchParam, pagination) => {
  const search = isNumber(searchParam)
    ? ` id==${searchParam}`
    : ` ${FilterUtils.buildFilter(['nome'])(searchParam)}`;

  return axios.get(`${resource}/auto-complete`, {
    params: {
      search,
      ...pagination
    }
  });
};

export const findById = id => {
  return axios.get(`${resource}/${id}`);
};

export const getEntidadePrincipal = () => {
  return axios.get(`${resource}/entidade-principal`);
};

export const getEntidadePrincipalUsuario = () => {
  return axios.get(`${resource}/entidade-principal-usuario`);
};

export const getEntidadePrincipalUsuarioKeycloak = () => {
  return axios.get(`${resource}/entidade-principal-usuario-keycloak`);
};

export const findByIdOrEntidadePrincipal = id => {
  return axios.get(`${resource}/find-by-id-or-entidade-principal/${id}`);
};

export const getEntidadeLogada = isCloud => {
  const entidadeKey = StorageService.getItem('entidade');

  if (isNumber(entidadeKey)) return findById(entidadeKey);
  if (isCloud) return getEntidadePrincipalUsuarioKeycloak();

  return getEntidadePrincipalUsuario();
};
