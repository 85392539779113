import { Col, DisplayData, FormattedDate, Row } from '@elotech/components';
import React from 'react';

type Props = { processo: any };

const SindicanciaViewSection: React.FC<Props> = ({ processo }) => {
  return (
    <>
      <Row>
        <Col sm={3} md={3}>
          <DisplayData title="Data Publicação">
            <FormattedDate value={processo.processoMp.dataPublicacao} />
          </DisplayData>
        </Col>
      </Row>
      <Row>
        <Col sm={4} md={4}>
          <DisplayData title="Sindicado">
            {processo.processoMp.nome}
          </DisplayData>
        </Col>
        <Col sm={4} md={4}>
          <DisplayData title="Procedimento Origem">
            {processo.processoReferencia}
          </DisplayData>
        </Col>
        <Col sm={4} md={4}>
          <DisplayData title="Resolução Instauradora">
            {processo.processoMp.resolucaoInstauradora}
          </DisplayData>
        </Col>
      </Row>
    </>
  );
};

export default SindicanciaViewSection;
