import {
  ActionButton,
  ActionsGroup,
  AutoComplete,
  BasicInput,
  Col,
  FormattedDate,
  Row,
  Table,
  TableChild,
  TextArea
} from '@elotech/components';
import { InlineButton, TableContainer } from 'common/components';
import { useExpandedIds, useLoading } from 'common/hooks';
import { Formik } from 'formik';
import { LABEL_PARECER, LABEL_PARECERES } from 'labels';
import { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  CaixaService,
  ParametroService,
  TramiteService,
  UsuarioService
} from 'service';
import { getIsLoggedSelector, getUserInfoSelector } from 'state';
import styled from 'styled-components';

import {
  BLOQUEAR_EDICAO_TRAMITACAO_JA_EFETUADA,
  BLOQUEIA_ALTERACAO_PARECER
} from '../../parametros/Parametros';
import HistoricoParecer from './HistoricoParecer';
import TramitesTimelineModal from './TramitesTimelineModal';

const initialControls = {
  isBoxChild: false,
  bloquearParecer: false,
  bloquearUsuarioParecer: false,
  historicoParecer: undefined,
  isModalOpen: false
};

export const TramitesList = ({
  processo,
  tramites,
  onUpdateParecer,
  onUpdateCaixa,
  onImprimirPapeleta,
  loading = false,
  paramProcessoMp = false
}) => {
  const { search: id } = useLocation();
  const [loadingParam, setLoadingParam] = useLoading();
  const { expandedIds, handleExpandedIds } = useExpandedIds();
  const { isLogged, usuarioLogado } = useSelector(state => ({
    isLogged: getIsLoggedSelector(state),
    usuarioLogado: getUserInfoSelector(state)
  }));
  const [controls, setControls] = useState(initialControls);
  const [tramitesUsuarios, setTramitesUsuarios] = useState({});

  useEffect(() => {
    setTramitesUsuarios([]);
    setLoadingParam(
      Promise.all(
        tramites
          ?.map?.(tramite => tramite?.usuarioResponsavelTramite)
          .filter(u => u)
          .filter((u, i, self) => i === self.indexOf(u))
          .map(usuarioId =>
            UsuarioService.findUserNameById(usuarioId).then(({ data }) => {
              if (data) {
                setTramitesUsuarios(usuarios => ({
                  ...usuarios,
                  [usuarioId]: data
                }));
              }
            })
          )
      )
    );
  }, [tramites]);

  useEffect(() => {
    if (isLogged && onUpdateParecer) {
      Promise.all([
        ParametroService.findParametro(BLOQUEAR_EDICAO_TRAMITACAO_JA_EFETUADA),
        ParametroService.findParametro(BLOQUEIA_ALTERACAO_PARECER)
      ]).then(([blockEdicaoTramitado, blockAlteracaoParecer]) => {
        setControls(prev => {
          return {
            ...prev,
            bloquearParecer: blockEdicaoTramitado.data.valor === 'S',
            bloquearUsuarioParecer: blockAlteracaoParecer.data.valor === 'S'
          };
        });
      });
    }
  }, [setLoadingParam]);

  const canPrintPapeleta = tramite => {
    return (
      isLogged &&
      (tramite.funcao === 'ENCAMINHADO' || tramite.funcao === 'ARQUIVADO') &&
      onImprimirPapeleta &&
      !paramProcessoMp
    );
  };

  const handleControls = (name, value) => {
    setControls(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const canEditParecer = (index, tramite) => {
    const canEdit = !controls.bloquearParecer && isLogged && index === 0;

    if (canEdit) {
      const sameUser = usuarioLogado.username === tramite.usuarioRegistro;
      return (
        !controls.bloquearUsuarioParecer ||
        (controls.bloquearUsuarioParecer && sameUser)
      );
    }

    return false;
  };

  const onSubmit = (sequencia, parecer) => {
    if (parecer) {
      setLoadingParam(
        TramiteService.salvarParecer(id, sequencia, {
          parecer
        }).then(({ data }) => {
          onUpdateParecer(data);
          handleExpandedIds(sequencia);
        })
      );
    }
  };

  const renderInnerComponent = (tramite, index) => {
    if (expandedIds.includes(tramite.sequencia)) {
      if (controls.isBoxChild) {
        return (
          <TableChild>
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="label" htmlFor={'caixa'}>
                    Caixa
                  </label>
                  <AutoComplete
                    name="caixa"
                    label="Caixa"
                    onSearch={CaixaService.findAllAutocomplete}
                    getOptionLabel={c => `${c.codigo} - ${c.descricao}`}
                    onSelect={(name, value) => {
                      if (value) {
                        setLoadingParam(
                          CaixaService.alterarCaixa(
                            processo,
                            value.ano,
                            value.codigo
                          ).then(({ data }) => {
                            onUpdateCaixa(data);
                            handleExpandedIds(tramite.sequencia);
                          })
                        );
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </TableChild>
        );
      }
      return (
        <TableChild>
          <Row>
            <Col sm={12} md={12}>
              <Formik
                enableReinitialize
                initialValues={{
                  parecer: tramite.parecer,
                  parecerResposta: tramite.parecerResposta,
                  dataParecerResposta: tramite.dataParecerResposta
                }}
                onSubmit={values => onSubmit(tramite.sequencia, values.parecer)}
              >
                {formProps => (
                  <>
                    <Col md={6} sm={6}>
                      <Row>
                        <BasicInput
                          rows={4}
                          size={12}
                          name="parecer"
                          label={LABEL_PARECER}
                          readOnly={!canEditParecer(index, tramite)}
                          render={({ field }) => (
                            <TextArea
                              {...field}
                              id="parecer"
                              rows={4}
                              readOnly={!canEditParecer(index, tramite)}
                            />
                          )}
                        />
                      </Row>
                      {canEditParecer(index, tramite) && (
                        <Row>
                          <Col md={12} sm={12}>
                            <FormGroup>
                              <InlineButton
                                type="submit"
                                icon="fa fa-check"
                                label={`Atualizar ${LABEL_PARECER}`}
                                disabled={!formProps.values.parecer}
                                onClick={formProps.submitForm}
                              />
                              <InlineButton
                                type="button"
                                icon="fa fa-history"
                                label={
                                  paramProcessoMp
                                    ? 'Observações Anteriores'
                                    : 'Pareceres Anteriores'
                                }
                                onClick={() =>
                                  handleControls('historicoParecer', tramite)
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col md={6} sm={6}>
                      {!paramProcessoMp && (
                        <Row>
                          <BasicInput
                            readOnly
                            rows={4}
                            size={12}
                            name="parecerResposta"
                            label={`${LABEL_PARECER} do Requerente`}
                            render={({ field }) => (
                              <TextArea
                                {...field}
                                id="parecerResposta"
                                rows={4}
                                readOnly
                              />
                            )}
                          />
                        </Row>
                      )}
                      <Row>
                        <BasicInput
                          size={12}
                          label="Data/Hora"
                          hint={`Data/Hora do preenchimento do(a) ${LABEL_PARECER} do Parecer do requerente`}
                          name="dataParecerResposta"
                          readOnly
                          render={({ field }) => (
                            <input
                              {...field}
                              type="datetime-local"
                              max="9999-12-31T00:00:00"
                              readOnly
                            />
                          )}
                        />
                      </Row>
                    </Col>
                  </>
                )}
              </Formik>
            </Col>
          </Row>
        </TableChild>
      );
    }

    return null;
  };

  return (
    <>
      <TableContainer>
        <Table
          fixed
          loading={loadingParam || loading}
          keyExtractor={p => p.sequencia}
          renderInnerComponent={renderInnerComponent}
          values={tramites?.sort((a, b) => b.sequencia - a.sequencia) || []}
          reduced={false}
        >
          <Table.Column header="Ordem" value={p => p.sequencia} />
          <Table.Column
            header="Situação"
            value={p => `${p.situacao?.id} - ${p.situacao?.descricao}`}
          />
          <Table.Column header="Status" value={p => p.funcao} />
          <Table.Column
            header="Data"
            value={p => <FormattedDate value={p.data} />}
          />
          <Table.Column header="Hora" value={p => p.hora} />
          <Table.Column
            header="Local"
            value={p => `${p.local?.id} - ${p.local?.descricao}`}
          />
          {!paramProcessoMp && (
            <Table.Column
              header="Caixa"
              value={p =>
                p.caixa ? `${p.caixa?.codigo} - ${p.caixa?.descricao}` : ''
              }
            />
          )}
          {!paramProcessoMp && (
            <Table.Column header="Dias" value={p => p.quantidadeDiasLocal} />
          )}
          <Table.Column header="Usuário Registro" value={p => p.nomeUsuario} />
          <Table.Column
            header="Usuário Destino"
            value={p => tramitesUsuarios[p.usuarioResponsavelTramite]}
          />
          <Table.Column
            header={
              <ActionsGroup>
                <ActionButton
                  icon="stream"
                  label="Visualizar Linha do Tempo"
                  onClick={() => handleControls('isModalOpen', true)}
                />
                <ActionButton
                  icon="comments"
                  label={LABEL_PARECERES}
                  onClick={() => {
                    handleControls('isBoxChild', false);
                    tramites.forEach(tramite => {
                      if (!expandedIds.includes(tramite.sequencia)) {
                        handleExpandedIds(tramite.sequencia);
                      }
                    });
                  }}
                />
              </ActionsGroup>
            }
            value={p => (
              <ActionsGroup>
                {p.sequencia ===
                  tramites?.sort((a, b) => b.sequencia - a.sequencia)[0]
                    .sequencia &&
                  p.funcao === 'ARQUIVADO' &&
                  onUpdateCaixa && (
                    <ActionButton
                      icon="archive"
                      label="Alterar caixa"
                      onClick={() => {
                        handleControls('isBoxChild', true);
                        return handleExpandedIds(p.sequencia);
                      }}
                    />
                  )}
                {(onUpdateParecer || paramProcessoMp) && (
                  <CustomParecerButtonActiveStyle>
                    <ActionButton
                      customClass={p.parecer ? 'parecer-active' : undefined}
                      icon="comments"
                      label={LABEL_PARECERES}
                      onClick={() => {
                        handleControls('isBoxChild', false);
                        return handleExpandedIds(p.sequencia);
                      }}
                    />
                  </CustomParecerButtonActiveStyle>
                )}
                {canPrintPapeleta(p) && (
                  <ActionButton
                    icon="print"
                    label="Imprimir Papeleta"
                    onClick={() => onImprimirPapeleta(p.sequencia)}
                  />
                )}
              </ActionsGroup>
            )}
          />
        </Table>
      </TableContainer>
      {controls.historicoParecer && (
        <HistoricoParecer
          processoId={id}
          sequencia={controls.historicoParecer.sequencia}
          onClose={() => handleControls('historicoParecer', undefined)}
          paramProcessoMp={paramProcessoMp}
        />
      )}
      {controls.isModalOpen && processo && (
        <TramitesTimelineModal
          processo={processo}
          tramitesUsuarios={tramitesUsuarios}
          onClose={() => handleControls('isModalOpen', false)}
        />
      )}
    </>
  );
};

/* Correção temporária até que seja ajustado diretamente no Arc */
const CustomParecerButtonActiveStyle = styled.div`
  .parecer-active {
    background: var(--background-primary) !important;
  }

  .parecer-active svg * {
    fill: white !important;
  }
`;
