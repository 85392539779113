import { Col, DisplayData, FormattedDate, Row } from '@elotech/components';
import classNames from 'classnames';
import { CustomLink, TinyMCE } from 'common/components';
import { LABEL_ASSUNTO, LABEL_PROCESSO } from 'labels';
import React from 'react';

import DocumentacaoViewSection from './view/DocumentacaoViewSection';
import PADIViewSection from './view/PADIViewSection';
import ProcedimentoInternoViewSection from './view/ProcedimentoInternoViewSection';
import RecomendacaoEspecificaViewSection from './view/RecomendacaoEspecificaViewSection';
import SindicanciaViewSection from './view/SindicanciaViewSection';

type Props = {
  processo: any;
  loading: boolean;
};

const ProcessoMpCabecalho: React.FC<Props> = ({ processo, loading }) => {
  const classes = classNames('display-data border small', {
    'placeholder-loader': loading
  });

  return (
    <>
      <div className={classes}>
        <Row>
          <Col sm={3} md={3}>
            <DisplayData title="Tipo">{`${processo.tipo?.id} - ${processo.tipo?.descricao}`}</DisplayData>
          </Col>
          <Col sm={3} md={3}>
            <DisplayData title={LABEL_PROCESSO}>
              {processo.numero}/{processo.ano}
            </DisplayData>
          </Col>
          {processo.processoPai && (
            <Col sm={3} md={3}>
              <DisplayData title="Processo Pai">
                <CustomLink
                  identificador={`${processo.processoPai?.numero}/${processo.processoPai?.ano}`}
                  uri={`/processo-mp/view/${processo.processoPai?.id}`}
                />
              </DisplayData>
            </Col>
          )}
          <Col sm={3} md={3}>
            <DisplayData title="Data Instauração">
              <FormattedDate value={processo.dataProcesso} />
            </DisplayData>
          </Col>
          <Col sm={2} md={2}>
            <DisplayData title="Sigiloso">
              {processo.sigiloso ? 'Sim' : 'Não'}
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <DisplayData title="Título">
              {processo.processoMp?.titulo}
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col sm={4} md={4}>
            <DisplayData title="Situação">
              {processo.situacao?.descricao}
            </DisplayData>
          </Col>
          <Col sm={4} md={4}>
            <DisplayData title="Prioridade">{processo.prioridade}</DisplayData>
          </Col>
          <Col sm={4} md={4}>
            <DisplayData title="Data de Conclusão">
              <FormattedDate value={processo.previsaoConclusao} />
            </DisplayData>
          </Col>
          <Col sm={4} md={4}>
            <DisplayData title="Bloqueio Anexo Externo">
              {processo.bloqueioAnexoExterno ? 'Sim' : 'Não'}
            </DisplayData>
          </Col>
        </Row>
        {!processo?.tipo?.assuntoFixo && (
          <Row>
            <Col sm={4} md={4}>
              <DisplayData title={LABEL_ASSUNTO}>
                {processo.assunto?.id} - {processo.assunto?.descricao}
              </DisplayData>
            </Col>
          </Row>
        )}
        <Row>
          {processo.usuarioResponsavelTramite && (
            <Col sm={12} md={12}>
              <DisplayData title="Atribuído para">
                {processo.usuarioResponsavelTramite?.loginUsuarioMask} -{' '}
                {processo.usuarioResponsavelTramite?.nomeUsuario}
              </DisplayData>
            </Col>
          )}
          {processo.sigiloso && (
            <>
              <Col sm={4} md={4}>
                <DisplayData title="Grau de sigilo">
                  {processo.grauSigilo?.id} - {processo.grauSigilo?.descricao}
                </DisplayData>
              </Col>
              <Col sm={4} md={4}>
                <DisplayData title="Prazo para Desclassificação">
                  <FormattedDate value={processo.prazoDesclassificacao} />
                </DisplayData>
              </Col>
              <Col sm={4} md={4}>
                <DisplayData title="Fundamentação Legal">
                  {processo.fundamentacaoLegal}
                </DisplayData>
              </Col>
            </>
          )}
        </Row>
        {processo.tipo?.tipoProcessoMp === 'DOCUMENTACAO' && (
          <DocumentacaoViewSection processo={processo} />
        )}
        {processo.tipo?.tipoProcessoMp === 'PADI' && (
          <PADIViewSection processo={processo} />
        )}
        {processo.tipo?.tipoProcessoMp === 'PROCEDIMENTO_INTERNO' && (
          <ProcedimentoInternoViewSection processo={processo} />
        )}
        {processo.tipo?.tipoProcessoMp === 'RECOMENDACAO_ESPECIFICA' && (
          <RecomendacaoEspecificaViewSection processo={processo} />
        )}
        {processo.tipo?.tipoProcessoMp === 'SINDICANCIA' && (
          <SindicanciaViewSection processo={processo} />
        )}
        <TinyMCE
          value={processo.processoMp?.descricao}
          editorId={'editor-descricao'}
          disabled
        />
      </div>
    </>
  );
};

export default ProcessoMpCabecalho;
