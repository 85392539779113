import { axios } from 'libs';

const resource = '/processos-favoritos';

export const save = (processo, usuario) => {
  return axios.post(resource, {
    entidade: processo.entidade,
    numero: processo.numero,
    tipo: processo.tipo,
    ano: processo.ano,
    usuario
  });
};

export const remove = (processo, usuario) => {
  return axios.delete(resource, {
    params: {
      numero: processo.numero,
      ano: processo.ano,
      tipo: processo.tipo.id,
      entidade: processo.entidade.id,
      usuario
    }
  });
};

export const findAllByUsuario = () => {
  return axios.get(`${resource}/usuario`);
};

export const existsByProcessoAndUsuario = (processo, usuario) => {
  return axios.get(`${resource}/exists`, {
    params: {
      numero: processo.numero,
      ano: processo.ano,
      tipo: processo.tipo.id,
      entidade: processo.entidade.id,
      usuario
    }
  });
};
