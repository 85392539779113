import { Col, FormikAutocomplete, Row } from '@elotech/components';
import { InlineButton } from 'common/components';
import { Form, Formik } from 'formik';
import { FormGroup } from 'react-bootstrap';
import * as Yup from 'yup';

import { SqlModelo } from '../../../type';

const validationSchema = sqls =>
  Yup.object().shape({
    novoSql: Yup.object()
      .required('Variável/Sql é obrigatório para adição')
      .test('novoSql', 'Variável/Sql já foi inserido', function (novoSql) {
        if (!novoSql) {
          return true;
        }

        const isDuplicado = sqls.some(sql => sql.id === novoSql.id);

        return !isDuplicado;
      })
      .nullable()
  });

const SqlFloatSidebarForm = ({ onAddSql, sqls, sqlModeloService }) => (
  <Row>
    <Col md={12}>
      <Formik
        enableReinitialize
        onSubmit={(values, actions) => {
          onAddSql({ sqlModelo: values.novoSql });
          actions.resetForm({ novoSql: undefined });
        }}
        initialValues={{ novoSql: undefined }}
        validationSchema={() => validationSchema(sqls)}
      >
        {formProps => {
          return (
            <Form>
              <FormikAutocomplete
                size={8}
                name="novoSql"
                label="Variável/Sql"
                onSearch={sqlModeloService.findAllAutocomplete}
                getOptionLabel={(p: SqlModelo) =>
                  `${p.identificador} - ${p.nome}`
                }
                hint="Variáveis/Sqls para serem utilizadas no Modelo"
              />
              <Col sm={1} md={1} className="mt-xs">
                <FormGroup>
                  <InlineButton
                    label="Adicionar"
                    onClick={formProps.submitForm}
                    color="module-color"
                    disabled={!formProps.isValid}
                    icon="fa fa-plus"
                  />
                </FormGroup>
              </Col>
            </Form>
          );
        }}
      </Formik>
    </Col>
  </Row>
);

export { SqlFloatSidebarForm };
