import { ProcessoDTO } from 'types';

type ProcessoLike = Pick<ProcessoDTO, 'numero' | 'ano'> & {
  tipo: Pick<ProcessoDTO['tipo'], 'id'>;
  entidade: Pick<ProcessoDTO['entidade'], 'id'>;
};

export const buildProcessoId = (processo: ProcessoLike) => {
  const { tipo, numero, ano, entidade } = processo;

  const params = new URLSearchParams();
  params.append('tipo', tipo.id.toString());
  params.append('numero', numero.toString());
  params.append('ano', ano.toString());
  params.append('entidade', entidade.id.toString());

  return `?${params.toString()}`;
};
