import { ExtraInfo } from '@elotech/components';
import { getCurrentYear, getDataAtual } from 'common/utils';
import { StorageService } from 'libs';

export const QTD_MAXIMA_TRAMITES = 2;

export const initialState = {
  id: '',
  tipo: undefined,
  numero: undefined,
  situacao: undefined,
  externo: false,
  assunto: undefined,
  numeroAntigo: '',
  prioridade: undefined,
  pessoa: undefined,
  complementoAssunto: '',
  ano: getCurrentYear(),
  dataProcesso: getDataAtual(),
  arquivos: [],
  tramites: [],
  andamentos: [],
  observadores: [],
  numeroJaExistente: false,
  locaisComunicacao: [],
  usuariosComunicacao: [],
  marcarTodosLocaisComunicacao: false,
  marcarTodosUsuariosComunicacao: false,
  idProcessoTaxa: undefined,
  possuiArquivoAnexo: false,
  possuiInteressado: false
};

export const initialValidacoes = {
  taxasInvalidas: false,
  arquivosInvalidos: false,
  requerentePossuiDebitos: false
};

export const prioridadeFormatada = {
  BAIXA: 'Baixa',
  MEDIA: 'Média',
  ALTA: 'Alta',
  MUITO_ALTA: 'Muito Alta'
};

export const funcaoFormatada = {
  ARQUIVADO: 'Arquivado',
  ABERTO: 'Aberto',
  RECEBIDO: 'Recebido',
  ENCAMINHADO: 'Encaminhado'
};

export const funcaoExtraInfo = {
  VENCIDO: {
    color: 'negative',
    letter: 'V',
    value: 'Vencido'
  },
  NO_PRAZO: {
    color: 'positive',
    letter: 'A',
    value: 'No Prazo'
  },
  PARALISADO: {
    color: 'negative',
    letter: 'P',
    value: 'Paralisado'
  },
  SUSPENSO: {
    color: 'positive',
    letter: 'S',
    value: 'Suspenso'
  },
  FECHADO: {
    color: 'positive',
    letter: 'F',
    value: 'Fechado'
  },
  ROTEIRO: {
    color: 'warning',
    letter: 'R',
    value: 'Possui Roteiro Definido'
  },
  INTERNO: {
    color: 'warning',
    letter: 'I',
    value: 'Interno'
  },
  EXTERNO: {
    color: 'warning',
    letter: 'E',
    value: 'Interno'
  },
  SIGILOSO: {
    color: 'neutral',
    letter: 'S',
    value: 'Sigiloso'
  },
  TAXA: {
    color: 'negative',
    letter: 'T',
    value: 'Taxa'
  },
  ANEXO: {
    color: 'warning',
    letter: 'U',
    value: 'Possui Anexo'
  },
  REQUERENTES_EXTRAS: {
    color: 'neutral',
    letter: 'R',
    value: 'Requerentes Extras'
  }
};

const isDataPrevisaoMenorQueAtual = processo =>
  processo.previsaoConclusao &&
  new Date(getDataAtual()) > new Date(processo.previsaoConclusao);

export const getInfos = processo => {
  return (
    <>
      <ExtraInfo {...getSituacao(processo)} />

      {processo.roteiro && <ExtraInfo {...funcaoExtraInfo.ROTEIRO} />}

      {!processo.externo && <ExtraInfo {...funcaoExtraInfo.INTERNO} />}

      {processo.externo && <ExtraInfo {...funcaoExtraInfo.EXTERNO} />}

      {processo.sigiloso && <ExtraInfo {...funcaoExtraInfo.SIGILOSO} />}

      {processo.idProcessoTaxa && <ExtraInfo {...funcaoExtraInfo.TAXA} />}

      {processo.possuiArquivoAnexo && <ExtraInfo {...funcaoExtraInfo.ANEXO} />}

      {processo.possuiInteressado && (
        <ExtraInfo {...funcaoExtraInfo.REQUERENTES_EXTRAS} />
      )}
    </>
  );
};

export const getSituacao = processo => {
  const config = StorageService.getItem('config');

  if (processo.fechado) {
    return funcaoExtraInfo.FECHADO;
  }
  if (processo.paralisado) {
    return config?.isProcessoMp
      ? funcaoExtraInfo.SUSPENSO
      : funcaoExtraInfo.PARALISADO;
  }

  return processo.funcao !== 'ARQUIVADO' &&
    isDataPrevisaoMenorQueAtual(processo)
    ? funcaoExtraInfo.VENCIDO
    : funcaoExtraInfo.NO_PRAZO;
};

export const validarBloqueioProcessoUsuarioEspecifico = (
  processo,
  username,
  bloqueioVisualizacaoUsuarioEspecifico
) =>
  processo &&
  (!bloqueioVisualizacaoUsuarioEspecifico ||
    !processo.ultimoTramite?.usuarioResponsavelTramite ||
    (processo.ultimoTramite?.usuarioResponsavelTramite === username &&
      bloqueioVisualizacaoUsuarioEspecifico));

export const getLinksVisibility = processo => {
  const RECEBER =
    processo.permiteAcessoUsuarioLogado &&
    processo.localDestino?.id === processo?.ultimoTramite?.local?.id &&
    processo.funcao === 'ENCAMINHADO';
  const ENCAMINHAR =
    processo.permiteAcessoUsuarioLogado && processo.funcao === 'RECEBIDO';
  const REABRIR =
    processo.permiteAcessoUsuarioLogado &&
    processo.funcao === 'ARQUIVADO' &&
    !ENCAMINHAR;
  const ARQUIVAR = RECEBER || ENCAMINHAR;

  return {
    RECEBER,
    RECEBER_ENCAMINHAR: RECEBER,
    ENCAMINHAR,
    REABRIR,
    ARQUIVAR
  };
};

export const agrupadosHandler = {
  selectOne(processo, agrupados = []) {
    const found = agrupados.find(p => processo.id === p.id);

    if (!found) {
      return [...agrupados, processo];
    } else {
      return agrupados.filter(p => p.id !== processo.id);
    }
  },
  selectAll(processos = [], agrupados = [], idsSelecionados = []) {
    const agrupadosIds = agrupados.map(p => p.id);

    if (processos.every(p => idsSelecionados.includes(p.id))) {
      const idsNaoSelecionados = processos
        .filter(p => !p.selected)
        .map(p => p.id);
      return agrupados.filter(p => !idsNaoSelecionados.includes(p.id));
    } else {
      return [
        ...agrupados,
        ...processos.filter(p => !agrupadosIds.includes(p.id))
      ];
    }
  }
};

export const processosStateReducer = (state, action) => {
  switch (action.type) {
    case ProcessosStateTypes.FIELD:
      return {
        ...state,
        ...action.payload
      };
    case ProcessosStateTypes.HANDLE_FAVORITOS: {
      const { favoritos } = state;
      const isLiked = favoritos.some(f => f.id === action.payload.id);
      const index = favoritos.findIndex(f => f.id === action.payload.id);

      return {
        ...state,
        favoritos: isLiked
          ? [...favoritos.slice(0, index), ...favoritos.slice(index + 1)]
          : [...favoritos, action.payload]
      };
    }
    case ProcessosStateTypes.HANDLE_FORM_MODAL:
      return {
        ...state,
        quickProcesso: action.payload.quickProcesso,
        fastTramite: action.payload.fastTramite
      };
    case ProcessosStateTypes.HANDLE_TIMELINE_MODAL:
      return {
        ...state,
        quickProcesso: action.payload.quickProcesso,
        showTimeline: action.payload.showTimeline
      };
    default:
      return state;
  }
};

export const ProcessosStateTypes = {
  FIELD: 'FIELD',
  HANDLE_FAVORITOS: 'HANDLE_FAVORITOS',
  HANDLE_FORM_MODAL: 'HANDLE_FORM_MODAL',
  HANDLE_TIMELINE_MODAL: 'HANDLE_TIMELINE_MODAL'
};
