import { FileType } from '../type/enum';

export const ConvertBase64ToFile = (
  base64: string,
  filename: string,
  fileType: string
) => {
  const base64Data = atob(base64);
  const buffer = new ArrayBuffer(base64.length);
  const base64Decode = new Uint8Array(buffer);

  for (let index = 0; index < base64Data.length; index++) {
    base64Decode[index] = base64Data.charCodeAt(index);
  }
  const file = new File([base64Decode], filename, { type: fileType });
  return file;
};

export const getExtension = fileName =>
  fileName?.replaceAll(/[^.]*\./g, '').toUpperCase();

export const getContentType = (
  fileName: string,
  defaultContentType: string = FileType.BIN
): string => {
  const extension = getExtension(fileName);
  return FileType[extension] || defaultContentType;
};

export const isFileSuported = () =>
  window.File && window.FileReader && window.FileList && window.Blob;
