import {
  AutoComplete,
  BasicInput,
  Col,
  Container,
  FAB,
  FabSpeedDial,
  FormikInputInteger,
  FormikSelect,
  Loading,
  Row,
  useShowNotification
} from '@elotech/components';
import { InputFile, Label, TinyMCE } from 'common/components';
import {
  FormikListOperationsProps,
  withFormikListOperations
} from 'common/hocs';
import { useLoading } from 'common/hooks';
import {
  ModeloDadosService,
  PrestarContasService,
  SqlModeloService
} from 'common/service';
import { ModeloDados } from 'common/type';
import {
  CategoriaModeloEnum,
  CategoriaModeloEnumFormatado,
  TipoModeloEnum,
  tipoModeloEnumFormatado
} from 'common/type/enum';
import { getBreadcrumbStatus } from 'common/utils';
import FileSaver from 'file-saver';
import { Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';

import { TinyMCEAction } from '../tiny-mce';
import { initialModelo, validationSchemaModelo } from './';
import { SqlsFloatSidebar } from './sqls/SqlsFloatSidebar';

type Location = {
  simam: boolean;
};

type Props = {
  service: ModeloDadosService;
  options: CategoriaModeloEnum[];
  prestarContasService: PrestarContasService;
  executarExport: (nome: ModeloDados) => void;
  sqlModeloService: SqlModeloService;
} & Partial<FormikListOperationsProps>;

type Params = {
  id: string;
};

const ModeloDadosComponent = (props: Props) => {
  const history = useHistory();
  const location = useLocation<Location>();
  const { id } = useParams<Params>();

  const {
    options,
    service,
    prestarContasService,
    executarExport,
    sqlModeloService
  } = props;

  const sortedOptions = options.sort((a, b) => a.localeCompare(b));

  const formikRef = useRef<Formik<ModeloDados>>(null);
  const showNotification = useShowNotification();
  const [loading, setLoading] = useLoading();
  const [modeloDados, setModeloDados] = useState<ModeloDados>(initialModelo);
  const [arquivo, setArquivo] = useState<File>();

  useEffect(() => {
    if (id !== 'new') {
      setLoading(
        service.findById(id).then(({ data }) => {
          setModeloDados({
            ...data,
            sqlModeloPrestacao: data.sqls?.length
              ? data.sqls[0].sqlModelo
              : undefined
          });
        })
      );
    }
  }, [setLoading, id, service]);

  const simam = useMemo(() => location.state?.simam, [location]);

  const onDownloadArquivoPrestacao = (id: string) => {
    setLoading(
      prestarContasService
        .downloadArquivoPrestacao(id)
        .then(({ arquivo, fileName }) => {
          FileSaver.saveAs(URL.createObjectURL(arquivo), fileName);
        })
    );
  };

  const onAdd = (formProps, fieldName) => newValue => {
    const oldArray = formProps.values[fieldName] || [];

    const newArray = [...oldArray, newValue];

    formProps.setFieldValue(fieldName, newArray);
  };

  const onAddVariavelSqlModelo = (formProps, name, values) => {
    const variavelSqlModelo = [{ sqlModelo: values }];

    formProps.setFieldValue(name, values);
    formProps.setFieldValue('sqls', variavelSqlModelo);
  };

  const onRemove = (formProps, fieldName) => index => {
    const oldArray = formProps.values[fieldName] || [];

    const newArray = [
      ...oldArray.slice(0, index),
      ...oldArray.slice(index + 1)
    ];

    formProps.setFieldValue(fieldName, newArray);
  };

  const onSubmit = (values: ModeloDados) => {
    if (values.sqls?.length === 0) {
      return showNotification({
        level: 'error',
        message:
          'Modelo de dados deve possuir pelo menos uma Variáveis/Sqls vinculada!'
      });
    }

    setLoading(
      service.save(values).then(async ({ data }) => {
        if (arquivo && values.tipo === TipoModeloEnum.OFFICE) {
          await service.saveArquivo(data, arquivo);
        }

        history.replace(data, { simam });

        showNotification({
          level: 'success',
          message: 'Modelo de dados salvo com sucesso!'
        });
      })
    );
  };

  const tinyMceActions: TinyMCEAction[] = [
    {
      name: 'exportpdf',
      execute: () =>
        formikRef.current?.state.values &&
        executarExport(formikRef.current?.state.values!)
    }
  ];

  const onRemoveFile = () => {
    setArquivo(undefined);
    setModeloDados({
      ...modeloDados,
      arquivoNome: undefined,
      arquivoFullPath: undefined
    });
  };

  const onDownloadFile = () => {
    if (!modeloDados.arquivoNome && !arquivo) {
      return showNotification({
        level: 'error',
        message: 'Não foi possível realizar o download do arquivo!'
      });
    }

    if (arquivo) {
      const link: any = document.createElement('a');
      document.body.appendChild(link);

      link.id = 'blobElement';
      link.style = 'display: none';
      link.href = URL.createObjectURL(new Blob([arquivo]));
      link.download = arquivo.name;
      link.click();
      return;
    }

    setLoading(
      service
        .gerarUrlDownload(
          modeloDados.arquivoFullPath!,
          modeloDados.arquivoNome!
        )
        .then(({ data }) => {
          const link: any = document.createElement('a');
          document.body.appendChild(link);

          link.id = 'blobElement';
          link.style = 'display: none';
          link.href = data.urlAssinada;
          link.download = modeloDados.arquivoNome;
          link.click();

          window.URL.revokeObjectURL(data.urlAssinada);
        })
    );
  };

  return (
    <Container breadcrumb status={getBreadcrumbStatus(id)}>
      <Loading loading={loading} />
      <Formik
        ref={formikRef}
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={modeloDados}
        validationSchema={() => validationSchemaModelo}
      >
        {formProps => {
          const { values, setFieldValue, submitForm } = formProps;

          return (
            <>
              {!simam && (
                <SqlsFloatSidebar
                  onAddSqls={onAdd(formProps, 'sqls')}
                  onRemoveSqls={onRemove(formProps, 'sqls')}
                  sqls={values.sqls?.map(s => s.sqlModelo) || []}
                  sqlModeloService={sqlModeloService}
                />
              )}
              <Row className="mb-xs">
                {simam && (
                  <FormikInputInteger label="Código" name="codigo" size={2} />
                )}
                <BasicInput label="Nome" name="nome" type="text" size={6} />
                <FormikSelect<CategoriaModeloEnum>
                  size={3}
                  name="categoria"
                  label="Categoria"
                  options={sortedOptions}
                  getOptionValue={value => value}
                  getOptionLabel={value => CategoriaModeloEnumFormatado[value]}
                />
                <FormikSelect<TipoModeloEnum>
                  size={3}
                  name="tipo"
                  label="Tipo"
                  options={[TipoModeloEnum.EDITOR, TipoModeloEnum.OFFICE]}
                  getOptionValue={value => value}
                  getOptionLabel={value => tipoModeloEnumFormatado[value]}
                />
              </Row>

              {values.tipo === TipoModeloEnum.EDITOR && (
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Template</Label>
                      <TinyMCE
                        actions={tinyMceActions}
                        value={values.templateHtml!}
                        onChange={value => setFieldValue('templateHtml', value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {values.tipo === TipoModeloEnum.OFFICE && (
                <Row>
                  <InputFile
                    name="arquivo"
                    fileName={arquivo?.name || values.arquivoNome}
                    label="Arquivo"
                    size={12}
                    onChange={event => setArquivo(event.currentTarget.files[0])}
                    onRemove={onRemoveFile}
                    onDownload={onDownloadFile}
                  />
                </Row>
              )}

              {simam && (
                <Row>
                  <BasicInput
                    name="sqlModeloPrestacao"
                    label="Sql"
                    size={12}
                    render={({ form, field }) => (
                      <AutoComplete
                        {...field}
                        getOptionLabel={(sqlModelo: any) => sqlModelo.nome}
                        onSelect={(name, value) => {
                          onAddVariavelSqlModelo(formProps, name, value);
                        }}
                        onSearch={search =>
                          sqlModeloService.findAllAutocomplete(search, true)
                        }
                      />
                    )}
                  />
                </Row>
              )}

              <FabSpeedDial icon="ellipsis-v" title="Ações">
                {simam && (
                  <FAB
                    icon="file"
                    data-testid="btnTxt"
                    title="Gerar txt"
                    onClick={() => onDownloadArquivoPrestacao(values.id!)}
                  />
                )}
                <FAB
                  icon="check"
                  title="Salvar"
                  data-testid="btnSave"
                  onClick={submitForm}
                />
              </FabSpeedDial>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

const withFormikOperations =
  withFormikListOperations<any>(ModeloDadosComponent);

export { withFormikOperations as ModeloDadosComponent };
