import classnames from 'classnames';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  column?: boolean;
  className?: string;
  loading?: boolean;
  reduced?: boolean;
  hasBtnActions?: boolean;
  highlight?: boolean;
  small?: boolean;
};

const DisplayDataGrid: React.FC<Props> = ({
  children,
  className = '',
  column = false,
  loading = false,
  reduced = true,
  hasBtnActions = false,
  highlight = false,
  small = false
}) => {
  const classesGrid = classnames('display-data border', className, {
    column,
    reduced,
    highlight,
    small,
    'has-btn-actions': hasBtnActions,
    'placeholder-loader': loading
  });

  return <div className={classesGrid}>{children}</div>;
};

export default DisplayDataGrid;
