import axios from 'libs/axios';

const resource = '/processos-interessados';

export const findByProcesso = processoId => {
  return axios.get(`${resource}/by-processo${processoId}`);
};

export const create = (processoId, interessado) => {
  return axios.post(`${resource}${processoId}`, interessado);
};

export const deleteById = id => {
  return axios.delete(`${resource}${id}`);
};
