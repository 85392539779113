export const mockedState = {
  entidade: {
    entidade: {
      id: 1
    }
  },
  config: {
    controlaProcessoRequerente: false,
    cubejsIp: 'localhost',
    entidade: 'ELOTECH TEST',
    integradoUnico: false,
    isCloud: true,
    isFileCloud: true,
    isStandalone: false,
    nomeModulo: 'Protocolo',
    notaExplicativa: '',
    possuiAssinaturaContratada: true,
    possuiAssinaturaCortesia: true,
    permiteAlterarProcessoTramitado: false,
    tenant: 'elotech',
    unicoParcial: false,
    urlOxyCidadao: 'https://elotech.qa.elotech.com.br/cidadao/',
    usaOuvidoria: true,
    usaProcessoExterno: true
  },
  configExterna: {
    bloqueiaAberturaAnonima: false,
    emailIsRequired: true,
    mensagemAvisoProcessosOuvidoria: '',
    tamanhoMaximoAnexos: 20971520,
    telefoneIsRequired: true
  },
  user: {
    keycloakLoaded: true,
    currentUser: {
      id: '111fbfa2-b156-4792-a707-82b7b0c863de',
      nome: 'Teste',
      sobrenome: 'Silva',
      nomeCompleto: 'Teste Silva',
      username: '63204483002',
      email: 'testesilva@elotech.com.br',
      ativo: true,
      cpf: '63204483002'
    },
    userExists: {
      userExists: true
    },
    profile: {
      name: 'Teste Silva',
      userid: '111fbfa2-b156-4792-a707-82b7b0c863de',
      username: '63204483002',
      firstName: 'Tesete',
      lastName: 'Silva',
      email: 'testesilva@elotech.com.br',
      emailVerified: true,
      attributes: {
        primeiro_acesso: ['FALSE'],
        usuario_aise: ['TRIBUTOSDEV']
      }
    }
  },
  auth: {
    userInfo: {
      exercicio: {
        id: 2023
      }
    }
  }
};
