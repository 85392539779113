import {
  Col,
  Row,
  SectionTitle,
  useShowNotification
} from '@elotech/components';
import { ModeloDadosSearch } from 'components';
import { ModeloDadosSearchMp } from 'components/ModeloDadosSearchMp';
import { useSelector } from 'react-redux';
import { ProcessoArquivoService } from 'service';
import { getConfigSelector } from 'state';

const ModeloDadosProcesso = ({
  modelo,
  setModelo,
  idProcesso,
  assuntoProcesso,
  onAddFilesFromResponse,
  setLoading,
  template,
  setTemplate
}) => {
  const showNotification = useShowNotification();
  const { isProcessoMp } = useSelector(getConfigSelector);

  const onSaveTemplate = () => {
    if (template) {
      return setLoading(
        ProcessoArquivoService.salvarArquivoTemplate(idProcesso, {
          contentHtml: template,
          nome: modelo?.nome || 'templateProcesso'
        })
          .then(onAddFilesFromResponse)
          .then(() =>
            showNotification({
              level: 'success',
              message: 'Modelo salvo como template com sucesso (.html)'
            })
          )
      );
    }
  };

  return (
    <>
      <SectionTitle>Modelo de Documentos</SectionTitle>
      <Row>
        <Col sm={12}>
          {isProcessoMp ? (
            <ModeloDadosSearchMp
              modelo={modelo}
              setModelo={setModelo}
              template={template}
              setTemplate={setTemplate}
              assuntoModelo={assuntoProcesso}
              onSaveTemplate={onSaveTemplate}
              entidadeId={idProcesso}
              onAddFilesFromResponse={onAddFilesFromResponse}
            />
          ) : (
            <ModeloDadosSearch
              modelo={modelo}
              setModelo={setModelo}
              template={template}
              setTemplate={setTemplate}
              assuntoModelo={assuntoProcesso}
              onSaveTemplate={onSaveTemplate}
              entidadeId={idProcesso}
              onAddFilesFromResponse={onAddFilesFromResponse}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export { ModeloDadosProcesso };
