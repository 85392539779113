import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/tiposlogradouro';
const defaultSort = { sort: 'id,asc' };

export const findAllAutocomplete = (
  searchParam = '',
  pagination,
  sort = defaultSort
) => {
  const search = isNumber(searchParam)
    ? `id==${searchParam}`
    : FilterUtils.buildFilter(['descricao'])(searchParam);

  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};
