import { ProcessosAgrupadosTypes } from 'state/types';

export const addProcessoAgrupado = payload => dispatch => {
  dispatch({
    type: ProcessosAgrupadosTypes.ADD_PROCESSO_AGRUPADO,
    payload
  });
};

export const removeProcessoAgrupado = payload => dispatch => {
  dispatch({
    type: ProcessosAgrupadosTypes.REMOVE_PROCESSO_AGRUPADO,
    payload
  });
};

export const removeProcessosAgrupados = payload => dispatch => {
  dispatch({
    type: ProcessosAgrupadosTypes.REMOVE_PROCESSOS_AGRUPADOS,
    payload
  });
};

export const setProcessosAgrupados = payload => dispatch => {
  dispatch({
    type: ProcessosAgrupadosTypes.SET_PROCESSOS_AGRUPADOS,
    payload
  });
};

export const clearProcessosAgrupados = () => dispatch => {
  dispatch({
    type: ProcessosAgrupadosTypes.CLEAR_PROCESSOS_AGRUPADOS
  });
};

export const addProcessosAgrupados = payload => dispatch => {
  dispatch({
    type: ProcessosAgrupadosTypes.ADD_PROCESSOS_AGRUPADOS,
    payload
  });
};
