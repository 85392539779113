import {
  ActionButton,
  ActionsGroup,
  Container,
  FAB,
  Field,
  Loading,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { TableContainer, alert } from 'common/components';
import { ModeloDadosService } from 'common/service';
import { ModeloDados } from 'common/type';
import { useHistory } from 'react-router';

import { useLoading } from '../../hooks';
import {
  CategoriaModeloEnum,
  CategoriaModeloEnumFilterOptions,
  CategoriaModeloEnumFormatado
} from '../../type/enum';
import { buildPageRequest } from '../../utils';

type Props = {
  service: ModeloDadosService;
  categorias: CategoriaModeloEnum[];
  entidade?: string | number;
};

const searchFields: Field[] = [
  {
    label: 'Nome',
    name: 'nome',
    type: 'STRING'
  },
  {
    label: 'Categoria',
    name: 'categoria',
    type: 'ENUM',
    options: Object.entries(CategoriaModeloEnumFilterOptions).map(
      ([name, descricao]) => ({
        name,
        descricao
      })
    )
  }
];

export const ModeloDadosListComponent = ({
  categorias = [],
  service,
  entidade
}: Props) => {
  const [isLoading, setIsLoading] = useLoading();
  const history = useHistory();
  const { doSearch, doPagedSearch, loading, values, pagination } =
    usePagedQuery({
      search: (search, page, sort = { sort: 'nome,desc' }) => {
        let customSearch =
          search !== '' ? `codigo==null and ${search}` : 'codigo==null';

        if (entidade) {
          customSearch += ` and entidade==${entidade}`;
        }
        if (categorias && categorias.length) {
          const categoriasFilter = categorias.join(',');
          customSearch += ` and categoria=in=(${categoriasFilter})`;
        }

        return service.findAll(customSearch, page, sort);
      }
    });

  const clone = (modelo: ModeloDados) => {
    alert({
      type: 'question',
      title: 'Deseja duplicar este modelo?',
      text: 'Informe o nome do novo modelo que será gerado.',
      input: 'text',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value: string) => {
        if (!value.trim()) {
          return 'O nome do modelo é obrigatório';
        }

        if (value.length > 200) {
          return 'O nome do modelo não pode ter mais que 200 caracteres';
        }

        return null;
      },
      onConfirm: value => {
        return setIsLoading(
          service.clone(modelo.id!, value).then(({ data }) => {
            history.push(`/modelo-dados/${data}`);
          })
        );
      }
    });
  };

  const changeStatus = (modelo: ModeloDados) => {
    alert({
      type: 'question',
      title: `Deseja ${modelo.ativo ? 'inativar' : 'ativar'} este modelo?`,
      onConfirm: () => {
        return setIsLoading(
          service.changeStatus(modelo.id!).then(() => doSearch())
        );
      }
    });
  };

  return (
    <Container breadcrumb>
      <Loading loading={isLoading} />
      <TableContainer>
        <SearchFilter fields={searchFields} search={doSearch} />
        <Table
          values={values}
          loading={loading}
          defaultSort={{ name: 'modelo', direction: 'desc' }}
          keyExtractor={(modelo: ModeloDados) => `${modelo.id}`}
          sortOnHeaderColumnClick={sort =>
            doPagedSearch(buildPageRequest(pagination), sort)
          }
        >
          <Table.Column
            name="nome"
            header="Nome"
            value={(modelo: ModeloDados) => modelo.nome}
            sortable
          />
          <Table.Column
            header="Categoria"
            value={(modelo: ModeloDados) =>
              modelo.categoria
                ? `${CategoriaModeloEnumFormatado[modelo.categoria!]}`
                : 'N/D'
            }
          />
          <Table.Column
            header="Ativo"
            value={(modelo: ModeloDados) => (modelo.ativo ? 'Sim' : 'Não')}
          />
          <Table.Column
            header=""
            value={(modelo: ModeloDados) => (
              <ActionsGroup>
                <ActionButton
                  label="Editar"
                  key="edit-button"
                  icon="pencil-alt"
                  onClick={() => history.push(`/modelo-dados/${modelo.id}`)}
                />
                <ActionButton
                  label="Duplicar"
                  key="clone-button"
                  icon="clone"
                  onClick={() => clone(modelo)}
                />
                <ActionButton
                  label={modelo.ativo ? 'Inativar' : 'Ativar'}
                  key="change-status-button"
                  icon={modelo.ativo ? 'times' : 'check'}
                  onClick={() => changeStatus(modelo)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
        {pagination && pagination.totalPages > 0 && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </TableContainer>
      <div className="btn-save">
        <FAB
          icon="plus"
          iconColor="white"
          title="Novo modelo"
          onClick={() => history.push('/modelo-dados/new')}
        />
      </div>
    </Container>
  );
};
