import { useWindowWidth } from '@elotech/components';
import React from 'react';
import styled from 'styled-components';

export type IFrameProps = {
  url?: string;
  path: string;
};

const TABLET_WIDTH = 768;

const calcHeight = (width: number): string => {
  if (width > TABLET_WIDTH) {
    return 'calc(100vh - 113px)';
  }

  if (width <= TABLET_WIDTH) {
    return 'calc(100vh - 156px)';
  }

  return '100vh';
};

const StyledIFrame = styled.iframe`
  height: ${props => calcHeight(Number(props.width))};
  width: ${props => props.width};
`;

export const IFrame = (props: IFrameProps) => {
  const width = useWindowWidth();

  return <StyledIFrame src={props.path} width={width} />;
};
