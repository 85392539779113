import { axios } from 'libs';

const resource = '/processos/referenciados';

export const getReferenciadosById = id => {
  return axios.get(`${resource}${id}`);
};

export const getReferenciadosAndPrincipalById = id => {
  return axios.get(`${resource}-and-principal${id}`);
};

export const save = (processoReferencia, processoReferenciado) => {
  return axios.post(resource, { processoReferencia, processoReferenciado });
};

export const remove = processoReferenciado => {
  return axios.delete(resource, { data: { processoReferenciado } });
};

export const cloneArquivos = id => {
  return axios.post(`${resource}/clone-arquivos${id}`);
};
