import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  children: ReactNode;
};

export const MediaMobile = ({ children }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
