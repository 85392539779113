import { axios } from 'libs';

const resource = '/integracao-processos';

export const reenviar = () => {
  return axios.post(`${resource}/reenviar`);
};

export const findAllNaoIntegrados = () => {
  return axios.get(resource);
};
