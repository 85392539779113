import React, { DetailedHTMLProps, FC, LabelHTMLAttributes } from 'react';

type Props = DetailedHTMLProps<
  LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

const Label: FC<Props> = ({ children, className = '', ...rest }) => {
  return (
    <label className={`label ${className}`} {...rest}>
      {children}
    </label>
  );
};

export { Label };
