import { Option } from 'common/utils';
const objectToOptions = (source: Record<string, string>): Option[] =>
  Object.entries(source).map(([value, description]) => ({
    description,
    value
  }));

export const TiposDocumentoMp: Record<string, string> = {
  EMAIL: 'E-mail',
  OFICIO: 'Ofício',
  PROTOCOLO: 'Protocolo',
  SEI: 'SEI'
} as const;

export const tiposDocumentoMpOptions = objectToOptions(TiposDocumentoMp);
export const TipoRequeridoMp: Record<string, string> = {
  PROMOTOR_JUSTICA: 'Promotor de Justiça',
  PROCURADOR_JUSTICA: 'Procurador de Justiça',
  ORGAO_MP: 'Órgão MP',
  CGMP: 'CGMP'
} as const;

export const TipoRequeridoMpOptions = objectToOptions(TipoRequeridoMp);

export const TipoRequerenteMp: Record<string, string> = {
  EXTERNO: 'Externo',
  PROMOTOR_JUSTICA: 'Promotor de Justiça',
  PROCURADOR_JUSTICA: 'Procurador de Justiça',
  OUVIDORIA: 'Ouvidoria',
  CEAF: 'CEAF',
  CNMP: 'CNMP',
  JUIZ_DIREITO: 'Juiz de Direito',
  CORREGEDORIA_JUSTICA: 'Corregedoria da Justiça',
  CORREGEDORIA_MP: 'Corregedoria MP',
  CORREGEDORIA_POLICIA_CIVIL: 'Corregedoria Polícia Civil',
  INTERNO: 'Interno',
  OUTRO: 'Outro'
} as const;

export const TipoRequerenteMpOptions = objectToOptions(TipoRequerenteMp);

export enum PrioridadeMPEnum {
  BAIXA,
  NORMAL,
  ALTA,
  URGENTE,
  IMEDIATA
}

export const prioridadeMPEnum = {
  BAIXA: 'Baixa',
  NORMAL: 'Normal',
  ALTA: 'Alta',
  URGENTE: 'Urgente',
  IMEDIATA: 'Imediata'
};

export enum TipoProcedimentoMPEnum {
  SINDICANCIA,
  PAD
}

export const tipoProcedimentoMPEnum = {
  SINDICANCIA: 'Sindicância',
  PAD: 'PAD'
};

export enum ProcedenciaEnum {
  PROCEDENTE,
  IMPROCEDENTE
}

export const procedenciaEnum = {
  PROCEDENTE: 'Procedente',
  IMPROCEDENTE: 'Improcedente'
};

export enum SancaoDisciplinarEnum {
  ADVERTENCIA,
  MULTA,
  CENSURA,
  SUSPENSAO,
  DISPONIBILIDADESUBSIDIO,
  DEMISSAO
}

export const sancaoDisciplinarEnum = {
  ADVERTENCIA: 'Advertência',
  MULTA: 'Multa',
  CENSURA: 'Censura',
  SUSPENSAO: 'Suspensão',
  DISPONIBILIDADESUBSIDIO: 'Disponib. com Subsídio Proporcional',
  DEMISSAO: 'Demissão'
};
