import { axios } from 'libs';

const resource = '/notificacoes';

const EloNotificationService = {
  findAll: (search, pagination, sort = { sort: 'createOn,desc' }) => {
    return axios.get(resource, {
      params: {
        search: search ? search : undefined,
        ...sort,
        ...pagination
      }
    });
  },
  getNotifications: () =>
    axios.get(resource, {
      params: {
        size: 5,
        page: 0,
        sort: 'createOn,desc'
      }
    }),
  getUnreadCount: () => axios.get(`${resource}/count-not-readed`),

  getWebsocketURL: () => axios.get(`${resource}/websocket-url`),

  markAsRead: ids => axios.put(resource, ids)
};
export default EloNotificationService;
