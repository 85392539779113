import {
  BasicInput,
  Col,
  FormikAutocomplete,
  Loading,
  Row
} from '@elotech/components';
import { BasicInputObject, InlineButton, alert } from 'common/components';
import { useLoading } from 'common/hooks';
import {
  formatTelefone,
  formataCpfOuCnpj,
  getUrl,
  retirarFormatacaoCnpj,
  retirarFormatacaoCnpjCpf,
  retirarFormatacaoCpf,
  validateCpfCnpj
} from 'common/utils';
import { Formik } from 'formik';
import { NAO_DEMONSTRA_AVISO_PROCESSO_MESMO_ASSUNTO_REQUERENTE } from 'pages/parametros/Parametros';
import { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  ParametroService,
  PessoaService,
  ProcessoService,
  TipoParticipanteService,
  TributosService
} from 'service';
import { getIsLoggedSelector } from 'state';
import { getConfigSelector } from 'state';
import { unicoUrl, unicoUrlParams } from 'utils';

const initialState = {
  pessoa: undefined,
  localTrabalho: '',
  tipoParticipante: undefined
};

export const InteressadosForm = ({ onAdd, onCancel, validationSchema }) => {
  const [loading, setLoading] = useLoading();
  const isLogged = useSelector(getIsLoggedSelector);
  const config = useSelector(getConfigSelector);
  const [naoDemonstraAvisoProcesso, setNaoDemonstraAvisoProcesso] =
    useState(false);
  const [possuiIntegracaoTributos, setPossuiIntegracaoTributos] =
    useState(false);
  const [requerentePossuiDebitos, setRequerentePossuiDebitos] = useState();

  const onChangeCnpjCpf = formProps => event => {
    const cnpjCpf = event.target.value || '';

    formProps.setFieldValue(
      'pessoa.cnpjCpf',
      cnpjCpf.length > 14
        ? retirarFormatacaoCnpj(cnpjCpf)
        : retirarFormatacaoCpf(cnpjCpf)
    );
  };

  const onBlurCnpjCpf = formProps => event => {
    const cnpjCpf = retirarFormatacaoCnpjCpf(event.target.value);

    if (!cnpjCpf || !validateCpfCnpj(cnpjCpf)) {
      return;
    }

    setLoading(
      PessoaService.findByCnpjCpf(cnpjCpf).then(({ data }) => {
        const pessoa = data?.id ? data : { ...initialState.pessoa, cnpjCpf };
        formProps.setFieldValue('pessoa', pessoa);
      })
    );
  };

  const onSubmit = (values, actions) => {
    onAdd(values);
    actions.resetForm(initialState);
  };

  const onCreateRequerente = () => {
    const url =
      config.integradoUnico || config.isCloud
        ? `${unicoUrl}/pessoas/new${unicoUrlParams}`
        : getUrl('protocolo', '/pessoa/new');

    window.open(url, '_blank');

    return Promise.resolve();
  };

  const verificarProcessosExistentes = (assunto, pessoa) => {
    if (!naoDemonstraAvisoProcesso) {
      if (assunto && pessoa) {
        return setLoading(
          ProcessoService.verificarSeJaExisteProcesso(
            assunto.id,
            pessoa.id
          ).then(({ data }) => {
            if (data && data.length > 0) {
              alert({
                title: 'Informação',
                text: 'Já existem outros processos deste assunto para o mesmo requerente.',
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Visualizar',
                cancelButtonText: 'Cancelar',
                onConfirm: () => {
                  window.open(
                    getUrl(
                      'protocolo',
                      `/processos-novo?filters==assunto.id_Igual=${assunto.id},pessoa.id_Igual=${pessoa.id}`
                    ),
                    '_blank'
                  );
                }
              });
            }
          })
        );
      }
    }
    return Promise.resolve();
  };

  const verificarDebitosRequerente = requerente => {
    return setLoading(
      PessoaService.possuiDebitos(requerente.cnpjCpf).then(({ data }) => {
        setRequerentePossuiDebitos(data);
      })
    );
  };

  const handleChangeRequerente = (formProps, value) => {
    if (value && value.id !== formProps.values.pessoa?.id) {
      verificarProcessosExistentes(formProps.values.assunto, value).finally(
        () => {
          if (possuiIntegracaoTributos) {
            verificarDebitosRequerente(value);
          }
        }
      );
    }
  };

  useEffect(() => {
    setLoading(
      TributosService.possuiIntegracao().then(({ data }) =>
        setPossuiIntegracaoTributos(data)
      )
    );
    setLoading(
      ParametroService.findParametro(
        NAO_DEMONSTRA_AVISO_PROCESSO_MESMO_ASSUNTO_REQUERENTE
      ).then(({ data }) => {
        setNaoDemonstraAvisoProcesso(data.valor === 'S');
      })
    );
  }, []);

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialState}
      validationSchema={validationSchema}
    >
      {formProps => (
        <>
          <Loading loading={loading} />
          {!isLogged && (
            <Row>
              <BasicInputObject
                size={3}
                disabled={loading}
                hint="Informar apenas os números"
                name="pessoa.cnpjCpf"
                label="CNPJ / CPF"
                maxLength={18}
                onChange={onChangeCnpjCpf(formProps)}
                onBlur={onBlurCnpjCpf(formProps)}
                customProps={{
                  value: formataCpfOuCnpj(formProps.values.pessoa?.cnpjCpf)
                }}
              />
              <BasicInput
                size={6}
                label="Nome"
                maxLength={150}
                name="pessoa.nome"
              />
              <BasicInput
                size={3}
                type="email"
                label="Email"
                maxLength={100}
                name="pessoa.email"
              />
            </Row>
          )}
          <Row>
            {isLogged ? (
              <FormikAutocomplete
                size={5}
                fast={false}
                name="pessoa"
                label="Requerente"
                onCreateValue={onCreateRequerente}
                newValueLabel="Adicionar Pessoa"
                onItemSelected={handleChangeRequerente}
                onSearch={PessoaService.findAllRequerentes}
                getOptionLabel={r =>
                  `${formataCpfOuCnpj(r.cnpjCpf)} - ${r.nome}`
                }
              />
            ) : (
              <>
                <BasicInput
                  size={3}
                  maxLength={100}
                  name="localTrabalho"
                  label="Local de Trabalho"
                />
                <BasicInput
                  size={2}
                  maxLength={15}
                  label="Telefone"
                  name="pessoa.celular"
                  onChange={e =>
                    formProps.setFieldValue(
                      'pessoa.celular',
                      formatTelefone(e.target.value)
                    )
                  }
                />
              </>
            )}
            <FormikAutocomplete
              size={4}
              label="Tipo"
              name="tipoParticipante"
              getOptionLabel={t => `${t.id} - ${t.descricao}`}
              onSearch={TipoParticipanteService.findAllAutocomplete}
            />
            <Col md={3} sm={3} className="mt-xs">
              <FormGroup>
                <InlineButton
                  label="Adicionar"
                  onClick={formProps.submitForm}
                />
                <InlineButton
                  color="negative"
                  label="Cancelar"
                  onClick={onCancel}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {requerentePossuiDebitos && (
              <div className="center info warning mt-xs">
                Requerente possui débitos pendentes.
              </div>
            )}
          </Row>
        </>
      )}
    </Formik>
  );
};
