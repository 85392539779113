import {
  BasicInput,
  FormikAutocomplete,
  FormikInputDate,
  Row
} from '@elotech/components';
import React from 'react';
import { ProcessoService } from 'service';

const RecomendacaoEspecificaSection: React.FC<unknown> = () => {
  return (
    <>
      <Row>
        <FormikAutocomplete
          name="procedimentoOrigem"
          label="Procedimento Origem"
          size={6}
          onSearch={ProcessoService.findAllAutocomplete}
          getOptionLabel={(p: any) =>
            `Tipo:${p.tipo?.descricao} - Número:${p.numero} - Ano:${p.ano}`
          }
        />
        <BasicInput name="processoMp.comarca" label="Comarca" size={6} />
      </Row>
      <Row>
        <BasicInput name="processoMp.promotor" label="Promotor" size={6} />
        <FormikInputDate
          name="processoMp.dataRecomendacao"
          label="Data Recomendação"
          size={6}
        />
      </Row>
      <Row>
        <BasicInput name="processoMp.promotoria" label="Promotoria" size={6} />
        <FormikInputDate
          name="processoMp.dataCienciaRecomendacao"
          label="Data Ciência Recomendação"
          size={6}
        />
      </Row>
    </>
  );
};

export default RecomendacaoEspecificaSection;
