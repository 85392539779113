import { ForbiddenPage, ProtectedRoute } from '@elotech/components';
import { usePermissions } from 'hooks';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { getConfigSelector } from 'state';
import { isAuthenticated } from 'utils';

export const ProtocoloProtectedRoute = props => {
  const { hasSeletor } = usePermissions();
  const { isCloud, isStandalone } = useSelector(getConfigSelector);

  if (!isAuthenticated() || (isStandalone && !hasSeletor(props.seletor))) {
    return <ForbiddenPage />;
  }

  if (isCloud) {
    return <ProtectedRoute {...props} />;
  }

  return <Route {...props} />;
};
