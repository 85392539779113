import { DragDropFiles } from 'components';
import { ArquivoService } from 'service';

export const TramiteArquivo = ({ formProps }) => {
  const addFile = file => {
    formProps.setFieldValue('arquivos', [
      ...formProps.values.arquivos,
      Object.assign(file, {
        idLocal: Date.now(),
        nome: file.name,
        preview: file.preview,
        documento: file.documento
      })
    ]);
  };

  const removeFile = (_, index) => {
    formProps.setFieldValue('arquivos', [
      ...formProps.values.arquivos.slice(0, index),
      ...formProps.values.arquivos.slice(index + 1)
    ]);
  };

  const previewFile = file => ArquivoService.preview(file);

  return (
    <DragDropFiles
      canAdd={true}
      canDelete={true}
      canDownload={false}
      showAlertExclusao={false}
      addFile={addFile}
      removeFile={removeFile}
      previewFile={previewFile}
      style={{ marginTop: 0 }}
      files={formProps.values?.arquivos || []}
    />
  );
};
