import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

type FuncAfterToSign = (idsFinalizados: string) => void;

export const useAfterToSign = (funcAfterToSign: FuncAfterToSign) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idsFinalizdosFull = searchParams.get('idsFinalizados');
    if (!idsFinalizdosFull) return;

    let idsFinalizados = idsFinalizdosFull;
    if (idsFinalizdosFull && idsFinalizdosFull.indexOf('$') > 0) {
      idsFinalizados = idsFinalizdosFull.split('$')[0];
      const originalQueryParams = idsFinalizdosFull.split('$')[1];
      history.replace({ search: originalQueryParams });
    }
    if (idsFinalizados) {
      funcAfterToSign(idsFinalizados);
    }
  }, [funcAfterToSign, location.search]);
};
