import { EntidadeService } from 'service';
import { EntidadeTypes } from 'state/types';

export const setEntidadeSelecionada = entidade => dispatch => {
  dispatch({ type: EntidadeTypes.SET_ENTIDADE, payload: entidade });
};

export const getEntidadePrincipal = () => dispatch => {
  return EntidadeService.getEntidadePrincipal().then(({ data }) => {
    dispatch({
      type: EntidadeTypes.SET_ENTIDADE_PRINCIPAL,
      payload: data
    });
  });
};

export const clearEntidade = () => dispatch => {
  dispatch({ type: EntidadeTypes.CLEAR_ENTIDADE });
};
