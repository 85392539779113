export type Option = {
  value: any;
  description: any;
};

export const enumToOptions = (target): Option[] => {
  return Object.entries(target).map(([value, description]) => {
    return {
      value,
      description
    };
  });
};

export const enumFromValue = (e: any, value: string): any => {
  for (const prop in e) {
    if (e[prop] === value) {
      return prop;
    }
  }
};
