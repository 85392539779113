export enum SituacaoProcessoAndamentoEnum {
  VENCIDO = 'VENCIDO',
  A_VENCER = 'A_VENCER',
  FINALIZADO = 'FINALIZADO'
}

export const situacaoProcessoAndamentoEnum = {
  VENCIDO: 'Vencido',
  A_VENCER: 'À Vencer',
  FINALIZADO: 'Finalizado'
};

export const situacaoProcessoAndamentoColor = {
  VENCIDO: 'negative',
  A_VENCER: 'warning',
  FINALIZADO: 'positive'
};
