import { showNotification } from '@elotech/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FloatSidebar,
  FloatSidebarList,
  FloatSidebarListItem
} from 'common/components';
import { useExpandedIds } from 'common/hooks';
import { Col } from 'react-bootstrap';

import { CustomLink } from '../../link';
import { SqlFloatSidebarForm } from './SqlFloatSidebarForm';

const SqlsFloatSidebar = ({
  sqls,
  onAddSqls,
  onRemoveSqls,
  sqlModeloService
}) => {
  const { expandedIds, handleExpandedIds } = useExpandedIds();

  const onDragStart = (event, objeto, alias) => {
    event.dataTransfer.setData(
      'text/html',
      `<span contenteditable="false">[[${objeto.identificador}.${alias}]]</span>`
    );
  };

  const copyText = (identificador: string, alias?: string) => {
    let text = '';

    if (!alias) text = `[[${identificador}]]`;
    if (alias) text = `[[${identificador}.${alias}]]`;

    navigator.clipboard.writeText(text).then(() =>
      showNotification({
        level: 'success',
        message: `${text} copiado com sucesso!`
      })
    );
  };

  return (
    <>
      <FloatSidebar hint title="Variáveis/Sqls">
        <SqlFloatSidebarForm
          onAddSql={onAddSqls}
          sqls={sqls}
          sqlModeloService={sqlModeloService}
        />
        <FloatSidebarList
          placeholder="Busca de Variáveis/Sqls"
          showTopDivider={true}
          showFilter={false}
        >
          {sqls.map((objeto, index) => (
            <FloatSidebarListItem key={objeto.id}>
              <Col md={12}>
                <FontAwesomeIcon
                  className="chip-icon-right"
                  icon="trash"
                  onClick={() =>
                    onRemoveSqls(sqls.findIndex(sql => sql.id === objeto.id))
                  }
                />
                <Col md={11}>
                  <b className="module-color col-md-4 pl-none pr-none">
                    Código:
                  </b>
                  <CustomLink
                    identificador={`${objeto.id}`}
                    uri={`/variaveis/${objeto.id}`}
                  />
                </Col>
              </Col>

              <Col md={12}>
                <Col md={11}>
                  <b className="module-color col-md-4 pl-none pr-none">Nome:</b>
                  {objeto.nome}
                </Col>
              </Col>

              <Col md={12}>
                <FontAwesomeIcon
                  className="chip-icon-right"
                  icon={
                    expandedIds.some(i => i === index)
                      ? 'chevron-up'
                      : 'chevron-down'
                  }
                  onClick={() => handleExpandedIds(index)}
                />
                <Col md={11}>
                  <b className="module-color col-md-4 pl-none pr-none">
                    Identificador:
                  </b>
                  {`${objeto.identificador} `}
                  <i
                    className="fa fa-copy blue-90 mr-xs"
                    style={{ cursor: 'pointer' }}
                    onClick={() => copyText(objeto.identificador)}
                  />
                </Col>
              </Col>
              {expandedIds.some(i => i === index) && (
                <Col md={12}>
                  <div className="row">
                    <div className="title-block">Colunas</div>
                  </div>
                  <div className="col-md-12 pl-none pr-none">
                    {objeto.columns.map(alias => {
                      return (
                        <FloatSidebarListItem
                          key={`alias_${objeto.id}_${alias}`}
                          draggable={true}
                          onDragStart={e => onDragStart(e, objeto, alias)}
                        >
                          <Col md={12}>
                            {`${alias} `}
                            <i
                              className="fa fa-copy blue-90 mr-xs"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                copyText(objeto.identificador, alias)
                              }
                            />
                          </Col>
                        </FloatSidebarListItem>
                      );
                    })}
                  </div>
                </Col>
              )}
            </FloatSidebarListItem>
          ))}
        </FloatSidebarList>
      </FloatSidebar>
    </>
  );
};

export { SqlsFloatSidebar };
