import { addDays, format, parseISO, subYears } from 'date-fns';

/*
 * Documentação com todos os formatos(tokens): https://date-fns.org/v1.30.1/docs/format
 */
const HOUR_MINUTE = 'HH:mm';
const YEAR_MONTH_DAY = 'yyyy-MM-dd';
const DAY_MONTH_YEAR = 'dd/MM/yyyy';

/**
 * @param quantidade quantidade de anos que será subtraído
 * @param data (Opcional) data inicial que será subtraída
 */
export const subtrairAno = (
  quantidade: number,
  data = new Date(Date.now())
) => {
  const dataSubtraida = subYears(data, quantidade);
  return format(dataSubtraida, YEAR_MONTH_DAY);
};

export const getDataAtual = () => format(Date.now(), YEAR_MONTH_DAY);

export const getDateAndCurrentTime = () => {
  const [date, hour] = format(
    Date.now(),
    `${YEAR_MONTH_DAY} ${HOUR_MINUTE}`
  ).split(' ');

  return `${date}T${hour}`;
};

export const getCurrentYear = () => new Date(Date.now()).getFullYear();

export const getYearFromDate = date => new Date(date).getUTCFullYear();

export const formatarData = (data: string) =>
  format(parseISO(data), DAY_MONTH_YEAR);

export const dateSortDesc = (date1: string, date2: string) => {
  const date1Date = new Date(date1);
  const date2Date = new Date(date2);

  if (date1Date > date2Date) return -1;
  if (date1Date < date2Date) return 1;
  return 0;
};

export const getMenorUltimoDiaDosMeses = (meses: number[] = []) => {
  const anoAtual = new Date(Date.now()).getFullYear();
  let menorDia = 31;
  meses.forEach(m => {
    const menorDiaMes = new Date(anoAtual, m, 0).getDate();
    if (menorDiaMes < menorDia) {
      menorDia = menorDiaMes;
    }
  });

  return menorDia;
};

export const addDaysToDate = (date, days): string => {
  return format(addDays(parseISO(date), days), YEAR_MONTH_DAY);
};
