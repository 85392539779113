import { axios } from 'libs';

const resource = '/relatorios';

const handleFile = response =>
  Promise.resolve(response.data)
    .then(data => URL.createObjectURL(data))
    .then(url => window.open(url, '_blank'));

export const comprovanteEncaminhamento = params => {
  return axios
    .post(`${resource}/processos/comprovante-encaminhamento`, params, {
      responseType: 'blob'
    })
    .then(handleFile);
};

export const etiqueta = params => {
  return axios
    .post(`${resource}/processos/etiqueta`, params, { responseType: 'blob' })
    .then(handleFile);
};

export const geral = params => {
  return axios
    .post(`${resource}/processos/geral`, params, { responseType: 'blob' })
    .then(handleFile);
};

export const tramite = params => {
  return axios
    .post(`${resource}/tramite/detalhado`, params, { responseType: 'blob' })
    .then(handleFile);
};

export const tramitePorLocal = params => {
  return axios
    .post(`${resource}/tramite/tramite-por-local`, params, {
      responseType: 'blob'
    })
    .then(handleFile);
};

export const processoReceberEncaminhar = params => {
  return axios
    .post(`${resource}/processos/processo-receber-encaminhar`, params, {
      responseType: 'blob'
    })
    .then(handleFile);
};
