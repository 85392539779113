import { ActionButton, ActionsGroup, Table } from '@elotech/components';
import { TableContainer } from 'common/components';
import { formatTelefone, formataCpfOuCnpj } from 'common/utils';
import { useSelector } from 'react-redux';
import { getConfigSelector, getIsLoggedSelector } from 'state';

export const InteressadosList = ({
  isLoading = false,
  interessados = [],
  isExterno = false,
  onRemove
}) => {
  const { isLogged, isCloud, integradoUnico } = useSelector(state => ({
    isLogged: getIsLoggedSelector(state),
    ...getConfigSelector(state)
  }));

  return (
    <TableContainer>
      <Table
        fixed
        loading={isLoading}
        values={interessados || []}
        keyExtractor={(interessado, index) => `${interessado.id}-${index}`}
      >
        <Table.Column header="Nome" value={i => i.pessoa?.nome || ''} />
        <Table.Column
          header="CNPJ/CPF"
          value={i => formataCpfOuCnpj(i.pessoa?.cnpjCpf || '')}
        />
        <Table.Column header="Email" value={i => i.pessoa?.email || ''} />
        <Table.Column
          header="Telefone"
          value={i => formatTelefone(i.pessoa?.celular || '')}
        />
        <Table.Column
          header="Tipo"
          value={i => i.tipoParticipante?.descricao || ''}
        />
        {isExterno && (
          <Table.Column
            header="Local de Trabalho"
            value={i => i.localTrabalho || ''}
          />
        )}
        <Table.Column
          header=""
          value={(i, index) => (
            <ActionsGroup>
              {!(integradoUnico || isCloud) && isLogged && (
                <ActionButton
                  newTab
                  label="Acessar Pessoa"
                  icon="external-link-alt"
                  path={`/pessoa/${i.pessoa?.id}`}
                />
              )}
              {onRemove && (
                <ActionButton
                  icon="trash"
                  label="Remover"
                  onClick={() => onRemove(i, index)}
                />
              )}
            </ActionsGroup>
          )}
        />
      </Table>
    </TableContainer>
  );
};
