import '@fortawesome/fontawesome-free/css/all.css';
import '@elotech/arc/src/assets/less/compiled/protocolo.css';

import './icons/IconsLibrary';

import { ErrorPage } from '@elotech/components';
import { StorageService } from 'libs';
import ReactDOM from 'react-dom';
import {
  EntidadeService,
  ProcessoExternoService,
  ProtocoloConfigService
} from 'service';
import { configureIntl, getEntidadeByPathname } from 'utils';

import App from './App';

configureIntl();

const container = document.getElementById('root');
const configStorage = StorageService.getItem('config');
const configExternaStorage = StorageService.getItem('configExterna');
const entidadeStorage = StorageService.getItem('entidade');
const entidadePathName = getEntidadeByPathname();

const renderApp = (config, configExterna) => {
  ReactDOM.render(
    <App config={config} configExterna={configExterna} />,
    container
  );
};

const renderError = error => {
  ReactDOM.render(
    <ErrorPage message="Falha ao buscar as configurações iniciais da aplicação." />,
    container
  );
};

const getConfigs = async entidadeId => {
  const [config, configExterna] = await Promise.all([
    ProtocoloConfigService.getConfig(entidadeId),
    ProcessoExternoService.getConfig(entidadeId)
  ]);

  StorageService.saveItemLocalAndSession('entidade', entidadeId);
  StorageService.saveItemAsJson('config', config.data);
  StorageService.saveItemAsJson('configExterna', configExterna.data);

  renderApp(config.data, configExterna.data);
};

EntidadeService.findByIdOrEntidadePrincipal(entidadePathName)
  .then(({ data }) => data.id)
  .then(entidadeId => {
    const entidade =
      entidadePathName || !entidadeStorage ? entidadeId : entidadeStorage;

    const isRenderApp =
      configStorage &&
      configExternaStorage &&
      configStorage.idEntidade === entidade;

    if (isRenderApp) {
      renderApp(configStorage, configExternaStorage);
    } else {
      getConfigs(entidade);
    }
  })
  .catch(error => renderError(error));
