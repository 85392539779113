export enum emailAutomaticoEnum {
  numeroContrato = 'Contrato - Número',
  exercicio = 'Contrato - Exercício',
  codigoTce = 'Contrato - Código TCE',
  tipoContrato = 'Contrato - Tipo',
  tipoAto = 'Contrato - Tipo do Ato',
  objeto = 'Contrato - Objeto',
  nomeDestinatario = 'Contrato - Nome do Destinatário',
  emailDestinatario = 'Contrato - Email do Destinatário',
  nomeFornecedor = 'Contrato - Nome do Fornecedor',
  cnpjFornecedor = 'Contrato - CNPJ do Fornecedor',
  nomeRepresentanteContratado = 'Contrato - Nome do Representante',
  dataInicioVigencia = 'Contrato - Data do início da vigência',
  dataTerminoVigencia = 'Contrato - Data do término da vigência',
  dataAssinatura = 'Contrato - Data de assinatura',
  dataRescisao = 'Contrato - Data de rescisão',
  valorContratado = 'Contrato - Valor contratado',
  valorAditivo = 'Contrato - Valor do aditivo',
  valorReducao = 'Contrato - Valor de redução',
  valorAtualizado = 'Contrato - Valor atualizado'
}
