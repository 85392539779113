import { axios } from 'libs';

const resource = '/tramites';

export const salvarParecer = (idProcesso, sequencia, parecer) => {
  return axios.put(`${resource}${idProcesso}&sequencia=${sequencia}`, parecer);
};

export const listaAlteracaoPareceres = (idProcesso, sequencia) => {
  return axios.get(
    `${resource}/listaAlteracaoParecer${idProcesso}&sequencia=${sequencia}`
  );
};
