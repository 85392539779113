import { BasicInput } from '@elotech/components';
import { FieldProps } from 'formik';
import { FC } from 'react';

import {
  DetalheConfiguracaoIntegracaoSelect,
  DetalheConfiguracaoIntegracaoSelectProps
} from './DetalheConfiguracaoIntegracaoSelect';

type FormikDetalheConfiguracaoIntegracaoSelectProps =
  Partial<DetalheConfiguracaoIntegracaoSelectProps> & {
    name: string;
    fast?: boolean;
    value?: string;
    size?: number;
  };

const FormikDetalheConfiguracaoIntegracaoSelect: FC<
  FormikDetalheConfiguracaoIntegracaoSelectProps
> = ({ name, fast = true, onSelect, value = '', size = 2, ...props }) => {
  return (
    <BasicInput
      {...props}
      noLabel={true}
      name={name}
      fast={fast}
      render={({ form, field }: FieldProps) => (
        <DetalheConfiguracaoIntegracaoSelect
          {...props}
          {...field}
          onSelect={(name: string, value: string) => {
            if (onSelect) {
              onSelect(name, value);
            } else {
              form.setFieldValue(name, value);
              form.setFieldTouched(name, true);
            }
          }}
        />
      )}
    />
  );
};

export { FormikDetalheConfiguracaoIntegracaoSelect };
