import { BasicInput, FormikSelect, Row } from '@elotech/components';
import React from 'react';
import { tiposDocumentoMpOptions } from 'types';

const DocumentacaoSection: React.FC<unknown> = () => {
  return (
    <Row>
      <FormikSelect
        name={'processoMp.tipoDocumento'}
        label={'SG - Tipo Documento'}
        options={tiposDocumentoMpOptions}
        size={2}
        getOptionLabel={obj => obj.description}
        getOptionValue={obj => obj.value}
      />
      <BasicInput
        name={'processoMp.interessado'}
        label={'Interessado'}
        size={10}
      />
    </Row>
  );
};

export default DocumentacaoSection;
