export const getIn = (obj: any, props: string[]) => {
  for (const prop of props) {
    if (!obj[prop]) {
      return '';
    }

    if (typeof obj[prop] === 'object') {
      const newProps = props.filter(p => p !== prop);

      if (newProps.length === 0) {
        return obj[prop];
      }

      return getIn(obj[prop], newProps);
    }

    return obj[prop];
  }
};
