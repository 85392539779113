import { ReactComponent as Logo } from '@elotech/arc/src/assets/img/modules/oxy-administrativo.svg';
import {
  Header,
  Icon,
  KeycloakService,
  useOnClickOutside
} from '@elotech/components';
import { alert } from 'common/components';
import { StorageService, axios } from 'libs';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ProtocoloAuthService } from 'service';
import { AuthActions, EntidadeActions } from 'state';
import { getConfigSelector, getEntidadeSelector } from 'state/selectors';
import { isAuthenticated } from 'utils';

const defaultModuloResolver = () => {
  return axios
    .get(`${KeycloakService.getUrl()}/usuario/me/modulos`)
    .then(response => response.data);
};

const modulos = [
  {
    valor: 'OXY_ALMOXARIFADO',
    descricao: 'Almoxarifado',
    uri: 'almoxarifado',
    area: 'ADMINISTRATIVO',
    descricaoArea: 'Administrativo'
  },
  {
    valor: 'OXY_COMPRAS',
    descricao: 'Compras',
    uri: 'compras',
    area: 'ADMINISTRATIVO',
    descricaoArea: 'Administrativo'
  },
  {
    valor: 'OXY_CONTROLE_INTERNO',
    descricao: 'Controle interno',
    uri: 'controle-interno',
    area: 'ADMINISTRATIVO',
    descricaoArea: 'Administrativo'
  },
  {
    valor: 'OXY_FROTAS',
    descricao: 'Frotas',
    uri: 'frotas',
    area: 'ADMINISTRATIVO',
    descricaoArea: 'Administrativo'
  },
  {
    valor: 'OXY_OBRAS',
    descricao: 'Obras',
    uri: 'obras',
    area: 'ADMINISTRATIVO',
    descricaoArea: 'Administrativo'
  },
  {
    valor: 'OXY_PATRIMONIO',
    descricao: 'Patrimônio',
    uri: 'patrimonio',
    area: 'ADMINISTRATIVO',
    descricaoArea: 'Administrativo'
  },
  {
    valor: 'OXY_PROCESSOS',
    descricao: 'Protocolo',
    uri: 'protocolo',
    area: 'ADMINISTRATIVO',
    descricaoArea: 'Administrativo'
  },
  {
    valor: 'OXY_ADMIN',
    descricao: 'Arrecadação Admin',
    uri: 'admin',
    area: 'ARRECADACAO',
    descricaoArea: 'Arrecadação'
  },
  {
    valor: 'OXY_FISCALIZACAO',
    descricao: 'Fiscalização',
    uri: 'fiscalizacao',
    area: 'ARRECADACAO',
    descricaoArea: 'Arrecadação'
  },
  {
    valor: 'OXY_ISS',
    descricao: 'ISS',
    uri: 'iss-admin',
    area: 'ARRECADACAO',
    descricaoArea: 'Arrecadação'
  },
  {
    valor: 'OXY_ITBI',
    descricao: 'ITBI',
    uri: 'itbi',
    area: 'ARRECADACAO',
    descricaoArea: 'Arrecadação'
  },
  {
    valor: 'OXY_PORTAL_CONTRIBUINTE',
    descricao: 'Portal Contribuinte',
    uri: 'portal-contribuinte',
    area: 'ARRECADACAO',
    descricaoArea: 'Arrecadação'
  },
  {
    valor: 'OXY_TRIBUTOS',
    descricao: 'Tributos',
    uri: 'tributos',
    area: 'ARRECADACAO',
    descricaoArea: 'Arrecadação'
  },
  {
    valor: 'OXY_UNICO',
    descricao: 'Único',
    uri: 'unico',
    area: 'COMUM',
    descricaoArea: 'Comum'
  },
  {
    valor: 'OXY_ATOS',
    descricao: 'Atos',
    uri: 'atos',
    area: 'CONTABIL',
    descricaoArea: 'Contábil'
  },
  {
    valor: 'OXY_CONTABILIDADE',
    descricao: 'Contabilidade',
    uri: 'contabilidade',
    area: 'CONTABIL',
    descricaoArea: 'Contábil'
  },
  {
    valor: 'OXY_CUSTOS',
    descricao: 'Custos',
    uri: 'custos',
    area: 'CONTABIL',
    descricaoArea: 'Contábil'
  },
  {
    valor: 'OXY_ORCAMENTO',
    descricao: 'Orcamento',
    uri: 'orcamento',
    area: 'CONTABIL',
    descricaoArea: 'Contábil'
  },
  {
    valor: 'OXY_PRESTACAO_CONTAS_PR',
    descricao: 'Prestação de Contas (PR)',
    uri: 'prestacao-contas-pr',
    area: 'CONTABIL',
    descricaoArea: 'Contábil'
  },
  {
    valor: 'OXY_ESOCIAL',
    descricao: 'ESocial',
    uri: 'esocial',
    area: 'RECURSOS_HUMANOS',
    descricaoArea: 'Recursos Humanos'
  },
  {
    valor: 'OXY_FOLHA_PAGAMENTO',
    descricao: 'Folha Pagamento',
    uri: 'folha-pagamento',
    area: 'RECURSOS_HUMANOS',
    descricaoArea: 'Recursos Humanos'
  },
  {
    valor: 'OXY_PORTAL_SERVIDOR',
    descricao: 'Portal Servidor',
    uri: 'portal-servidor',
    area: 'RECURSOS_HUMANOS',
    descricaoArea: 'Recursos Humanos'
  },
  {
    valor: 'OXY_SAUDE',
    descricao: 'Saúde',
    uri: 'saude',
    area: 'SOCIAL',
    descricaoArea: 'Social'
  }
];

export const AppHeader = () => {
  const history = useHistory();
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const [isLoginActive, setIsLoginActive] = useState(false);
  const { entidadeLogada, config } = useSelector(state => ({
    entidadeLogada: getEntidadeSelector(state),
    config: getConfigSelector(state)
  }));

  useOnClickOutside(contentRef, () => {
    setIsLoginActive(false);
  });

  const isLogged = isAuthenticated();

  const onLogout = () => {
    StorageService.removeItem('entidade');
    ProtocoloAuthService.clearAuthentication();
    const redirectUri = `${ProtocoloAuthService.getHost()}/protocolo/consultaProcesso`;

    if (config.isCloud) {
      KeycloakService.logout({
        redirectUri
      });
    } else {
      dispatch(AuthActions.logout());
      dispatch(EntidadeActions.clearEntidade());
      window.location.href = redirectUri;
    }
  };

  const onClickNovidadesVersao = () => {
    window.open('https://elotech.movidesk.com/kb/category/oxy-protocolo');
  };

  const goToOxyCidadao = () => {
    if (!config.urlOxyCidadao) {
      return alert({
        type: 'info',
        title: 'A entidade não possui o Oxy Cidadão configurado!'
      });
    }

    window.location.href = config.urlOxyCidadao;
  };

  const onLogin = () => {
    StorageService.removeItem('entidade');

    if (config.isCloud) {
      window.location.search = '?login=true';
    } else if (config.integradoUnico || config.unicoParcial) {
      ProtocoloAuthService.redirectToLoginUnico();
    } else {
      history.push('/login');
    }
  };

  const renderFeatures = () => {
    if (!isLogged) {
      return (
        <ul className="header-features">
          {config.isSelecionaEntidade && (
            <li
              title="Trocar Entidade"
              onClick={() => {
                StorageService.removeItem('entidade');
                history.push('/consultaProcesso');
                window.location.reload();
              }}
            >
              <Icon icon="building" className="icon" />
              <small className="hidden-xs">Trocar Entidade</small>
            </li>
          )}
          <li
            title="Login"
            className={isLoginActive ? 'active' : ''}
            onClick={() => setIsLoginActive(prev => !prev)}
          >
            <Icon icon="user" className="icon" />
            <small className="hidden-xs">Login</small>
          </li>
        </ul>
      );
    }

    if (isLogged) {
      return (
        <>
          <Header.Features.AccessibilityButton />
          <Header.Features.NotificationButton />
          <Header.Features.AjustesButton />
          <Header.Features.ModulosButton />
        </>
      );
    }

    return null;
  };

  const getModulos = !!KeycloakService.getInstance().authServerUrl
    ? defaultModuloResolver
    : () => Promise.resolve(modulos);

  const renderContentLogged = () => {
    return (
      <>
        <Header.FeaturesContent.AccessibilityContent />
        <Header.FeaturesContent.EloNotificationContent />
        <Header.FeaturesContent.AjustesContent
          autoHeight
          showNovidadesVersao
          hideAccount={!config.isCloud}
          onLogout={onLogout}
          onClickNovidadesVersao={onClickNovidadesVersao}
        />
        <Header.FeaturesContent.ModulosContent getModulos={getModulos} />
      </>
    );
  };

  const renderContentNotLogged = () => {
    return (
      <div className="features-user auto-height active" ref={contentRef}>
        <h2 className="title">Login</h2>
        <ul className="user-actions">
          <li onClick={goToOxyCidadao}>
            <Icon icon="house-user" className="icon" />
            Cidadão
          </li>
          <li onClick={onLogin}>
            <Icon icon="user-tie" className="icon" />
            Servidor
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <Header
        showToggleButton
        logoModulo={Logo}
        brasao={entidadeLogada?.brasao}
        title={isLogged ? entidadeLogada?.nome || '' : config.entidade || ''}
      >
        <Header.Features>{renderFeatures()}</Header.Features>
        {isLogged && (
          <Header.FeaturesContent>
            {renderContentLogged()}
          </Header.FeaturesContent>
        )}
      </Header>
      {isLoginActive && (
        <Header.FeaturesContent>
          {renderContentNotLogged()}
        </Header.FeaturesContent>
      )}
    </>
  );
};
