import { FormattedDate } from '@elotech/components';

export type LeftComponentProps = {
  date?: string;
  hour?: string;
  title?: string;
  subtitle?: string;
};

export const LeftComponent = (props: LeftComponentProps) => {
  const { date, hour, title, subtitle } = props;

  return (
    <>
      <b>{date ? <FormattedDate value={date} /> : title}</b>
      <small>{hour || subtitle || ''}</small>
    </>
  );
};
