import Axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

import { FileType } from '../../type/enum';
import { TransProgressKind, initializeAxios } from './TransProgressExternal';
import TransProgressFloatSideBar from './TransProgressFloatSideBar';
import {
  TransProgressAddFileEvent,
  TransProgressAddFileEventType,
  TransProgressFile,
  TransProgressStatus,
  TransProgressUpdateFileEvent,
  TransProgressUpdateFileEventType,
  TransProgressUpdateKind
} from './types';

export const TransProgressContainer = ({ axios = [Axios] }) => {
  const [files, setFiles] = useState<TransProgressFile[]>([]);
  const [intervals, setIntervals] = useState<any[]>([]);

  // Configura as instâncias do axios
  useEffect(() => {
    const injections: {
      axiosInstance: AxiosInstance;
      request: number;
      response: number;
    }[] = [];

    for (const instance of axios) {
      injections.push(initializeAxios(instance));
    }

    return () => {
      injections
        .filter(i => i)
        .forEach(injection => {
          injection.axiosInstance.interceptors.request.eject(injection.request);
          injection.axiosInstance.interceptors.response.eject(
            injection.response
          );
        });
    };
  }, [axios]);

  // Atualiza os arquivos com o callback
  const updateFile = (
    oldFileId: string,
    callback: (file: TransProgressFile) => TransProgressFile,
    onlyActives: boolean = false
  ) => {
    setFiles(prevFiles =>
      prevFiles.map(newFile =>
        newFile.id === oldFileId && (!onlyActives || newFile.active)
          ? callback(newFile)
          : newFile
      )
    );
  };

  // Remove o arquivo da lista
  const removeFile = (fileId: string) => {
    if (setFiles) {
      setFiles(prevFiles => prevFiles.filter(newFile => newFile.id !== fileId));
    }
  };

  // Iniciar um timeout e inclui ele no intervals para serem removidos quando desmontar o componente
  const addTimeout = (callback: () => void, interval: number) => {
    setIntervals(prevIntervals => [
      ...prevIntervals,
      setTimeout(callback, interval)!
    ]);
  };

  // Limpa os intervalos que estiverem pendentes, quando for destruir
  useEffect(
    () => () => {
      intervals.forEach(clearInterval);
    },
    []
  );

  // Remove os arquivos que estiverem finalizados
  useEffect(() => {
    const needsToRemove = (file: TransProgressFile) =>
      !file.finishing && file.status === TransProgressStatus.FINISHED;
    if (!files.some(needsToRemove)) {
      return;
    }
    setFiles(prevFiles =>
      prevFiles.map(file => {
        if (needsToRemove(file)) {
          file.finishing = true;
          addTimeout(() => {
            removeFile(file.id);
          }, 1000);
        }
        return file;
      })
    );
  }, [files]);

  // Cancela o arquivo no state e envia o sinal de cancelamento
  const cancel = (fileId: string) => {
    let updated = false;
    updateFile(
      fileId,
      newFile => {
        updated = true;
        newFile.status = TransProgressStatus.CANCELED;
        newFile.active = false;
        newFile.cancelToken!.cancel('Operação cancelada pelo usuário');
        return newFile;
      },
      true
    );
    return updated;
  };

  // Remove o arquivo, cancelando caso necessário (usado no clique do Cancelar)
  const remove = (fileId: string) => {
    cancel(fileId);
    removeFile(fileId);
  };

  // Adiciona arquivo no state e mostra a FloatSidebar
  const addFile = (
    id: string,
    name: string,
    kind: TransProgressKind,
    cancelToken: CancelTokenSource
  ) => {
    const newFile: TransProgressFile = {
      id,
      name,
      cancelToken,
      status: TransProgressStatus.STARTING,
      kind,
      active: true
    };
    setFiles(oldFiles => [...oldFiles, newFile]);
    return newFile;
  };

  // Atualiza o progresso do arquivo
  const handleProgress = (fileId: string, total: number, loaded: number) => {
    updateFile(
      fileId,
      newFile => {
        newFile.size = Math.max(total, newFile.size || 0);
        newFile.transfered = loaded;
        newFile.status = TransProgressStatus.TRANSFERING;
        return newFile;
      },
      true
    );
  };

  // Marca o arquivo como falhado e salva o motivo
  const failFile = (fileId: string, reason: any) => {
    const getReasonText = async (): Promise<string> => {
      window.console.log({ reason });
      if (!reason?.message) {
        return 'Falha ao realizar transferência';
      }
      if (reason?.message === 'Network Error') {
        return 'Falha na conexão';
      }

      if (reason?.response?.data?.type === FileType.XML) {
        const xmlText = await reason?.response?.data?.text();
        if (xmlText.includes('NoSuchKey')) {
          return 'Não encontrado';
        }
        const message = xmlText.match(/<Message>(.*)<\/Message>/)[1];

        return message || reason.message;
      }

      if (!reason.response) {
        if (reason.error) {
          return reason.error;
        }
        return reason.message;
      }

      let { data } = reason.response;
      const { status } = reason.response;

      if (data instanceof Blob) {
        data = await data.text();
        try {
          data = JSON.parse(data);
        } catch (e) {
          // Não é JSON
        }
      }

      if (data.message) {
        return data.message;
      }

      if (typeof data === 'string') {
        if (data.includes('<body')) {
          const element = document.createElement('div');
          element.innerHTML = data;
          return element.textContent || element.innerText || data;
        }
        return data;
      }

      switch (status) {
        case 404:
          return 'Não encontrado';
        case 401:
        case 402:
        case 403:
          return 'Não autorizado';
      }

      if (data.error) {
        return data.error;
      }

      return reason.error || reason.message;
    };

    getReasonText().then(reasonText => {
      updateFile(fileId, newFile => {
        newFile.status = TransProgressStatus.FAILED;
        newFile.active = false;
        newFile.statusInfo = reasonText;
        return newFile;
      });
    });
  };

  // Marca o arquivo como finalizado
  const finishFile = (fileId: string) => {
    updateFile(fileId, newFile => {
      newFile.status = TransProgressStatus.FINISHED;
      newFile.active = false;
      return newFile;
    });
  };

  // Eventos DOM
  useEffect(() => {
    // Recebeu evento de adicionar arquivo
    const handleAddFile = (event: TransProgressAddFileEvent) => {
      const { cancelToken, id, kind, name } = event.detail;
      addFile(id, name, kind, cancelToken);
    };

    // Recebeu evento de atualizar arquivo e chama método correspondente
    const handleUpdateFile = (event: TransProgressUpdateFileEvent) => {
      const { id, updateKind } = event.detail;
      switch (updateKind) {
        case TransProgressUpdateKind.PROGRESS:
          handleProgress(id, event.detail.total!, event.detail.loaded!);
          return;
        case TransProgressUpdateKind.FAILED:
          failFile(id, event.detail.reason!);
          return;
        case TransProgressUpdateKind.FINISHED:
          finishFile(id);
          return;
      }
    };

    // Monitora os eventos
    document.addEventListener(
      TransProgressAddFileEventType,
      handleAddFile,
      true
    );
    document.addEventListener(
      TransProgressUpdateFileEventType,
      handleUpdateFile,
      true
    );

    // Ao desmontar o componente, remove os listeners
    return () => {
      document.removeEventListener(
        TransProgressAddFileEventType,
        handleAddFile,
        true
      );
      document.removeEventListener(
        TransProgressUpdateFileEventType,
        handleUpdateFile,
        true
      );
    };
  }, []);

  return (
    <>
      <TransProgressFloatSideBar
        cancel={cancel}
        remove={remove}
        files={files}
      />
    </>
  );
};
