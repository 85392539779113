import { Icon } from '@elotech/components';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

type Props = {
  position?: 'left' | 'center' | 'right';
  size?: SizeProp;
  pulse?: boolean;
  className?: string;
};

export const Spinner: React.FC<Props> = ({
  position = 'center',
  size = '2x' as SizeProp,
  className = ''
}) => (
  <div className={`${className} ${position}`}>
    <Icon icon="spinner" className="spinner" size={size} pulse primary />
  </div>
);
