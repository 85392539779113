export const isTemporary = key => {
  return !!sessionStorage.getItem(key);
};

export const getItemAsJson = key => {
  return localStorage.getItem(key) || sessionStorage.getItem(key);
};

export const getItem = key => {
  const value = getItemAsJson(key);

  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return;
    }
  }
};

export const saveItem = (key, value, persistent = false) => {
  if (!value) {
    return;
  }

  try {
    const storage = persistent ? localStorage : sessionStorage;

    storage.setItem(key, value);
  } catch (e) {
    return;
  }
};

export const saveItemAsJson = (key, value, persistent = false) => {
  if (!value) {
    return;
  }

  try {
    const storage = persistent ? localStorage : sessionStorage;

    storage.setItem(key, JSON.stringify(value));
  } catch (e) {
    return;
  }
};

export const removeItem = key => {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
};

export const saveItemLocalAndSession = (key, value) => {
  if (!value) {
    return;
  }

  try {
    localStorage.setItem(key, value);
    sessionStorage.setItem(key, value);
  } catch (e) {
    return;
  }
};
