import { axios } from 'libs';

const resource = '/processos/taxas';

export const reemitirBoleto = processoTaxaId => {
  return axios.post(`${resource}/processo-taxa/${processoTaxaId}`);
};

export const verificarStatusPagamento = (id, entidade) => {
  return axios.post(`${resource}/verificar-pagamento${id}`, entidade);
};

export const emitir = (processo, taxas) => {
  return axios.post(resource, taxas, {
    params: {
      ano: processo.ano,
      tipo: processo.tipo.id,
      numero: processo.numero,
      entidade: processo.entidade.id
    }
  });
};
