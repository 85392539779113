import { PagedResponse } from '@elotech/components';
import { AxiosPromise } from 'axios';
import { FastField, Field, FieldProps } from 'formik';
import React from 'react';

import { ComissaoSearch, ComissaoSearchProps } from './ComissaoSearch';

type FormikModalidadeSearchProps<T> = Partial<ComissaoSearchProps<T>> & {
  fast?: boolean;
  name: string;
  getOptionLabel: (option: T) => string;
  onSearch: (search: string) => AxiosPromise<PagedResponse<T>>;
};

const FormikComissaoSearch = <T,>({
  name,
  fast = true,
  onSelect,
  getOptionLabel,
  ...props
}: FormikModalidadeSearchProps<T>) => {
  const FormikField = fast ? FastField : Field;

  return (
    <FormikField
      name={name}
      render={({ field: { onChange, ...fieldProps }, form }: FieldProps) => {
        return (
          <ComissaoSearch<T>
            {...props}
            {...fieldProps}
            getOptionLabel={getOptionLabel}
            onSelect={(name, value) => {
              if (onSelect) {
                onSelect(name, value);
              } else {
                form.setFieldValue(name, value);
                form.setFieldTouched(name, true);
              }
            }}
            error={form.errors[name]}
          />
        );
      }}
    />
  );
};

export { FormikComissaoSearch };
