import { axios } from 'libs';

const resource = '/processo-andamentos';

export const findAllDTO = (search, pagination, sort = { sort: 'id,desc' }) => {
  return axios.get(`${resource}/dto`, {
    params: {
      search,
      ...sort,
      ...pagination
    }
  });
};

export const save = (id, andamento) => {
  if (andamento.id) {
    return axios.put(`${resource}/${andamento.id}${id}`, andamento);
  }

  return axios.post(`${resource}/${id}`, andamento);
};

export const deleteById = id => {
  return axios.delete(`${resource}/${id}`);
};

export const getNumeroRecomendado = (tipoAndamentoId, ano) => {
  return axios.get(`${resource}/numero-recomendado`, {
    params: {
      tipoAndamentoId,
      ano
    }
  });
};

export const createAndamentoComunicacao = (id, locaisUsuariosComunicacao) => {
  return axios.post(
    `${resource}/andamento-comunicacao${id}`,
    locaisUsuariosComunicacao
  );
};

export const aceite = (id, resposta) => {
  return axios.put(`${resource}/${id}/aceite`, resposta);
};
