import { AlertProps } from './types';

export const noop = (value?: any) => {};
export const buildParams = (
  params: Omit<AlertProps, 'onConfirm' | 'onCancel' | 'type'>
) => {
  params.confirmButtonColor = '#348c30';

  if (params.input) {
    return {
      ...params,
      input: params.input
    };
  }

  return params;
};
