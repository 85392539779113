import {
  ActionButton,
  ActionsGroup,
  AreaChip,
  Col,
  Container,
  Icon,
  Panel,
  Row,
  SearchFilter,
  SearchPagination,
  Table,
  TableChild,
  usePagedQuery
} from '@elotech/components';
import { selectUnit } from '@formatjs/intl-utils';
import { TableContainer } from 'common/components';
import { useLoading } from 'common/hooks';
import { onExpandIndex } from 'common/utils';
import { useState } from 'react';
import { FormattedRelativeTime } from 'react-intl';
import { EloNotificationService } from 'service';

const searchFields = [
  {
    label: 'Lida',
    name: 'readed',
    type: 'BOOLEAN'
  },
  {
    label: 'Mensagem',
    name: 'message',
    type: 'STRING'
  },
  {
    label: 'Detalhe',
    name: 'messageDetail',
    type: 'STRING'
  },
  {
    label: 'Tipo',
    name: 'type',
    type: 'STRING'
  },
  {
    label: 'Status',
    name: 'status',
    type: 'ENUM',
    options: [
      { name: 'STARTED', descricao: 'Iniciado' },
      { name: 'COMPLETED', descricao: 'Concluído' },
      { name: 'FAILED', descricao: 'Falhado' }
    ]
  },
  {
    label: 'Data',
    name: 'createOn',
    type: 'DATETIME'
  },
  {
    label: 'Módulo',
    name: 'modulo',
    type: 'STRING'
  },
  {
    label: 'Link',
    name: 'link',
    type: 'STRING'
  }
];

const NotificacaoPageList = () => {
  const [isLoading] = useLoading();
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const { values, loading, pagination, doSearch, doPagedSearch, doSortChange } =
    usePagedQuery({
      search: EloNotificationService.findAll,
      onError: error => Promise.reject(error)
    });

  const formatCreateOn = createOn => {
    const relativeTimeData = selectUnit(new Date(createOn));
    return (
      <FormattedRelativeTime
        value={relativeTimeData.value}
        unit={relativeTimeData.unit}
      />
    );
  };

  const formatStatus = status => {
    if (status === 'STARTED') {
      return <Icon icon="spinner" spin color={'red'} />;
    }
    if (status === 'COMPLETED') {
      return <Icon icon="check" color={'green'} />;
    }
    if (status === 'FAILED') {
      return <Icon icon="times" color={'red'} />;
    }
    return <></>;
  };

  const redirectToLink = link => window.open(link, '_blank');

  const renderInnerComponent = (notificacao, index) => {
    if (expandedIndexes.length > 0 && expandedIndexes.includes(index)) {
      return (
        <TableChild>
          <Panel>
            <Row>
              <Col>{notificacao.messageDetail}</Col>
            </Row>
          </Panel>
        </TableChild>
      );
    }

    return null;
  };

  return (
    <Container breadcrumb>
      <TableContainer>
        <SearchFilter fields={searchFields} search={doSearch} />
        <Table
          renderInnerComponent={renderInnerComponent}
          columnSelector
          values={values}
          loading={loading || isLoading}
          sortOnHeaderColumnClick={doSortChange}
          keyExtractor={notificacao => `${notificacao.id}`}
          defaultSort={{ name: 'createOn', direction: 'desc' }}
        >
          <Table.Column
            sortable
            header="Tipo"
            name="type"
            value={notificacao => notificacao.type}
          />

          <Table.Column
            sortable
            header="Módulo"
            name="descricao"
            value={notificacao => <AreaChip area={notificacao.modulo} />}
          />
          <Table.Column
            sortable
            header="Mensagem"
            name="message"
            value={notificacao => notificacao.message}
          />
          <Table.Column
            sortable
            header="Recebido Em"
            name="createOn"
            value={notificacao => formatCreateOn(notificacao.createOn)}
          />

          <Table.Column
            sortable
            header="Status"
            name="status"
            value={notificacao => formatStatus(notificacao?.status)}
          />
          <Table.Column
            header=""
            value={(notificacao, index) => (
              <ActionsGroup>
                <ActionButton
                  label="Abrir Link"
                  icon="eye"
                  onClick={() => redirectToLink(notificacao.link)}
                />
                <ActionButton
                  label="Detalhamento"
                  icon="chevron-down"
                  onClick={() =>
                    setExpandedIndexes(onExpandIndex(index, expandedIndexes))
                  }
                />
              </ActionsGroup>
            )}
          />
        </Table>
        {pagination && pagination.totalPages > 0 && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </TableContainer>
    </Container>
  );
};

export default NotificacaoPageList;
