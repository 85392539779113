import { FileNameFormatter } from 'components';
import styled from 'styled-components';

const DragDropItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonsGroup = styled.div`
  align-items: center;
  display: flex;
  max-width: 168px;
  position: absolute;
  right: 10px;
`;

const DragDropItemButton = styled.button`
  background: hsl(117, 49%, 37%);
  border-radius: 100%;
  color: #fff !important;
  display: block;
  height: 20px;
  margin-right: 8px;
  overflow: hidden;
  width: 20px;
  z-index: 3;
`;

const DragDropItemLabel = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const situacaoByAnalise = {
  N: 'Não Analisado',
  A: 'Aprovado',
  R: 'Recusado'
};

const DragDropItem = ({
  item,
  readOnly,
  canDelete,
  canDownload,
  index,
  onRemove,
  onDownload,
  onPreview,
  onApprove,
  onReject,
  canApprove,
  canReject,
  canRename,
  onRenameFile
}) => {
  const usuario = item.usuario ? ` - Usuário: ${item.usuario}` : '';

  const data = item.dataCriacao
    ? ` - Data: ${new Date(item.dataCriacao).toLocaleString()}`
    : '';

  const situacao = item.analise ? `- ${situacaoByAnalise[item.analise]}` : '';

  const sigilo = item.sigiloExterno
    ? ' - Não demonstrado na consulta externa'
    : '';

  return (
    <li key={item.sequencia}>
      <DragDropItemContainer className="dragdrop-item" key={item.sequencia}>
        <DragDropItemLabel>{`${FileNameFormatter({
          originalName: item.nome,
          shouldTruncate: false
        })} ${usuario} ${data} ${situacao} ${sigilo}`}</DragDropItemLabel>

        <ButtonsGroup>
          {canRename && (
            <DragDropItemButton
              title="Renomear"
              type="button"
              className="fa fa-pencil-alt"
              onClick={() => onRenameFile?.(item, index)}
            />
          )}
          {canApprove && (
            <DragDropItemButton
              title="Aprovar"
              type="button"
              className="fa fa-check"
              onClick={() => onApprove?.(item, index)}
            />
          )}
          {canReject && (
            <DragDropItemButton
              title="Rejeitar"
              type="button"
              className="fa fa-times"
              onClick={() => onReject?.(item, index)}
            />
          )}
          {canDownload && (
            <DragDropItemButton
              title="Baixar"
              type="button"
              className="fa fa-download"
              onClick={() => onDownload?.(item)}
            />
          )}
          {canDownload && (
            <DragDropItemButton
              title="Visualizar"
              type="button"
              className="fa fa-eye"
              onClick={() => onPreview?.(item)}
            />
          )}
          {!readOnly && canDelete && (
            <DragDropItemButton
              title="Remover"
              type="button"
              className="fa fa-trash"
              onClick={() => onRemove(item, index)}
            />
          )}
        </ButtonsGroup>
      </DragDropItemContainer>
    </li>
  );
};

export default DragDropItem;
