import { SectionTitle } from '@elotech/components';
import { InlineButton } from 'common/components';
import { useState } from 'react';

import ProcessoObservadorExternoForm from './ProcessoObservadorExternoForm';
import ProcessoObservadorExternoList from './ProcessoObservadorExternoList';

const ProcessoObservadorExternoContainer = ({
  observadoresExternos = [],
  onAdd,
  onRemove,
  canAddAndRemove = true
}) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <SectionTitle hasButton>
        Observadores Externos
        {!showForm && canAddAndRemove && (
          <InlineButton
            icon="fa fa-plus"
            label="Adicionar"
            onClick={() => setShowForm(true)}
          />
        )}
      </SectionTitle>
      {showForm && (
        <ProcessoObservadorExternoForm
          onAdd={onAdd}
          onCancel={() => setShowForm(false)}
        />
      )}
      <ProcessoObservadorExternoList
        observadoresExternos={observadoresExternos}
        onRemove={onRemove}
        canRemove={canAddAndRemove}
      />
    </>
  );
};

export default ProcessoObservadorExternoContainer;
