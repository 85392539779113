import {
  Col,
  Loading,
  NumberUtils,
  Row,
  SectionTitle
} from '@elotech/components';
import { useLoading } from 'common/hooks';
import { DragDropFiles } from 'components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ArquivoService,
  ParametroService,
  ProcessoArquivoService
} from 'service';
import { getIsLoggedSelector } from 'state';

import {
  PERMITE_RENOMEAR_ANEXOS,
  TAMANHO_MAXIMO_ARQUIVOS_INTERNO
} from '../../parametros/Parametros';
import TableDocumentosProcesso from './TableDocumentosProcesso';

export const ProcessoArquivo = ({
  canAddArquivos = true,
  processo,
  onAdd,
  onRemove,
  showDocumentoObrigatorio = true,
  showFiles = true,
  onVisualizarArquivoHtml = undefined,
  processoExterno = false
}) => {
  const [maxSize, setMaxSize] = useState();
  const [canRename, setCanRename] = useState(false);
  const [isLoading, setLoading] = useLoading();
  const isLogged = useSelector(getIsLoggedSelector);

  useEffect(() => {
    if (isLogged) {
      setLoading(
        Promise.all([
          ParametroService.findParametro(TAMANHO_MAXIMO_ARQUIVOS_INTERNO).then(
            ({ data }) => {
              if (NumberUtils.isNumeric(data.valor)) {
                setMaxSize(parseFloat(data.valor));
              }
            }
          ),
          ParametroService.findParametro(PERMITE_RENOMEAR_ANEXOS).then(
            ({ data }) => setCanRename(data.valor === 'S')
          )
        ])
      );
    }
  }, [isLogged]);

  const canDownload = !!processo?.id;
  const canAdd = processo?.acessos?.incluiArquivosDigitais || true;
  const canDelete = processo?.acessos?.excluiArquivosDigitais || true;
  const hasInvalidFiles =
    (processo?.assunto && processo?.assunto?.assuntoDocumento?.length) ||
    0 !== processo?.arquivos?.filter(a => a.documento).length;

  const add = file =>
    onAdd(
      Object.assign(file, {
        idLocal: Date.now(),
        nome: file.name,
        preview: file.preview,
        documento: file.documento
      })
    );

  const preview = file => {
    if (
      file?.nome?.toLowerCase().endsWith('.html') &&
      onVisualizarArquivoHtml
    ) {
      ArquivoService.rawDownload(file).then(({ data }) =>
        onVisualizarArquivoHtml(data, file)
      );
    } else {
      ArquivoService.preview(file);
    }
  };

  const download = file => ArquivoService.download(file);

  const remove = file => setLoading(onRemove(file));

  const analisarArquivo = file =>
    setLoading(ProcessoArquivoService.analisar(file));

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Row>
      <Loading loading={isLoading} />
      {showDocumentoObrigatorio &&
        processo.assunto?.assuntoDocumento?.length > 0 && (
          <Col md={5}>
            <SectionTitle>Documentos Obrigatórios</SectionTitle>
            <TableDocumentosProcesso
              adicionarArquivo={add}
              removerArquivo={remove}
              possuiErroArquivos={hasInvalidFiles}
              arquivosInseridos={processo.arquivos || []}
              assuntoDocumento={processo.assunto?.assuntoDocumento}
              processoExterno={processoExterno}
            />
          </Col>
        )}

      <Col
        md={
          showDocumentoObrigatorio &&
          processo.assunto?.assuntoDocumento?.length > 0
            ? 7
            : 12
        }
      >
        {showFiles && <SectionTitle>Arquivos</SectionTitle>}
        <DragDropFiles
          files={showFiles ? processo?.arquivos : []}
          addFile={add}
          analisarArquivo={analisarArquivo}
          previewFile={preview}
          downloadFile={download}
          removeFile={remove}
          canAdd={canAddArquivos && canAdd}
          canDelete={canDelete}
          canDownload={canDownload}
          canRename={canRename}
          maxSize={maxSize}
          replaceFile={reloadPage}
        />
      </Col>
    </Row>
  );
};
