import {
  ActionButton,
  ActionsGroup,
  Loading,
  Table,
  useShowNotification
} from '@elotech/components';
import { TableContainer, alert } from 'common/components';
import { useLoading } from 'common/hooks';
import { ProcessoObservadorExternoService } from 'service';

const ProcessoObservadorExternoList = ({
  observadoresExternos = [],
  onRemove,
  canRemove
}) => {
  const [loading, setLoading] = useLoading();
  const showNotification = useShowNotification();

  return (
    <TableContainer>
      <Loading loading={loading} />
      <Table
        values={observadoresExternos}
        keyExtractor={obs => `${obs.pessoa.id}`}
      >
        <Table.Column header="Nome" value={obs => obs.pessoa.nome} />
        <Table.Column header="Email" value={obs => obs.pessoa.email} />
        <Table.Column
          header="Tipo Andamento"
          value={obs => obs.tipoAndamento?.descricao}
        />
        <Table.Column
          header="Obriga Visualização"
          value={obs => (obs.obrigaVisualizacao ? 'Sim' : 'Não')}
        />
        <Table.Column
          header=""
          value={(obs, index) => (
            <ActionsGroup>
              {canRemove && (
                <>
                  {obs.id && (
                    <ActionButton
                      label="Enviar Email"
                      icon="paper-plane"
                      onClick={() =>
                        setLoading(
                          ProcessoObservadorExternoService.sendEmail(
                            obs.id
                          ).then(() =>
                            showNotification({
                              level: 'success',
                              message: 'Email enviado com sucesso!'
                            })
                          )
                        )
                      }
                    />
                  )}
                  <ActionButton
                    label="Remover"
                    icon="trash"
                    onClick={() =>
                      alert({
                        type: 'question',
                        title: 'Remover',
                        text: 'Deseja mesmo excluir este observador?',
                        onConfirm: () => onRemove(obs, index)
                      })
                    }
                  />
                </>
              )}
            </ActionsGroup>
          )}
        />
      </Table>
    </TableContainer>
  );
};

export default ProcessoObservadorExternoList;
